import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExpressionOperator, FilterLogicalOperator } from '../enums';
import { FilterItem } from '../models';
import { VesselLookup } from '../models/vessel';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';

@Injectable({ providedIn: 'root' })
export class VesselService {
    private readonly url = `${this.appEnvironment.baseApiUrl}/vessel`;

    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private appEnvironment: any) {}

    getLookupsBy(value: string, propertyNames: string[], pageSize = 10): Observable<VesselLookup[]> {
        let httpParams = new HttpParams();
        propertyNames.forEach((propertyName, index) => {
            httpParams = FilterItem.create(propertyName, value, ExpressionOperator.StartsWith, FilterLogicalOperator.OR).updateHttpParams(httpParams, index);
        });

        httpParams = httpParams.set('pageSize', pageSize);
        return this.http.get<VesselLookup[]>(`${this.url}/lookup/?${httpParams}`);
    }
}
