import { Injectable } from '@angular/core';
import { ColumnFilterItem, CustomMatchMode, PartyLookup } from '../models';
import { Table } from 'primeng/table';
import { TreeTable } from 'primeng/treetable';
import { ObjectUtils } from 'primeng/utils';

@Injectable({ providedIn: 'root' })
export class CustomColumnFiltersService {
    registerColumnFilterItem(table: Table | TreeTable): void {
        table.filterService.register(CustomMatchMode.ColumnFilterItemIn, (value: any, filters: ColumnFilterItem<any, any>[]): boolean => {
            if (!filters || filters.length === 0) {
                return true;
            }

            return filters?.some((filter: ColumnFilterItem<unknown, unknown>) =>
                ObjectUtils.equals(
                    filter.dataField ? value?.[filter.dataField] : (value ? value : null),
                    (filter.filterField ? filter.value?.[filter.filterField] : filter.value) ?? null
                )
            );
        });
    }

    registerEmptyDateFilter(table: Table | TreeTable): void {
        table.filterService.register(CustomMatchMode.EmptyDate, (value: string, filter: string[]): boolean => {
            return !value;
        });
    }

    registerPartyFilters(table: Table | TreeTable): void {
        table.filterService.register(
            CustomMatchMode.PartyFilterRule,
            (
                value: { charterer: string; shipper: string; shippingAgent: string },
                filter: {
                    charterer: ColumnFilterItem<PartyLookup, any>[];
                    shipper: ColumnFilterItem<PartyLookup, any>[];
                    shippingAgent: ColumnFilterItem<PartyLookup, any>[];
                }
            ): boolean => {
                if (filter.charterer.length === 0 && filter.shipper.length === 0 && filter.shippingAgent.length === 0) {
                    return true;
                }

                return (
                    filter.charterer.some(
                        (filter: ColumnFilterItem<PartyLookup, string>) =>
                            (filter.filterField ? filter.value?.[filter.filterField] : filter.value) === (filter.dataField ? value?.charterer?.[filter.dataField] : value.charterer)
                    ) ||
                    filter.shipper.some(
                        (filter: ColumnFilterItem<PartyLookup, string>) =>
                            (filter.filterField ? filter.value?.[filter.filterField] : filter.value) === (filter.dataField ? value?.shipper?.[filter.dataField] : value.shipper)
                    ) ||
                    filter.shippingAgent.some(
                        (filter: ColumnFilterItem<PartyLookup, string>) =>
                            (filter.filterField ? filter.value?.[filter.filterField] : filter.value) === (filter.dataField ? value?.shippingAgent?.[filter.dataField] : value.shippingAgent)
                    )
                );
            }
        );
    }
}
