import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class PageIndexResolver {
    /**
     * @param first Index of first row to be displayed
     * @param pageSize 
     * @returns 
     */
    resolve(first: number | undefined, pageSize: number): number {
        return Math.floor((first ?? 0) / pageSize);
    }
}