import { Provider, Type } from '@angular/core';
import { AddedDataService, ChangedDataService, DeletedDataService, SailedDataService } from './data-update-services';
import { DataUpdateStore } from './data-update.store';
import { SignalRChangeHandlerService } from './signalr-change-handler';

/**
 * 
 * @param store Pass the store that extends DataUpdateStore
 * @returns 
 */
export const DATA_UPDATE_PROVIDER = (store: Type<unknown>) => [
    AddedDataService,
    ChangedDataService,
    DeletedDataService,
    SailedDataService,
    { provide: DataUpdateStore, useExisting: store },
    SignalRChangeHandlerService
] as Provider[];
