import { ChangeDetectionStrategy, Component, input, Input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartModule } from 'primeng/chart';
import { Port, PublicHoliday, ScheduledMaintenance } from '@port-line-up/shared/data-access';
import { PortCallReport } from './models/port-call-report';
import { VesselDelayReport } from './models/vessel-delay-report';
import { VesselDelayService } from './services/vessel-delay.service';
import { VesselReportService } from './services/vessel-report.service';
import { GroupVesselsByCargoUomServices } from './services/group-vessels-by-cargo-uom.services';
import { PortDetailMessageService } from './services/port-detail-message.service';
import { ReportTableComponent } from './components/report-table/report-table.component';
import { PortCallPresentation } from '../../models';
import { ScopedSpinnerComponent } from '@port-line-up/shared/ui/scoped-spinner';

@Component({
    selector: 'plu-report',
    templateUrl: 'report.component.html',
    styleUrl: 'report.component.scss',
    imports: [CommonModule, ChartModule, ScopedSpinnerComponent, ReportTableComponent],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [VesselDelayService, VesselReportService, PortDetailMessageService, GroupVesselsByCargoUomServices],
})
export class ReportComponent {
    @Input() set portCalls(portCalls: PortCallPresentation[] | null) {
        if (portCalls === null) {
            return;
        }

        this.createReport(portCalls);
    }

    @Input() set port(port: Port | null) {
        if (!port || port.id.length === 0) {
            return;
        }
        this._port = port;
    }

    get port(): Port {
        return this._port;
    }

    vesselDelayReports = signal<VesselDelayReport[]>([]);
    upcomingPublicHolidays = input.required({
        transform: (value: PublicHoliday[]) => this.portDetailMessageService.createPublicHolidayMessage(value),
    });
    scheduledMaintenance = input.required({
        transform: (value: ScheduledMaintenance[]) => this.portDetailMessageService.createScheduledMaintenanceMessage(value),
    });

    groupedPortCallReports!: Map<string, PortCallReport[]>;
    private _port: Port = new Port();

    constructor(private vesselDelayService: VesselDelayService,
                private groupVesselsByCargoUomServices: GroupVesselsByCargoUomServices,
                private portDetailMessageService: PortDetailMessageService) {}

    private createReport(portCalls: PortCallPresentation[]): void {
        this.groupedPortCallReports = this.groupVesselsByCargoUomServices.groupVesselsByCargoUom(portCalls);
        this.vesselDelayReports
            .set(this.vesselDelayService.getVesselDelayReport(Array.from(this.getTerminalsDistinctFrom(portCalls)), portCalls));
    }

    private getTerminalsDistinctFrom(portCalls: PortCallPresentation[]): Set<string> {
        const terminals: Set<string> = new Set();
        portCalls.forEach((portCall: PortCallPresentation) => {
            if (portCall.terminalName && portCall.terminalName.length > 0) {
                terminals.add(portCall.terminalName);
            }
        });
        return terminals;
    }
}
