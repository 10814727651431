import { AfterViewInit, Directive, EventEmitter, Output } from '@angular/core';
import { Accordion } from 'primeng/accordion';

@Directive({
    selector: '[pluAccordionHeaderHeight]',
    standalone: true
})
export class AccordionHeaderHeightDirective implements AfterViewInit {

    @Output('pluAccordionHeaderHeight') height: EventEmitter<number> = new EventEmitter<number>();
    constructor(private accordion: Accordion) {}

    ngAfterViewInit(): void {
        const accordionHeader = Array.from(this.accordion.el.nativeElement.getElementsByClassName('p-accordion-header'))?.[0];
        this.height.emit(accordionHeader ? (accordionHeader as HTMLDivElement).offsetHeight : 0);
    }
}
