import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, PopupRequest } from '@azure/msal-browser';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { Observable } from 'rxjs';

@Injectable()
export class AuthService {
    constructor(
        private msalService: MsalService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        @Inject(APP_ENVIRONMENT) private appEnvironment: any
    ) {}

    initialize(): Observable<void> {
        return this.msalService.initialize();
    }

    getAllAccounts(): AccountInfo[] {
        return this.msalService.instance.getAllAccounts();
    }

    hasAuthRequest(): boolean {
        return !!this.msalGuardConfig.authRequest;
    }

    loginPopupWithRequest(): Observable<AuthenticationResult> {
        return this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest);
    }

    loginPopup(): Observable<AuthenticationResult> {
        return this.msalService.loginPopup();
    }

    setActiveAccount(account: AccountInfo | null): void {
        this.msalService.instance.setActiveAccount(account);
    }

    logoutPopup(): void {
        const account = this.msalService.instance.getActiveAccount();

        this.msalService.logoutPopup({
            postLogoutRedirectUri: '/',
            mainWindowRedirectUri: '/',
            account,
        });
    }

    acquireTokenSilent(): Observable<AuthenticationResult> {
        return this.msalService.acquireTokenSilent({ scopes: this.appEnvironment.b2cSettings.scopes });
    }
}
