export const environment = {
    production: false,
	baseUrl: 'https://portlineup-dev.gac.com/',
    baseApiUrl: 'https://api-dev.gac.com/external/portlinup/v1/api',
    signalRUrl: 'https://portlineup-dev.gac.com/push/',
    b2cSettings: {
        clientId: 'e5095a40-516d-4fb1-a9e3-43862bae06d3',
        signInAuthority: 'https://login.microsoftonline.com/6e9db74c-11b7-459e-b199-9f044662d260',
        scopes: ['api://e5095a40-516d-4fb1-a9e3-43862bae06d3/access_as_user']
    },
    ocpApimSubscriptionKey: '536ada48ae034c4ab9cc3b94bf1b61d3',
    mapbox: {
        accessToken: 'pk.eyJ1IjoiZ3JvdXBpdCIsImEiOiJjbGV3dTRtOTAwazA0M3JvNHhzbGdxOHVkIn0.4-wcba5ifxEWSahwcol-3w'
    },
    powerBI: {
        embedUrl: 'https://app.powerbi.com/reportEmbed'
    }
}
