<div *ngIf="actualTime">
  <div class="flex items-center">
    <div *ngIf="isClickable; else actualTimeContainer">
      <button (click)="onSwitchToExpectedTime($event)"  class="h-[21px] px-1 text-sm text-white w-14 {{ actualTimeButtonBgColorClass }} flex items-center rounded">
        <img src="assets/images/agent.svg" class="mr-1"/>
        <span>{{actualTimeShorterName}}</span>
      </button>
    </div>
    <ng-template #actualTimeContainer>
      <div class="h-[21px] px-1 text-sm text-white w-14 {{ actualTimeButtonBgColorClass }} flex items-center rounded"      >
        <img src="assets/images/agent.svg" class="mr-1"/>
        <span>{{actualTimeShorterName}}</span>
      </div>
    </ng-template>
    <div class="font-medium ml-1">
      {{actualTime | pluDatePipe}}
    </div>
  </div>
</div>

<div *ngIf="expectedTime && !actualTime">
  <div class="flex items-center">
    <div *ngIf="isClickable; else expectedTimeContainer">
      <button (click)="onSwitchToActualTime($event)"  class="h-[21px] px-1 text-sm text-white w-14 {{ expectedTimeButtonBgColorClass }} flex items-center rounded">
        <img src="assets/images/agent.svg" class="mr-1"/>
        <span>{{expectedTimeShorterName}}</span>
      </button>
    </div>
    <ng-template #expectedTimeContainer>
      <div class="h-[21px] px-1 text-sm text-white w-14 {{ expectedTimeButtonBgColorClass }} flex items-center rounded"      >
        <img src="assets/images/agent.svg" class="mr-1"/>
        <span>{{expectedTimeShorterName}}</span>
      </div>
    </ng-template>
    <div class="font-medium ml-1">
      {{expectedTime | pluDatePipe}}
    </div>
  </div>
</div>
