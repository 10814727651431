import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { ErrorLogTypes } from '../enums';
import { LookupItem } from '@port-line-up/shared/util';

@Injectable({ providedIn: 'root' })
export class ErrorLogTypesService {
    private types: KeyValue<ErrorLogTypes, string>[] = [
        { key: ErrorLogTypes.LoggedIn, value: 'Logged In' },
        { key: ErrorLogTypes.Save, value: 'Save' },
        { key: ErrorLogTypes.Share, value: 'Share' },
        { key: ErrorLogTypes.Export, value: 'Export' },
        { key: ErrorLogTypes.Import, value: 'Import' },
        { key: ErrorLogTypes.Sync, value: 'Sync' },
        { key: ErrorLogTypes.Other, value: 'Other' },
        { key: ErrorLogTypes.Priority, value: 'Priority' },
    ];

    getValue(key: ErrorLogTypes | null): string {
        if (key === null || key === undefined) {
            return '';
        }

        const result = this.types.find((status) => status.key == key);
        return result ? result.value : '';
    }

    getLookups(): LookupItem[] {
        return this.types.map((entry) => new LookupItem(entry.key.toString(), entry.value));
    }
}
