import { Injectable } from '@angular/core';

@Injectable()
export class ScrollableContainerService {
    private container: HTMLElement | null = null;

    setContainer(container: HTMLElement): void {
        this.container = container;
    }

    getContainer(): HTMLElement | null{
        return this.container;
    }

    scrollTo(options: ScrollToOptions): void {
        if (this.container) {
            this.container.scrollTo(options);
        }
    }
    
}