import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntityListResult, FilterItem, TableHttpParamsBuilder, User } from '../models';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { UserSave } from '../models';
import { TableLazyLoadEvent } from 'primeng/table';
import { ExpressionOperator, FilterLogicalOperator } from '../enums';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private readonly baseUrl = `${this.appEnvironment.baseApiUrl}/user`;
    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private appEnvironment: any) {}

    get(pageIndex = 0, pageSize = 10, lazyLoadEvent?: TableLazyLoadEvent): Observable<EntityListResult<User>> {
        const httpParamsBuilder = new TableHttpParamsBuilder().addPagination(pageIndex, pageSize).addSorting(lazyLoadEvent).addFiltering(lazyLoadEvent);
        return this.http.get<EntityListResult<User>>(`${this.baseUrl}?${httpParamsBuilder.getHttpParams()}`);
    }

    getUsersBy(query: string): Observable<EntityListResult<User>> {
        let httpParams = new HttpParams();
        httpParams = FilterItem.create('name', query, ExpressionOperator.Contains, FilterLogicalOperator.OR).updateHttpParams(httpParams, 0);
        httpParams = FilterItem.create('lastName', query, ExpressionOperator.Contains, FilterLogicalOperator.OR).updateHttpParams(httpParams, 1);
        httpParams = FilterItem.create('email', query, ExpressionOperator.Contains, FilterLogicalOperator.OR).updateHttpParams(httpParams, 2);

        return this.http.get<EntityListResult<User>>(`${this.baseUrl}/?${httpParams}`);
    }


    getById(id: string | null): Observable<User | undefined> {
        return this.http.get<User>(`${this.baseUrl}/${id}`);
    }

    save(user: UserSave): Observable<User> {
        return this.http.post<User>(this.baseUrl, user);
    }

    delete(userId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${userId}`);
    }

    getLoggedUserProfile(): Observable<User> {
        return this.http.get<User>(`${this.baseUrl}/loggedUserProfile`);
    }
}
