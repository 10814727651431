import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function dateRangeRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (value.length === 0 || (!!value[0] && !!value[1])) {
            return null;
        }

        return { dateRange: true };
    };
}
