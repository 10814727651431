import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActionLogTypes } from '../enums';
import { LookupItem } from '@port-line-up/shared/util';

@Injectable({ providedIn: 'root' })
export class ActionLogTypesService {
    private types: KeyValue<ActionLogTypes, string>[] = [
        { key: ActionLogTypes.LoggedIn, value: 'Logged In' },
        { key: ActionLogTypes.Save, value: 'Save' },
        { key: ActionLogTypes.Share, value: 'Share' },
        { key: ActionLogTypes.Export, value: 'Export' },
        { key: ActionLogTypes.Import, value: 'Import' },
        { key: ActionLogTypes.SaveSailed, value: 'Save Sailed' }, 
    ];

    getValue(key: ActionLogTypes | null): string {
        if (key === null || key === undefined) {
            return '';
        }

        const result = this.types.find((status) => status.key == key);
        return result ? result.value : '';
    }

    getLookups(): LookupItem[] {
        return this.types.map((entry) => new LookupItem(entry.key.toString(), entry.value));
    }
}
