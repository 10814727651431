import { Injectable } from '@angular/core';
import { UserStateService } from '@port-line-up/shared/data-access';
import { PortCallExcelHeaders } from '@port-line-up/shared/util';
import { first } from 'rxjs';
import { ExcelHeadersResolver } from '../excel-headers-resolver';
import { ExcelHeadersGacUserResolver } from './resolvers/gac-user.resolver';
import { ExcelHeadersAdminResolver } from './resolvers/admin.resolver';
import { ExcelHeadersDefaultResolver } from './resolvers/default.resolver';

@Injectable({providedIn: 'root'})
export class ExcelHeadersResolverService {
    private resolvers: ExcelHeadersResolver[] = [];
    constructor(userStateService: UserStateService) {
        userStateService.user$.pipe(first()).subscribe((user) => {
            if (!user) {
                this.resolvers = [new ExcelHeadersDefaultResolver()]
                return;
            }

            this.resolvers = [new ExcelHeadersGacUserResolver(user), new ExcelHeadersAdminResolver(user), new ExcelHeadersDefaultResolver()];
        });
    }

    resolve(): PortCallExcelHeaders[] {
        return (this.resolvers.find((r) => r.canResolve()) as ExcelHeadersResolver).resolve();
    }
    
}