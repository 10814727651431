import { Directive, inject } from '@angular/core';
import { CargoColumnFilterService, CustomColumnFiltersService } from '@port-line-up/shared/util';
import { Table } from 'primeng/table';

@Directive({
    selector: '[pluPresentationTableRegisterFilterRuleDirective]',
    standalone: true,
})
export class PresentationTableRegisterFilterRuleDirective {
    private readonly table = inject(Table);
    private readonly cargoColumnFilterService = inject(CargoColumnFilterService);
    private readonly customColumnFiltersService = inject(CustomColumnFiltersService);

    // Filters must be registered during construction time, onInit is too late.
    constructor() {
        this.registerTableCustomFilters();
    }

    private registerTableCustomFilters(): void {
        this.cargoColumnFilterService.registerCargoTypeNameFilterRule(this.table);

        this.customColumnFiltersService.registerPartyFilters(this.table);
        this.customColumnFiltersService.registerColumnFilterItem(this.table);
        this.customColumnFiltersService.registerEmptyDateFilter(this.table);
    }
}
