import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HoursService {
  // From total hours get remaining hours after full days are extracted.
  getHours(value: number): number | null {
    const hoursPerDay = 24;
    const hours = value % hoursPerDay;

    if (hours === 0) return null;

    return hours;
  }

  // From total hours get days.
  getDays(value: number): number | null {
    const hoursPerDay = 24;

    if (value < hoursPerDay) return null;

    return Math.floor(value / hoursPerDay);
  }
}
