<p-columnFilter
  pluShowCloseButton
  pluApplyFilterOnFilterMetadataChange
  [field]="fieldName"
  display="menu"
  [placeholder]="placeholder"
  [showAddButton]="false"
  [showOperator]="false"
  [matchModeOptions]="matchModeOptions"
>
  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
    <div class="mb-4">
      <label for="days">Days</label>
      <p-inputNumber
        #daysInput
        pInputText
        inputId="days"
        type="text"
        [ngModel]="value | pluHoursToDaysPipe"
        (onBlur)="
          checkIfAtLeastOnValueExist(daysInput.value, hoursInput.value) &&
            filter(getFilterValue(daysInput.value, hoursInput.value))
        "
      />
    </div>

    <label for="hours">Hours</label>
    <p-inputNumber
      #hoursInput
      pInputText
      [min]="1"
      [max]="23"
      inputId="hours"
      type="text"
      [ngModel]="value | pluHoursToRemainingHoursPipe"
      (onBlur)="
        checkIfAtLeastOnValueExist(daysInput.value, hoursInput.value) &&
          filter(getFilterValue(daysInput.value, hoursInput.value))
      "
    />
  </ng-template>
</p-columnFilter>
