import { Injectable } from '@angular/core';
import { Column } from '@port-line-up/shared/util';

@Injectable({providedIn: 'root'})
export class HandleColumnsWidthsService {

    handleColumnsWidthsService(columns: Column[], tableWidth: number): Column[] {
        const visibleColumnWidth: number =
            columns.reduce((accumulator: number, currentValue: Column)=> accumulator + +currentValue.width, 0);

        if (visibleColumnWidth < tableWidth && columns.length > 0) {
            const widthDifference: number = tableWidth - visibleColumnWidth;

            const differenceToAddToColumnsWidth: number = widthDifference / columns.length;
            columns.forEach((column: Column): void => {
                column.width =  (+column.width +  differenceToAddToColumnsWidth).toString();
            })
        }

        return columns;
    }
}
