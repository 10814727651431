import { Directive, EventEmitter, Output } from '@angular/core';
import { ColumnFilter } from 'primeng/table';

@Directive({ selector: '[pluOnClear]', standalone: true })
export class ColumnFilterClearNotificationDirective {
  @Output() public pluOnClear = new EventEmitter<void>();

  constructor(columnFilter: ColumnFilter) {
    const handler = {
      apply: (target: any, thisArg: any, argumentsList: any) => {
        this.pluOnClear.emit();

        return target.apply(thisArg, argumentsList);
      }
    };

    const proxy = new Proxy(columnFilter.clearFilter, handler);
    columnFilter.clearFilter = proxy;
  }
}