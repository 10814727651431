import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { Company, FilterItem, TableHttpParamsBuilder, MyCompanySave } from '../models';
import { EntityListResult } from '../models';
import { CompanySave } from '../models';
import { ExpressionOperator } from '../enums';
import { TableLazyLoadEvent } from 'primeng/table';

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    private readonly baseUrl = `${this.appEnvironment.baseApiUrl}/company`;
    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private appEnvironment: any) {}

    get(pageIndex = 0, pageSize = 10, lazyLoadEvent?: TableLazyLoadEvent): Observable<EntityListResult<Company>> {
        const httpParamsBuilder = new TableHttpParamsBuilder().addPagination(pageIndex, pageSize).addSorting(lazyLoadEvent).addFiltering(lazyLoadEvent);
        return this.http.get<EntityListResult<Company>>(`${this.baseUrl}?${httpParamsBuilder.getHttpParams()}`);
    }

    getById(id: string | null): Observable<Company> {
        return this.http.get<Company>(`${this.baseUrl}/${id}`);
    }

    getByName(name: string): Observable<EntityListResult<Company>> {
        const queryString = FilterItem.create('name', name, ExpressionOperator.StartsWith).getHttpParams(0);
        return this.http.get<EntityListResult<Company>>(`${this.baseUrl}/?${queryString}`);
    }

    getMyCompany(): Observable<EntityListResult<Company>> {
        return this.http.get<EntityListResult<Company>>(`${this.baseUrl}/myCompany`);
    }

    save(company: CompanySave): Observable<Company> {
        return this.http.post<Company>(this.baseUrl, company);
    }

    saveMyCompany(company: MyCompanySave): Observable<Company> {
        return this.http.post<Company>(`${this.baseUrl}/myCompany`, company);
    }

    delete(companyId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${companyId}`);
    }
}
