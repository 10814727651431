import { AfterViewInit, ContentChild, Directive } from '@angular/core';
import { AccordionTab } from 'primeng/accordion';
import { AccordionHeaderTemplate } from './accordion-header-template';

@Directive({ selector: '[pluAccordionSetter]', standalone: true })
export class AccordionHeaderSetterDirective implements AfterViewInit {
    @ContentChild(AccordionHeaderTemplate) component!: AccordionHeaderTemplate;
    constructor(private accordionTab: AccordionTab) {}
    ngAfterViewInit(): void {
        this.accordionTab.headerTemplate = this.component.headerTemplate;
    }
}
