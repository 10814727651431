import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { SharedViewResponse, SharedViewService } from '@port-line-up/shared/data-access';
import { ActionPendingInterceptor } from '@port-line-up/shared/util';
import { SharedViewGridColumnsFactoryService } from './shared-view/services/grid-columns.factory';
import { tap } from 'rxjs';

export const sharedViewResolver: ResolveFn<SharedViewResponse> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const sharedViewService = inject(ActionPendingInterceptor).createInterceptor(inject(SharedViewService));
    const columnsFactory = inject(SharedViewGridColumnsFactoryService);
    return sharedViewService.getActive(route?.params['id'], route?.params['token']).pipe(
        tap((res) => {
            columnsFactory.setColumns(res.columnsConfig);
        })
    );
};
