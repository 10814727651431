import { Injectable } from '@angular/core';
import { DynamicColumnResolver } from '../dynamic-column-resolver';
import { Column } from '@port-line-up/shared/util';
import { UserStateService } from '@port-line-up/shared/data-access';
import { first } from 'rxjs';
import { GroupByViewGacUserResolver } from './resolvers/gac-user.resolver';
import { GroupByViewAdminResolver } from './resolvers/admin.resolver';
import { GroupByViewDefaultResolver } from './resolvers/default.resolver';

@Injectable({ providedIn: 'root' })
export class GroupByViewColumnResolver {
    private resolvers: DynamicColumnResolver[] = [];
    constructor(userStateService: UserStateService) {
        userStateService.user$.pipe(first()).subscribe((user) => {
            if (!user) {
                return;
            }
            this.resolvers = [new GroupByViewGacUserResolver(user), new GroupByViewAdminResolver(user), new GroupByViewDefaultResolver()];
        });
    }

    resolve(): Column[] {
        return (this.resolvers.find((r) => r.canResolve()) as DynamicColumnResolver).resolve();
    }
}
