export class PartyGridFilterChip {
    filterId = '';
    partyChipItems: PartyChipItem[] = [];

    constructor(filterId: string) {
        this.filterId = filterId;
    }

    addPartyChipItem(title: string, value: string): void {
        this.partyChipItems.push(new PartyChipItem(title, value));
    }
}

export class PartyChipItem {
    title = '';
    value = '';

    constructor(title: string, value: string) {
        this.title = title;
        this.value = value;
    }
}
