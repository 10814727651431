
<div *ngIf="(dataChangedMessage$ | async) as message" class="pr-2 w-72 leading-4 flex text-blue-500 flex-col">
    <div *ngIf="message.dataChangeMessage">
        <i class="pi pi-exclamation-circle pr-1"></i>
        <span>
            {{message.dataChangeMessage}}
        </span>
    </div>

    <div *ngIf="message.dataAddedMessage">
        <i class="pi pi-exclamation-circle pr-1"></i>
        <span>
            {{message.dataAddedMessage}}
        </span>
    </div>

    <div *ngIf="message.dataDeletedMessage">
      <i class="pi pi-exclamation-triangle pr-1"></i>
        <span>
            {{message.dataDeletedMessage}}
        </span>
    </div>

    <div *ngIf="message.dataSailedMessage">
        <i class="pi pi-exclamation-triangle pr-1"></i>
          <span>
              {{message.dataSailedMessage}}
          </span>
      </div>
</div>
