import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserStateService {
    private _user: User | null = null;
    private userSubject = new BehaviorSubject<User | null>(null);
    user$ = this.userSubject.asObservable();

    public setUser(user: User): void {
        this._user = User.createFrom(user);
        this.userSubject.next(this._user);
    }

    public removeUser(): void {
        this._user = null;
        this.userSubject.next(null);
    }

    public get user(): User | null {
        return this._user;
    }
}
