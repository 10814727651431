import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    EntityListResult,
    ErrorLog,
    ActionLog,
    ServerError,
    TableHttpParamsBuilder,
    UsageReportFilter,
    ExcelExportLog
} from '../models';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { CustomFilters } from '../models/custom-table-filter';
import { TableLazyLoadEvent } from 'primeng/table';

@Injectable({
    providedIn: 'root',
})
export class LogService {
    public readonly logServerErrorEndpoint = 'logServerError';
    private readonly url = `${this.appEnvironment.baseApiUrl}/logger`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private appEnvironment: any) {}

    getErrorLogs(pageIndex = 0, pageSize = 10, lazyLoadEvent?: TableLazyLoadEvent): Observable<EntityListResult<ErrorLog>> {
        const httpParamsBuilder = new TableHttpParamsBuilder().addPagination(pageIndex, pageSize).addSorting(lazyLoadEvent).addFiltering(lazyLoadEvent);
        return this.http.get<EntityListResult<ErrorLog>>(`${this.url}/errorLogs?${httpParamsBuilder.getHttpParams()}`);
    }

    getActionLogs(
        pageIndex = 0,
        pageSize = 10,
        lazyLoadEvent?: TableLazyLoadEvent,
        customFilters?: CustomFilters
    ): Observable<EntityListResult<ActionLog>> {
        const httpParamsBuilder = new TableHttpParamsBuilder()
            .addPagination(pageIndex, pageSize)
            .addSorting(lazyLoadEvent)
            .addFiltering(lazyLoadEvent, customFilters);
        return this.http.get<EntityListResult<ActionLog>>(`${this.url}/actionLogs?${httpParamsBuilder.getHttpParams()}`);
    }

    getActionLogsUsageReports(
      usageReportFilter: UsageReportFilter
    ): Observable<EntityListResult<ActionLog>> {
        return this.http.post<EntityListResult<ActionLog>>(`${this.url}/usageReport`, usageReportFilter);
    }

    logServerError(model: ServerError): Observable<void> {
        return this.http.post<void>(`${this.url}/${this.logServerErrorEndpoint}`, model);
    }

    logExcelExport(model: ExcelExportLog): Observable<void> {
        return this.http.post<void>(`${this.url}/logExcelExport`, model);
    }

    logExcelExportAnonymous(model: ExcelExportLog): Observable<void> {
        return this.http.post<void>(`${this.url}/logExcelExport/anonymous`, model);
    }
}
