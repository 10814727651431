import { HttpErrorResponse } from '@angular/common/http';
import { signalStoreFeature, withState } from '@ngrx/signals';
import { ApiResponse } from '../../models';
import { ApiResponseType } from '../../enums';
import { ErrorMessageService } from '../../services';

export type ApiResponseStatusState = { status: ApiResponse | null };

export function withApiResponseStatus() {
    return signalStoreFeature(
        withState<ApiResponseStatusState>({ status: null }),
    );
}

export function setError(error: HttpErrorResponse | null, errorMessageService: ErrorMessageService): ApiResponseStatusState {
    return { status: new ApiResponse(ApiResponseType.Error, error?.error ? errorMessageService.handleErrorMessage(error?.error) : '') };
}

export function setSuccess(message: string): ApiResponseStatusState {
    return { status: new ApiResponse(ApiResponseType.Success, message) };
}
