import { Directive } from '@angular/core';
import { FilterMetadata } from 'primeng/api';
import { ColumnFilter } from 'primeng/table';
import { PartyFilter } from '../../models';

/*
* [PRIMENG_OVERRIDE]
* The first override function enable us to set default value for party filter when user click on "Clear" button.
* The second override function checks if is party filter empty or not.
* */
@Directive({
    selector: '[pluPartyColumnFilter]',
    standalone: true
})
export class PartyColumnFilterDirective {

    constructor(private columnFilter: ColumnFilter) {

        this.columnFilter.initFieldFilterConstraint = () => {
            const defaultMatchMode: string = this.columnFilter.getDefaultMatchMode();
            this.columnFilter.dt.filters[<string>this.columnFilter.field] = this.columnFilter.display == 'row' ?
                 { value: new PartyFilter(), matchMode: defaultMatchMode } :
                [{ value: new PartyFilter(), matchMode: defaultMatchMode, operator: this.columnFilter.operator }];
        }

        this.columnFilter.hasFilter = () => {
            const fieldFilter: FilterMetadata | FilterMetadata[] =
                this.columnFilter.dt.filters[<string>this.columnFilter.field];

            if (!fieldFilter) {
                return false;
            }

            const partyFilterValue: PartyFilter = (<FilterMetadata[]>fieldFilter)[0].value as PartyFilter;

            return partyFilterValue.shipper.length > 0 ||
                   partyFilterValue.charterer.length > 0 ||
                   partyFilterValue.shippingAgent.length > 0;
        }
    }
}
