import { AfterViewInit, Directive, HostBinding, OnDestroy } from '@angular/core';
import { distinctUntilChanged, filter, fromEvent, Subject, takeUntil } from 'rxjs';
import { Table } from 'primeng/table';
import { SliderService } from '../../services';

@Directive({
    selector: '[pluTableGlobalHorizontalScroll]',
    standalone: true,
})
export class TableGlobalHorizontalScrollDirective implements AfterViewInit, OnDestroy {

    private scrollLeft = 0;
    private destroy$ = new Subject<void>();
    private tableObserver!: ResizeObserver;

    @HostBinding('class') className = 'hide-table-horizontal-scrollbar';

    constructor(private table: Table, private slideService: SliderService) {}

    ngAfterViewInit(): void {
       this.listenToTableScrollEvent();
       this.subscribeToTableScrollLeftValueChange();
       this.observeTableResize();
    }

    ngOnDestroy(): void {
        this.tableObserver.disconnect();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private observeTableResize(): void {
        this.tableObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                this.slideService.setTableWidthTo(entry.contentRect.width)
            });
        });

        this.tableObserver.observe(this.table.tableViewChild?.nativeElement);
    }

    private subscribeToTableScrollLeftValueChange(): void {
        this.slideService.tableScrollLeft$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((scrollLeft) => {
            if (this.table.wrapperViewChild && typeof scrollLeft === "number" && this.scrollLeft !== scrollLeft) {
                this.scrollLeft = scrollLeft;
                this.table.wrapperViewChild.nativeElement.scrollLeft = scrollLeft;
            }
        });
    }

    private listenToTableScrollEvent(): void {
        fromEvent(this.table.wrapperViewChild?.nativeElement, 'scroll')
            .pipe(takeUntil(this.destroy$), filter(wrapper => {
                const { scrollLeft } = (wrapper as any).target;
                if (scrollLeft === this.scrollLeft) {
                    return false;
                }
                this.scrollLeft = scrollLeft;
                return true;
            })).subscribe(( wrapper ) => {
            const { scrollLeft } = (wrapper as any).target;
            this.slideService.tableScrollLeft(scrollLeft);
        });
    }
}
