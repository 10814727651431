import { Injectable } from '@angular/core';
import { Column, GridColumnFactory } from '@port-line-up/shared/util';

@Injectable({ providedIn: 'root' })
export class SharedViewGridColumnsFactoryService implements GridColumnFactory {
    private columns: Column[] = [];

    create(): Column[] {
        return this.columns;
    }

    setColumns(columns: Column[]): void {
        this.columns = columns;
    }
}
