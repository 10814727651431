import { Directive, inject, input } from '@angular/core';
import { Port, VesselLocationService, VesselsLocationUpdate } from '@port-line-up/shared/data-access';
import { VesselsLocationUpdater } from '@port-line-up/shared/feature-presentation-table';
import { Observable } from 'rxjs';
import { SharedViewStore } from '../../shared-view.store';

@Directive({ selector: '[pluVesselsLocationUpdate]', standalone: true })
export class VesselsLocationUpdateDirective extends VesselsLocationUpdater {
  port = input.required<Port>();

  private readonly vesselLocationService = inject(VesselLocationService);
  private readonly sharedViewStore = inject(SharedViewStore);

  override selectedIdentifier$: Observable<string | null> = this.sharedViewStore.token$;

  getVesselsLocation(token: string): Observable<VesselsLocationUpdate[]> {
    return this.vesselLocationService.getByUnlocodeForSharedView(this.port()?.unloCode ?? null, token);
  }
}