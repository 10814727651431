export class GridFilterChip {
    filterId = '';
    title = '';
    values: string[] = [];

    constructor(filterId: string, title: string) {
        this.filterId = filterId;
        this.title = title;
    }
}
