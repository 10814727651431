<div class="mt-2">
  <div class="flex justify-between border-b mb-1" *ngFor="let asset of assets">
    <div class="whitespace-nowrap overflow-hidden truncate">
      <p class="inline">{{asset.fileName}}{{asset.extension}}</p>
    </div>
    <p-button icon="pi pi-download" [outlined]="true" (onClick)="download(asset)"
              styleClass="p-button-sm w-5 h-5 border-gray-300 text-gray-500 border-none shadow-none"></p-button>
  </div>

  <div *ngIf="assets.length === 0" class="text-center mt-8">
    <h3>No documents were attached!</h3>
  </div>
</div>
