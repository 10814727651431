import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ScrollableContainerDirective } from './scrollable-container.directive';

@Component({
    selector: 'plu-scrollable-container',
    standalone: true,
    imports: [ScrollableContainerDirective],
    templateUrl: './scrollable-container.component.html',
    styleUrl: './scrollable-container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollableContainerComponent {}
