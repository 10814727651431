import { Injectable } from '@angular/core';
import { first } from 'rxjs';
import { UserStateService } from '@port-line-up/shared/data-access';
import { ExcelUploadMappingDataResolver } from '../excel-upload-mapping-data-resolver';
import { ExcelUploadMappingDataGacUserResolver } from './resolvers/gac-user.resolver';
import { ExcelUploadMappingDataAdminResolver } from './resolvers/admin.resolver';
import { ExcelUploadMappingDataDefaultResolver } from './resolvers/default.resolver';
import { PropertyNameExcelHeaderMapping } from '@port-line-up/shared/util';

@Injectable({ providedIn: 'root' })
export class ExcelUploadMappingsDataResolver {
    private resolvers: ExcelUploadMappingDataResolver[] = [];
    constructor(userStateService: UserStateService) {
        userStateService.user$.pipe(first()).subscribe((user) => {
            if (!user) {
                return;
            }

            this.resolvers = [new ExcelUploadMappingDataGacUserResolver(user), new ExcelUploadMappingDataAdminResolver(user), new ExcelUploadMappingDataDefaultResolver()];
        });
    }

    resolve(): PropertyNameExcelHeaderMapping[] {
        return (this.resolvers.find((r) => r.canResolve()) as ExcelUploadMappingDataResolver).resolve();
    }
}
