import { Injectable } from '@angular/core';
import { GridFilterItem } from '@port-line-up/shared/util';
import { FilterMatchMode, FilterOperator } from 'primeng/api';

@Injectable()
export class GridFilterItemFactory {
    create(filterId: string, value: any, matchMode: FilterMatchMode, operator: FilterOperator): GridFilterItem {
        return {
            [filterId]: [
                {
                    value: value,
                    matchMode: matchMode,
                    operator: operator,
                },
            ],
        } as GridFilterItem;
    }
}
