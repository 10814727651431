export class PublicHoliday {
    id = '';
    portId = '';
    portName = ''
    name = '';
    from = '';
    to = '';
    note = '';

    static create(portId: string, portName: string): PublicHoliday {
        const respone = new PublicHoliday();
        respone.portId = portId;
        respone.portName = portName;
        return respone; 
    }
}
