import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT, PartyLookup } from '@port-line-up/shared/util';
import { PartyTypeCode } from '../enums';
import { MdmVessel } from '../models';

@Injectable({ providedIn: 'root' })
export class MdmService {
    private readonly url = `${this.appEnvironment.baseApiUrl}/mdm`;

    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private appEnvironment: any) {}

    getParties(name: string, partyTypeCodes: PartyTypeCode[]): Observable<PartyLookup[]> {
        let queryString = new HttpParams().set(`name`, name);
        for (const partyType of partyTypeCodes) {
            queryString = queryString.append(`partyType`, partyType);
        }
        return this.http.get<PartyLookup[]>(`${this.url}/party/lookup?${queryString}`);
    }

    getVesselBy(imoNumber: string): Observable<MdmVessel> {
        return this.http.get<MdmVessel>(`${this.url}/vessel?imoNumber=${imoNumber}`);
    }
}
