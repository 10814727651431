<p-columnFilter pluShowCloseButton (pluOnClear)="clearFilters.emit()"
    [field]="field" [matchMode]="CustomMatchMode.ColumnFilterItemIn" display="menu" [showMatchModes]="false" [showOperator]="false"
    [showAddButton]="false">
    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
        <p-autoComplete [ngModel]="value" [ngModelOptions]="{standalone: true}" pluAutoCompleteColumnFilterCommonSettings optionLabel="filterLabel" dataKey="value.id"
            [suggestions]="suggestions"
            (completeMethod)="search.emit($event.query)"
            (onSelect)="applyFilter.emit({filter, value: $event.value})"
            (onUnselect)="unApplyFilter.emit({filter, value: $event.value})"
            placeholder="Vessel name or Imo Number" filterPlaceholder="Search for Vessel Name or Imo Number">
            <ng-template let-item pTemplate="item">
                <div class="flex flex-col">
                    <span class="font-medium">{{item?.filterLabel}}</span>
                    <span class="text-primary-500 text-sm">{{item?.value?.imoNumber}}</span>
                </div>
            </ng-template>
        </p-autoComplete>
    </ng-template>
</p-columnFilter>