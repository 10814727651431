import { Injectable } from '@angular/core';
import { PortCall } from '../models';

@Injectable({ providedIn: 'root' })
export class BerthDelayService {
    getBerthDelay(portCall: PortCall): number | null {
        // order of items matters, it's based on priority of pairs
        const timePairs = [
            { start: portCall.atb, end: portCall.ata },
            { start: portCall.atb, end: portCall.ataihs },
            { start: portCall.atb, end: portCall.eta },
            { start: portCall.atb, end: portCall.etaihs },
            { start: portCall.atbihs, end: portCall.ata },
            { start: portCall.atbihs, end: portCall.ataihs },
            { start: portCall.atbihs, end: portCall.eta },
            { start: portCall.atbihs, end: portCall.etaihs },
            { start: portCall.etb, end: portCall.ata },
            { start: portCall.etb, end: portCall.ataihs },
            { start: portCall.etb, end: portCall.eta },
            { start: portCall.etb, end: portCall.etaihs },
        ];

        for (const { start, end } of timePairs) {
            if (start && end) {
                return this.calcHoursDelayed(start, end);
            }
        }

        return null;
    }

    private calcHoursDelayed(date1: Date | string, date2: Date | string): number | null {
        const hoursDelay = Math.ceil((new Date(date1).getTime() - new Date(date2).getTime()) / (1000 * 60 * 60));

        return hoursDelay < 0 ? null : hoursDelay;
    }
}
