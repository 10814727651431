<div class="flex">
  @for (item of items(); track $index) {
    @if (item === 1 && activeItem() === 1) { 
      <div class="dot mr-1 last:mr-0 bg-green-500 text-white font-bold">
        <i class="pi pi-check text-[8px]"></i>
      </div>
    } @else {
      <div
        class="dot mr-1 last:mr-0 font-bold"
        [ngClass]="{ 'text-white bg-blue-700': item === activeItem(), 'bg-primary-300': item !== activeItem() }"
      >
        {{ item }}
      </div>
    }
  }
</div>