import { Component } from '@angular/core';

@Component({
    selector: 'plu-disclaimer',
    templateUrl: 'disclaimer.component.html',
    styles: `
        :host {
            @apply block italic text-xs;
        } 
    `,
    standalone: true,
})
export class DisclaimerComponent {}
