import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { ExcelHeaders, PortCallExcelInfo } from '../models';

@Injectable({ providedIn: 'root' })
export class ImportPreviewService {
    private readonly url = this.appEnvironment.baseApiUrl;

    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private appEnvironment: any) {}

    getPortCallsFrom(file: FormData): Observable<PortCallExcelInfo[]> {
        return this.http.post<PortCallExcelInfo[]>(`${this.url}/ExcelUpload/upload`, file);
    }

    analyzeHeaders(file: FormData): Observable<ExcelHeaders> {
        return this.http.post<ExcelHeaders>(`${this.url}/ExcelUpload/analyze`, file);
    }
}
