<div class="w-full h-full flex justify-center flex-col overflow-auto relative">
  <div>
    <table class="w-full">
      <thead></thead>
      <tbody>
          <tr>
            <td class="text-start w-72 font-bold">GENERAL MARKS:</td>
            <td>{{ port.generalRemarks }}</td>
          </tr>
          <tr>
            <td class="text-start w-72 font-bold">UPCOMING PUBLIC HOLIDAYS:</td>
            <td class="whitespace-pre-line">{{ upcomingPublicHolidays() }}</td>
          </tr>
          <tr>
            <td class="text-start w-72 font-bold">SCHEDULED MAINTENANCE:</td>
            <td class="whitespace-pre-line">{{ scheduledMaintenance() }}</td>
          </tr>
      </tbody>
    </table>

    <ng-container *ngFor="let groupedPortCallReport of groupedPortCallReports | keyvalue">
      <plu-report-table [portCalls]="groupedPortCallReport.value" [quantityUom]="groupedPortCallReport.key"></plu-report-table>
    </ng-container>

    <div class="flex mt-5">
        <table>
          <thead>
              <tr>
                <th class="w-72 bg-blue-900 text-white">Terminals</th>
                <th class="bg-blue-900 text-white">AVERAGE DELAY FOR V/L AT ANCHOR (hours)</th>
                <th class="bg-blue-900 text-white">AVERAGE HOURS DELAY FOR V/L ARRIVING IN 7 DAYS</th>
                <th class="bg-blue-900 text-white">AVERAGE HOURS DELAY FOR V/L ARRIVING IN 7 TO 14 DAYS</th>
                <th class="bg-blue-900 text-white">AVERAGE HOURS DELAY FOR V/L ARRIVING IN 14 TO 21 DAYS</th>
                <th class="bg-blue-900 text-white">AVERAGE HOURS DELAY FOR V/L ARRIVING IN 21+ DAYS</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let vesselDelayReport of vesselDelayReports()">
                <td class="text-center">{{ vesselDelayReport.terminalName }}</td>
                <td class="text-center">{{ vesselDelayReport.averageDelayForVlAtAnchor() | number }}</td>
                <td class="text-center">{{ vesselDelayReport.averageDaysDelayForVlArrivingIn0To7Days() | number }}</td>
                <td class="text-center">{{ vesselDelayReport.averageDaysDelayForVlArrivingIn7To14Days() | number }}</td>
                <td class="text-center">{{ vesselDelayReport.averageDaysDelayForVlArrivingIn14To21Days() | number }}</td>
                <td class="text-center">{{ vesselDelayReport.averageDaysDelayForVlArrivingIn21PlusDays() | number }}</td>
              </tr>
          </tbody>
        </table>
    </div>
  </div>
</div>

