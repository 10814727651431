import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExpressionOperator, FilterLogicalOperator } from '../enums';
import {
    Berth,
    EntityListResult,
    TerminalSave,
    FilterItem,
    Port,
    PortLookup,
    PortSave,
    PublicHoliday,
    PublicHolidaySave,
    ScheduledMaintenance,
    ScheduledMaintenanceSave,
    TableHttpParamsBuilder,
} from '../models';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { PortSupportedSave } from '../models';
import { TableLazyLoadEvent } from 'primeng/table';
import {
    Terminal
} from '../models';


@Injectable({ providedIn: 'root' })
export class PortService {
    private readonly url = `${this.appEnvironment.baseApiUrl}/port`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private appEnvironment: any) { }

    getSupportedPorts(): Observable<EntityListResult<Port>> {
        return this.http.get<EntityListResult<Port>>(`${this.url}/supported?`);
    }

    savePort(port: PortSave): Observable<Port> {
        return this.http.post<Port>(`${this.url}`, port);
    }

    saveSupportedPort(port: PortSupportedSave): Observable<Port> {
        return this.http.post<Port>(`${this.url}/supported`, port);
    }

    getPorts(pageIndex = 0, pageSize = 10, lazyLoadEvent?: TableLazyLoadEvent): Observable<EntityListResult<Port>> {
        const httpParamsBuilder = new TableHttpParamsBuilder().addPagination(pageIndex, pageSize).addSorting(lazyLoadEvent).addFiltering(lazyLoadEvent);
        return this.http.get<EntityListResult<Port>>(`${this.url}?${httpParamsBuilder.getHttpParams()}`);
    }

    getSupportedPortsBy(query: string): Observable<EntityListResult<Port>> {
        const nameQuery= FilterItem.create('name', query, ExpressionOperator.Contains, FilterLogicalOperator.OR).getHttpParams(0);
        const httpParams = FilterItem.create('unloCode', query, ExpressionOperator.Contains, FilterLogicalOperator.OR).updateHttpParams(nameQuery, 1);
        return this.http.get<EntityListResult<Port>>(`${this.url}/supported?${httpParams}`);
    }

    getLookupsBy(name: string, pageSize = 10): Observable<PortLookup[]> {
        const queryString = FilterItem.create('name', name, ExpressionOperator.StartsWith).getHttpParams(0).set('pageSize', pageSize);
        return this.http.get<PortLookup[]>(`${this.url}/lookup/?${queryString}`);
    }

    getPortById(id: string | null): Observable<Port> {
        return this.http.get<Port>(`${this.url}/${id}`);
    }

    getPortBy(unloCode: string | null | undefined): Observable<EntityListResult<Port>> {
        if (!unloCode) {
            throw new Error('Unlo code is required');
        }

        const queryString = FilterItem.create('unloCode', unloCode, ExpressionOperator.StartsWith).getHttpParams(0);
        return this.http.get<EntityListResult<Port>>(`${this.url}/?${queryString}`);
    }

    getPortsBy(unloCodes: string[]): Observable<Port[]> {
        return this.http.post<Port[]>(`${this.url}/list`, unloCodes);
    }

    getBerthsByPortId(portId: string): Observable<Berth[]> {
        return this.http.get<Berth[]>(`${this.url}/${portId}/berths`);
    }

    getPublicHolidays(portId: string, pageIndex = 0, pageSize = 10, lazyLoadEvent?: TableLazyLoadEvent): Observable<EntityListResult<PublicHoliday>> {
        const queryString = new TableHttpParamsBuilder()
            .addPagination(pageIndex, pageSize)
            .addSorting(lazyLoadEvent)
            .addFiltering(lazyLoadEvent)
            .getHttpParams();
        return this.http.get<EntityListResult<PublicHoliday>>(`${this.url}/${portId}/publicHolidays?${queryString}`);
    }

    getUpcomingPublicHolidaysForPortReport(portId: string): Observable<EntityListResult<PublicHoliday>> {
        const queryString = FilterItem.create('to', new Date().toISOString(), ExpressionOperator.GreaterOrEquals, 0).getHttpParams(0).set('pageIndex', 0).set('pageSize', 100);

        return this.http.get<EntityListResult<PublicHoliday>>(`${this.url}/${portId}/publicHolidays?${queryString}`);
    }

    getUpcomingPublicHolidaysForPortReportSharedView(portId: string, token: string): Observable<EntityListResult<PublicHoliday>> {
        const headers = { Authorization: `Bearer ${token}` };
        const queryString = FilterItem.create('to', new Date().toISOString(), ExpressionOperator.GreaterOrEquals, 0).getHttpParams(0).set('pageIndex', 0).set('pageSize', 100);

        return this.http.get<EntityListResult<PublicHoliday>>(`${this.url}/${portId}/publicHolidays/anonymous?${queryString}`, { headers });
    }

    savePublicHoliday(model: PublicHolidaySave): Observable<PublicHoliday> {
        return this.http.post<PublicHoliday>(`${this.url}/${model.portId}/publicHoliday`, model);
    }

    deletePublicHoliday(portId: string, publicHolidayId: string): Observable<void> {
        return this.http.delete<void>(`${this.url}/${portId}/publicHoliday/${publicHolidayId}`);
    }

    getMaintenances(portId: string, pageIndex = 0, pageSize = 10, lazyLoadEvent?: TableLazyLoadEvent): Observable<EntityListResult<ScheduledMaintenance>> {
        const queryString = new TableHttpParamsBuilder()
            .addPagination(pageIndex, pageSize)
            .addSorting(lazyLoadEvent)
            .addFiltering(lazyLoadEvent)
            .getHttpParams();
        return this.http.get<EntityListResult<ScheduledMaintenance>>(`${this.url}/${portId}/maintenances?${queryString}`);
    }

    getScheduledMaintenanceForPortReport(portId: string): Observable<EntityListResult<ScheduledMaintenance>> {
        const queryString = FilterItem.create('to', (new Date()).toISOString(), ExpressionOperator.GreaterOrEquals, 0).getHttpParams(0).set('pageIndex', 0).set('pageSize', 100);
        return this.http.get<EntityListResult<ScheduledMaintenance>>(`${this.url}/${portId}/maintenances?${queryString}`);
    }

    getScheduledMaintenanceForPortReportSharedView(portId: string, token: string): Observable<EntityListResult<ScheduledMaintenance>> {
        const headers = { Authorization: `Bearer ${token}` };

        let queryString = FilterItem.create('portId', portId).getHttpParams(0).set('pageIndex', 0).set('pageSize', 100);
        queryString = FilterItem.create('to', new Date().toISOString(), ExpressionOperator.GreaterOrEquals, 0).updateHttpParams(queryString, 1);

        return this.http.get<EntityListResult<ScheduledMaintenance>>(`${this.url}/${portId}/maintenances/anonymous?${queryString}`, { headers });
    }

    saveMaintenance(model: ScheduledMaintenanceSave): Observable<ScheduledMaintenance> {
        return this.http.post<ScheduledMaintenance>(`${this.url}/${model.portId}/maintenance`, model);
    }

    deleteMaintenance(portId: string, maintenanceId: string): Observable<void> {
        return this.http.delete<void>(`${this.url}/${portId}/maintenance/${maintenanceId}`);
    }


    getTerminalsBy(portId: string): Observable<Terminal[]> {
        return this.http.get<Terminal[]>(`${this.url}/${portId}/terminals`);
    }

    getBerthsBy(portId: string, terminalId: string): Observable<Berth[]> {
        return this.http.get<Berth[]>(`${this.url}/${portId}/terminal/${terminalId}/berths`);
    }

    saveTerminal(portId: string, terminalSave: TerminalSave): Observable<Terminal> {
        return this.http.post<Terminal>(`${this.url}/${portId}/terminal`, terminalSave);
    }

    deleteTerminal(portId: string, terminalId: string): Observable<void> {
        return this.http.delete<void>(`${this.url}/${portId}/terminal/${terminalId}`);
    }
}
