import { inject, Injectable } from '@angular/core';
import { initializeAutocompleteFilter } from '@port-line-up/shared/util';
import { ColumnFilterService } from './column-filter.service';

@Injectable()
export class ColumnFiltersAutocompleteDataProviderService {
  private readonly columnFilterService = inject(ColumnFilterService);

    vesselNameFilter = initializeAutocompleteFilter(
    this.columnFilterService.filterableVesselNames$,
    (suggestion, query) => !query || (!!suggestion.value && suggestion.value.displayText.toLowerCase().includes(query.toLowerCase()))
  );

  voyageNumberFilter = initializeAutocompleteFilter(
    this.columnFilterService.filterableVoyageNumbers$,
    (suggestion, query) => !query || (!!suggestion.value && suggestion.value.toLowerCase().includes(query.toLowerCase()))
  );

  nextDestinationsFilter = initializeAutocompleteFilter(
    this.columnFilterService.filterableNextDestinations$,
    (suggestion, query) => !query || (!!suggestion.value && suggestion.value.toLowerCase().includes(query.toLowerCase()))
  );
}
