import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from "@angular/core";
import { APP_ENVIRONMENT } from "@port-line-up/shared/util";

export const OcpApimInterceptor: HttpInterceptorFn = (req, next) => {

    const appEnvironment = inject(APP_ENVIRONMENT);

    req = req.clone({
        setHeaders: {
            'ocp-apim-subscription-key' : appEnvironment.ocpApimSubscriptionKey
        }
    });

    return next(req);
};
