import { AfterViewInit, ContentChildren, Directive, ElementRef, Input, OnDestroy, QueryList, Renderer2 } from '@angular/core';
import { CellChangedDirective } from './cell-changed.directive';
import { Subject, takeUntil, withLatestFrom } from 'rxjs';
import { ApplyChangesStatus, DataUpdateState, DataUpdateStore } from '../../services';

@Directive({ selector: '[pluRowChange]', standalone: true })
export class RowChangedDirective implements AfterViewInit, OnDestroy {
    @Input('pluRowChange') id!: string;
    @ContentChildren(CellChangedDirective, { descendants: true }) cells: QueryList<CellChangedDirective> = new QueryList();

    private destroy$ = new Subject<void>();
    constructor(private dataUpdateStore: DataUpdateStore<DataUpdateState>, private el: ElementRef, private renderer: Renderer2) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewInit(): void {
        this.dataUpdateStore.applyChanges$
            .pipe(withLatestFrom(this.dataUpdateStore.changedPortCalls$, this.dataUpdateStore.addedPortCalls$), takeUntil(this.destroy$))
            .subscribe(([apply, changedPortCalls, addedPortCalls]) => {
                if (apply.status === ApplyChangesStatus.NothingToApply || addedPortCalls.has(this.id)) {
                    return;
                }

                for (const cell of this.cells) {
                    const propertyMap = changedPortCalls.get(this.id);
                    if (cell.propertyName.some((p) => propertyMap?.has(p))) {
                        cell.setClass();
                    } else {
                        cell.removeClass();
                    }
                }

                if (changedPortCalls.has(this.id)) {
                    this.setClass();
                } else {
                    this.removeClass();
                }
            });
    }

    setClass(): void {
        this.renderer.addClass(this.el.nativeElement, 'datatable-presentation-row-changed');
        this.renderer.addClass(this.el.nativeElement, 'ease-in-out');
        this.renderer.addClass(this.el.nativeElement, 'duration-300');
    }

    removeClass(): void {
        this.renderer.removeClass(this.el.nativeElement, 'datatable-presentation-row-changed');
        this.renderer.addClass(this.el.nativeElement, 'inherit');
    }
}
