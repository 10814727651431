<p-columnFilter [field]="field"
                [matchMode]="CustomMatchMode.PartyFilterRule" class="ml-auto" display="menu"
                [showOperator]="false"
                [showAddButton]="false"
                [showMatchModes]="false"
                pluShowCloseButton
                pluPartyColumnFilter>
    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
        <p-multiSelect [options]="(filterableCharterers$ | async) || []"
                       [ngModel]="value.charterer"
                       optionLabel="filterLabel" dataKey="value.code" placeholder="Charterer"
                       (onChange)="filter(updatePartyFilterValue($event.value, PartyType.charterer))">
            <ng-template let-option pTemplate="item">
                <div class="font-medium">{{option.filterLabel}}</div>
            </ng-template>
        </p-multiSelect>
        <p-multiSelect styleClass="mt-2"
                       [options]="(filterableShippingAgents$ | async) || []"
                       [ngModel]="value.shippingAgent"
                       optionLabel="filterLabel" dataKey="value.code" placeholder="Shipping Agent"
                       (onChange)="filter(updatePartyFilterValue($event.value, PartyType.shippingAgent))">
            <ng-template let-option pTemplate="item">
                <div class="font-medium">{{option.filterLabel}}</div>
            </ng-template>
        </p-multiSelect>
        <p-multiSelect  styleClass="mt-2"
                        [options]="(filterableShippers$ | async) || []"
                        [ngModel]="value.shipper"
                        optionLabel="filterLabel" dataKey="value.code" placeholder="Shipper"
                        (onChange)="filter(updatePartyFilterValue($event.value, PartyType.shipper))">
            <ng-template let-option pTemplate="item">
                <div class="font-medium">{{option.filterLabel}}</div>
            </ng-template>
        </p-multiSelect>
    </ng-template>
</p-columnFilter>
