import { inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterMetadata } from 'primeng/api';
import { ColumnFilterItem, CustomMatchMode, GridFilter, GridFilterChip, PartyGridFilterChip } from '../models';
import { BerthDelayMessageFactory, DateChipMessageFactory, DwtMessageFactory } from './grid-filter';

export class FilterChipsServiceBase {
    protected filterChipsSubject: BehaviorSubject<GridFilterChip[]> = new BehaviorSubject<GridFilterChip[]>([]);
    filterChips$: Observable<GridFilterChip[]> = this.filterChipsSubject.asObservable();

    protected partyGridFilterChipsSubject: BehaviorSubject<PartyGridFilterChip | null> = new BehaviorSubject<PartyGridFilterChip | null>(null);
    partyGridFilterChips$: Observable<PartyGridFilterChip | null> = this.partyGridFilterChipsSubject.asObservable();


    dateChipMessageFactory = inject(DateChipMessageFactory);
    dwtMessageFactory = inject(DwtMessageFactory);
    berthDelayMessageFactory = inject(BerthDelayMessageFactory);

    protected createFilterChipItem(
        gridFilters: GridFilter | null,
        filterId: string,
        title: string,
        gridFilterChips: GridFilterChip[],
        isAutocomplete?: boolean
    ): void {
        if (!(gridFilters?.filters?.[filterId] as FilterMetadata[])) {
            return;
        }
        const filterItems = (gridFilters?.filters?.[filterId] as FilterMetadata[])[0]?.value;
        if (Array.isArray(filterItems) && filterItems?.length > 0) {
            const gridFilterChip: GridFilterChip = new GridFilterChip(filterId, title);

            if (isAutocomplete) {
                const mappedFilterItems = filterItems.map((filter) => filter?.value?.filterLabel ?? filter.filterLabel);
                gridFilterChip.values.push(...mappedFilterItems);
            } else {
                filterItems.forEach((lookupItem: ColumnFilterItem<unknown, unknown>): void => {
                    gridFilterChip.values.push(lookupItem.filterLabel);
                });
            }
            gridFilterChips.push(gridFilterChip);
        } else if (filterItems?.length > 0) {
            const gridFilterChip: GridFilterChip = new GridFilterChip(filterId, title);
            gridFilterChip.values.push(filterItems);
            gridFilterChips.push(gridFilterChip);
        }
    }

    protected createDateFilterChipItem(gridFilters: GridFilter | null, filterId: string, title: string, gridFilterChips: GridFilterChip[]): void {
        this.createChipItemWithRules(gridFilters, filterId, title, gridFilterChips, (filters) => this.dateChipMessageFactory.create(filters));
    }

    protected createNumberFilterChipItem(gridFilters: GridFilter | null, filterId: string, title: string, gridFilterChips: GridFilterChip[]): void {
        this.createChipItemWithRules(gridFilters, filterId, title, gridFilterChips, (filters) => this.dwtMessageFactory.create(filters));
    }

    protected createPartyFilterChipItem(gridFilters: GridFilter | null, filterId: string): void {
        const filterItemsIds = (gridFilters?.filters?.[filterId] as FilterMetadata[])?.[0].value;

        const charterers = filterItemsIds?.charterer;
        const shippers = filterItemsIds?.shipper;
        const shippingAgents = filterItemsIds?.shippingAgent;

        if (charterers?.length > 0 || shippers?.length > 0 || shippingAgents?.length > 0) {
            const gridFilterChip: PartyGridFilterChip = new PartyGridFilterChip(filterId);
            charterers.forEach((charter: ColumnFilterItem<unknown, unknown>) => {
                gridFilterChip.addPartyChipItem('Charterer', charter.filterLabel);
            });

            shippers.forEach((shipper: ColumnFilterItem<unknown, unknown>) => {
                gridFilterChip.addPartyChipItem('Shipper', shipper.filterLabel);
            });

            shippingAgents.forEach((shippingAgent: ColumnFilterItem<unknown, unknown>) => {
                gridFilterChip.addPartyChipItem('Shipping Agent', shippingAgent.filterLabel);
            });

            this.partyGridFilterChipsSubject.next(gridFilterChip);
        } else {
            this.partyGridFilterChipsSubject.next(null);
        }
    }

    protected createBerthDelayFilterChipItem(gridFilters: GridFilter | null, filterId: string, title: string, gridFilterChips: GridFilterChip[]): void {
        const berthDelayFilterValue = (gridFilters?.filters?.[filterId] as FilterMetadata[])?.[0];
        if (!berthDelayFilterValue) return;

        const message = this.berthDelayMessageFactory.create(berthDelayFilterValue);
        if (!message) return;

        const gridFilterChip = new GridFilterChip(filterId, title);
        gridFilterChip.values.push(message);
        gridFilterChips.push(gridFilterChip);
    }

    protected createChipItemWithRules(
        gridFilters: GridFilter | null,
        filterId: string,
        title: string,
        gridFilterChips: GridFilterChip[],
        messageFactory: (filters: FilterMetadata[]) => string
    ): void {
        const filters = gridFilters?.filters?.[filterId] as FilterMetadata[];
        if (!filters) {
            return;
        }

        const gridFiltersWithValue = filters.filter((x) => x.matchMode === CustomMatchMode.EmptyDate ? x : x.value);
        if (gridFiltersWithValue.length > 0) {
            const message = messageFactory(gridFiltersWithValue);
            const gridFilterChip = new GridFilterChip(filterId, title);
            gridFilterChip.values.push(message);
            gridFilterChips.push(gridFilterChip);
        }
    }
}
