import { Injectable } from '@angular/core';
import { PortCallPresentation } from '../models';
import { ScrollingToRowData } from '@port-line-up/shared/util';

@Injectable()
export class ScrollingToRowDataFactory {

    private heightOffset = 0;

    create(imoNumber: string | null | undefined, notFoundCallback?: any): ScrollingToRowData<PortCallPresentation> {
        return {
            rowIdentifier: {
                key: 'imoNumber',
                value: imoNumber?.toString(),
            },
            heightOffset: this.heightOffset,
            styleClass: 'datatable-cell-pending datatable-cell-pending-text ease-in-out duration-300',
            notFoundCallback: notFoundCallback
        } as ScrollingToRowData<PortCallPresentation>
    }

    setHeightOffset(heightOffset: number): void {
        this.heightOffset = heightOffset;
    }
}
