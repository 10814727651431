import { Injectable } from "@angular/core";
import { Table } from "primeng/table";
import { TreeTable } from 'primeng/treetable';
import { CargoMatchMode, ColumnFilterItem, LookupItem } from "../models";

@Injectable({ providedIn: 'root' })
export class CargoColumnFilterService {
  CargoMatchMode = CargoMatchMode;

  registerCargoTypeNameFilterRule(table: Table | TreeTable): void {
    table.filterService.register(CargoMatchMode.CargoType, this.multiValueFilter);
  }

  registerCargoRateUOMFilterRule(table: Table): void {
    table.filterService.register(CargoMatchMode.CargoRateUOM, this.multiValueFilter);
  }

  registerCargoQuantityUOMFilterRule(table: Table): void {
    table.filterService.register(CargoMatchMode.QuantityUOM, this.multiValueFilter);
  }

  registerCargoRateFilterRule(table: Table): void {
    table.filterService.register(CargoMatchMode.CargoRate, this.singleValueFilter);
  }

  registerCargoQuantityFilterRule(table: Table): void {
    table.filterService.register(CargoMatchMode.Quantity, this.singleValueFilter);
  }

  private multiValueFilter = (tableCellValue: LookupItem[], filters: ColumnFilterItem<LookupItem, LookupItem>[]): boolean => {
    if (!filters || !filters[0]) return true

    const tableCellValueIds = tableCellValue.map((value) => value?.id);

    return filters.every((filter) => tableCellValueIds.some(x => {      
      const propertyForCompare = filter.filterField ? filter.value?.[filter.filterField] : filter.value;
      return (x ?? null) === (propertyForCompare ?? null);
    }));
  }

  private singleValueFilter = (tableCellValue: number[], filter: string): boolean => {
    if (!filter) return true;
    else if (!tableCellValue) return false;

    const tableCellWithValues = tableCellValue.filter(Boolean);
    if (!tableCellWithValues.length) return false;

    return tableCellWithValues.some((value) => value.toString().includes(filter));
  }
}
