import { Injectable } from '@angular/core';
import {
    CargoMatchMode,
    CustomMatchMode,
    GridFilterFactory,
    GridFilterItem,
    PartyFilter,
    PresentationTableFilterIds
} from '@port-line-up/shared/util';
import { FilterMatchMode, FilterOperator } from 'primeng/api';

@Injectable()
export class GridFilterConfigurationFactory extends GridFilterFactory {

    create(): GridFilterItem {
        return {
            [PresentationTableFilterIds.vesselName]: [
                {
                    value: null,
                    matchMode: CustomMatchMode.ColumnFilterItemIn,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.vesselType]: [
                {
                    value: null,
                    matchMode: CustomMatchMode.ColumnFilterItemIn,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.voyageNumber]: [
                {
                    value: null,
                    matchMode: CustomMatchMode.ColumnFilterItemIn,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.vesselStatusId]: [
                {
                    value: null,
                    matchMode: CustomMatchMode.ColumnFilterItemIn,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.terminalId]: [
                {
                    value: null,
                    matchMode: CustomMatchMode.ColumnFilterItemIn,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.berthId]: [
                {
                    value: null,
                    matchMode: CustomMatchMode.ColumnFilterItemIn,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.cargoType]: [
                {
                    value: null,
                    matchMode: CargoMatchMode.CargoType,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.cargoDescription]: [
                {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.operationId]: [
                {
                    value: null,
                    matchMode: CustomMatchMode.ColumnFilterItemIn,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.notes]: [
                {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.portName]: [
                {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.ata]: [
                {
                    value: null,
                    matchMode: FilterMatchMode.DATE_IS,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.atb]: [
                {
                    value: null,
                    matchMode: FilterMatchMode.DATE_IS,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.atc]: [
                {
                    value: null,
                    matchMode: FilterMatchMode.DATE_IS,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.atd]: [
                {
                    value: null,
                    matchMode: FilterMatchMode.DATE_IS,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.acc]: [
                {
                    value: null,
                    matchMode: FilterMatchMode.DATE_IS,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.dwt]: [
                {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.berthDelay]: [
                {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.nextDestinationNote]: [
                {
                    value: null,
                    matchMode: CustomMatchMode.ColumnFilterItemIn,
                    operator: FilterOperator.AND,
                },
            ],
            [PresentationTableFilterIds.party]: [
                {
                    value: new PartyFilter(),
                    matchMode: CustomMatchMode.PartyFilterRule,
                    operator: FilterOperator.AND,
                },
            ],
        };
    }


}
