/**
 * filterField tells which property to use for filtering in filter data, if not provided the value itself will be used
 * dataField tells which property to use for filtering in table data, if not provided the value itself will be used
 */

export class ColumnFilterItem<TFilter, TData> {
    constructor(
        public readonly filterLabel: string,
        public readonly value: TFilter | null,
        public readonly filterField?: keyof TFilter,
        public readonly dataField?: keyof TData
    ) {}

    static create<TFilter, TData>(filterLabel: string, value: TFilter | null, filterField?: keyof TFilter, dataField?: keyof TData): ColumnFilterItem<TFilter, TData> {
        return new ColumnFilterItem(filterLabel, value, filterField, dataField);
    }

    static createEmpty<TFilter, TData>(filterField?: keyof TFilter, dataField?: keyof TData): ColumnFilterItem<TFilter, TData> {
        return new ColumnFilterItem('Empty', null, filterField, dataField);
    }
}
