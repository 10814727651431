import { Injectable } from '@angular/core';
import { VesselStatus } from '../enums';
import { VesselStatusFacadeService } from './vessel-status.facade';

@Injectable({ providedIn: 'root' })
export class VesselStatusIconResolver {
    constructor(private vesselStatusService: VesselStatusFacadeService) {}

    private portCallStatusIcon = new Map<string, string>()
        .set(this.vesselStatusService.getDisplayTextById(VesselStatus.Arrived), `arrived.svg`)
        .set(this.vesselStatusService.getDisplayTextById(VesselStatus.Berthed), `berthed.svg`)
        .set(this.vesselStatusService.getDisplayTextById(VesselStatus.OperationsInProgress), `in-progress.svg`)
        .set(this.vesselStatusService.getDisplayTextById(VesselStatus.Expected), `expected.svg`)
        .set(this.vesselStatusService.getDisplayTextById(VesselStatus.Sailed), `sailed.svg`)
        .set(this.vesselStatusService.getDisplayTextById(VesselStatus.OperationCompleted), `completed.svg`);

    resolve(key: string): string {
        if (key === null || key === undefined) {
            return '';
        }

        return this.portCallStatusIcon.get(key) ?? '';
    }
}
