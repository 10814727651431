import { PortCall, PortCallCargo } from '@port-line-up/shared/data-access';
import { PortCallExcelExport, PortCallCargoExcelExport } from '@port-line-up/shared/util';

export abstract class ExcelColumnsResolver {

    abstract canResolve(): boolean;
    abstract resolve(portCalls: PortCall[]): PortCallExcelExport[];

    protected resolveCargos(portCallCargos: PortCallCargo[]): PortCallCargoExcelExport[] {
        return portCallCargos.map(cargo => ({
            cargoDescription: cargo.cargoDescription,
            cargoQuantity: cargo.cargoQuantity,
            cargoQuantityUomName: cargo.cargoQuantityUom,
            cargoRate: cargo.cargoRate,
            cargoRateUomName: cargo.cargoRateUom,
            cargoTypeName: cargo.cargoType
        } as PortCallCargoExcelExport))
    }
}
