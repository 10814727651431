import { AfterViewChecked, DestroyRef, Directive, OnInit, inject, input } from '@angular/core';
import { RowScrollService, ScrollingToRowData } from '@port-line-up/shared/util';
import { PortCallPresentation } from '../models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Table } from 'primeng/table';

@Directive({ selector: '[pluScrollingToSpecificRowByFragment]', standalone: true })
export class ScrollingToSpecificRowByFragmentDirective implements OnInit, AfterViewChecked {
    scrollingToRowData = input.required<ScrollingToRowData<PortCallPresentation> | null>({ alias: 'pluScrollingToSpecificRowByFragment' });

    private scrollingCompleted = false;
    private haveChangesBeenDetected = false;

    private destroyRef = inject(DestroyRef);
    constructor(private rowScrollService: RowScrollService, private tableRef: Table) {}

    ngOnInit(): void {
        this.subscribeToPageChanged();
    }

    ngAfterViewChecked(): void {
        this.handleScrolling();
    }

    private handleScrolling(): void {
        const scrollingToRowData = this.scrollingToRowData();
        if(!scrollingToRowData){
            return;
        }

        if (!this.scrollingCompleted && !this.haveChangesBeenDetected && this.tableRef.value.length > 0) {
            this.rowScrollService.handleScrollingToSpecificRow<PortCallPresentation>(this.tableRef, scrollingToRowData);
            this.scrollingCompleted = true;
        }
    }

    private subscribeToPageChanged() {
        this.rowScrollService.pageChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => (this.haveChangesBeenDetected = true));
    }
}
