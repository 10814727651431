import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { EMPTY, catchError, switchMap, throwError } from 'rxjs';
import { LogService } from '../http-services';
import { ServerError } from '../models';

export const ServerErrorInterceptor: HttpInterceptorFn = (req, next) => {
    const errorLogService = inject(LogService);

    return next(req).pipe(
        catchError((err) => {
            if (!err.url.endsWith(errorLogService.logServerErrorEndpoint) && (err.status === 0 || err.status === 504 || err.status === 502)) {
                return errorLogService.logServerError(new ServerError(err.url, `${err.status} ${err.statusText} ${err.error} ${err.message}`))
                .pipe(switchMap(() => EMPTY));
            }
            return throwError(() => err);
        })
    );
};
