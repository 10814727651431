import {
    ChangeDetectionStrategy,
    Component,
    computed
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Column, GridColumnFactory, GridColumnService } from '@port-line-up/shared/util';
import { GridColumnSelectorComponent } from '@port-line-up/shared/ui/grid-column-selector';
import { TableSettingsMenuBuilder, TableSettingsMenuComponent } from '@port-line-up/shared/ui/table-settings-menu';

@Component({
    selector: 'plu-presentation-table-settings-menu',
    templateUrl: 'presentation-table-settings-menu.component.html',
    imports: [CommonModule, TableSettingsMenuComponent],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresentationTableSettingsMenuComponent {

    tableSettingsMenuItems = computed(() => {
        return new TableSettingsMenuBuilder()
            .addGridSettingsMenuItem(() => { this.openGridSettingsModal() })
            .addResetSettingsMenuItem(() => { this.gridColumnService.resetColumns() })
            .getMenuItems();
    });

    private ref: DynamicDialogRef | undefined;

    constructor(private dialogService: DialogService,
                private gridColumnService: GridColumnService,
                private gridColumnFactory: GridColumnFactory) {}

    private  openGridSettingsModal(): void {
        this.ref = this.dialogService.open(GridColumnSelectorComponent, {
            header: 'Column Chooser',
            width: '700px',
            height: 'auto',
            data: {
                visibleColumns: this.gridColumnService.getColumns(),
                availableColumns: this.gridColumnFactory.create()
            },
        });

        this.ref.onClose.subscribe((result: Column[] | null) => {
            if (result) {
                this.gridColumnService.saveColumns(result);
            }
        });
    }
}
