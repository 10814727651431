import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';

@Pipe({
  name: 'pluDecimalPipe',
  standalone: true,
})

export class PluDecimalPipe implements PipeTransform {
  private readonly decimalPipe = inject(DecimalPipe);

  transform(
    value: string | number,
    digitsInfo = '1.2-2',
    locale?: string
  ): string | null {
    return this.decimalPipe.transform(value, digitsInfo, locale);
  }
} 