import { Injectable, inject } from '@angular/core';
import { FilterMatchMode, FilterMetadata } from 'primeng/api';
import { HoursService } from '../hours.service';

@Injectable({ providedIn: 'root' })
export class BerthDelayMessageFactory {
  private hoursService = inject(HoursService);


  create(filterMetadata: FilterMetadata): string | null {
    if (filterMetadata.value && filterMetadata.matchMode) {
      const matchModeMessage = this.handleMatchMode(filterMetadata.matchMode);
      const days = this.hoursService.getDays(filterMetadata.value);
      const hours = this.hoursService.getHours(filterMetadata.value);

      if (!days) {
        return `${matchModeMessage}: ${hours} h`;
      }

      if (!hours) {
        return `${matchModeMessage}: ${days} d`;
      }

      return `${matchModeMessage}: ${days} d ${hours} h`;
    }

    return null;
  }

  private handleMatchMode(matchMode: string): string {
    switch (matchMode) {
      case FilterMatchMode.EQUALS:
        return 'Equals to';
      case FilterMatchMode.GREATER_THAN:
        return 'Greater then';
      case FilterMatchMode.GREATER_THAN_OR_EQUAL_TO:
        return 'Greater then or equal to';
      case FilterMatchMode.LESS_THAN:
        return 'Less than';
      case FilterMatchMode.LESS_THAN_OR_EQUAL_TO:
        return 'Less than or equal to';
      default:
        return '';
    }
  }
}
