import { Injectable } from '@angular/core';
import { FilterMetadata } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';

@Injectable({ providedIn: 'root' })
export class TableStateService {
  setTableState(table: Table, event: TableLazyLoadEvent | null) {
    if (!event) return;

    table.filters = event.filters as { [s: string]: FilterMetadata | FilterMetadata[] };

    table.first = event.first;
    table.restoringFilter = true;

    table.sortField = event.sortField as (string | null | undefined);
    table.sortOrder = event.sortOrder as number;
    table._filter();
  }
}
