import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ColumnFilterItem, CustomMatchMode, ShowCloseButtonDirective } from '@port-line-up/shared/util';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';

@Component({
    selector: 'plu-terminal-filter',
    standalone: true,
    imports: [TableModule, MultiSelectModule, FormsModule, ShowCloseButtonDirective],
    templateUrl: './terminal-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerminalFilterComponent {
    @Input({required: true}) field!: string;
    @Input() options: ColumnFilterItem<any, any>[] = [];
    CustomMatchMode = CustomMatchMode;
}
