import { Lookup } from '@port-line-up/shared/util';
import { CompanyAccessRights } from '../enums';
import { CompanyPort } from './company-port';
import { User } from './user';

export class Company {
    id: string | null = null;
    name: string | null = '';
    countryId = '';
    countryName = '';
    city: string | null = '';
    email: string | null = '';
    accessRights: CompanyAccessRights = CompanyAccessRights.Editor;
    isGacCompany = false;
    isActive = false;
    users: User[] = [];
    ports: CompanyPort[] = [];
}

export class CompanyLookup implements Lookup {
    constructor(public readonly id: string, public readonly displayText: string) {}

    static create(id: string, displayText: string): CompanyLookup {
        return new CompanyLookup(id, displayText);
    }
}
