import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { SliderService } from '@port-line-up/shared/util';
import { HorizontalScrollDirective } from './horizontal-scroll.directive';

@Component({
    selector: 'plu-table-horizontal-scroll-bar',
    templateUrl: 'table-horizontal-scroll-bar.component.html',
    styleUrls: ['table-horizontal-scroll-bar.component.scss'],
    standalone: true,
    imports: [HorizontalScrollDirective],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHorizontalScrollBarComponent implements OnInit, OnDestroy {

    tableWidth = 0;
    private destroy$ = new Subject<void>();

    constructor(private sliderService: SliderService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.subscribeToWidthChange();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private subscribeToWidthChange(): void {
        this.sliderService.tableWidth$.pipe(takeUntil(this.destroy$),distinctUntilChanged()).subscribe((tableWidth) => {
            if (tableWidth && tableWidth > 0) {
                this.tableWidth = tableWidth;
                this.cdr.detectChanges();
            }
        })
    }
}
