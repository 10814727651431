import { Component, Input } from '@angular/core';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ColumnFilterItem, CustomMatchMode, PartyFilter, PartyLookup, ShowCloseButtonDirective } from '@port-line-up/shared/util';
import { PartyType } from '@port-line-up/shared/data-access';
import { PartyColumnFilterDirective } from '@port-line-up/shared/util';

@Component({
    selector: 'plu-party-filter',
    templateUrl: 'party-filter.component.html',
    imports: [
        AsyncPipe,
        TableModule,
        FormsModule,
        MultiSelectModule,
        ShowCloseButtonDirective,
        PartyColumnFilterDirective,
        JsonPipe
    ],
    standalone: true
})
export class PartyFilterComponent {
    @Input({required: true}) field!: string;
    @Input({required: true}) filterableCharterers$!: Observable<ColumnFilterItem<PartyLookup, any>[]>;
    @Input({required: true}) filterableShippers$!: Observable<ColumnFilterItem<PartyLookup, any>[]>;
    @Input({required: true}) filterableShippingAgents$!: Observable<ColumnFilterItem<PartyLookup, any>[]>;

    protected readonly PartyType = PartyType;
    protected readonly CustomMatchMode = CustomMatchMode;

    private party: PartyFilter = new PartyFilter();

    updatePartyFilterValue(value: string[], partyType: PartyType): PartyFilter {
        switch (partyType) {
            case PartyType.charterer:
                this.party.charterer = value;
                break;
            case PartyType.shippingAgent:
                this.party.shippingAgent = value;
                break;
            case PartyType.shipper:
                this.party.shipper = value;
                break;
        }
        return this.party;
    }
}
