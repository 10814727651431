import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Port, PortCall, UIStore, VesselStatusIconResolver } from '@port-line-up/shared/data-access';
import {
    BerthPriorityService,
    ColumnFiltersAutocompleteDataProviderService,
    ColumnFilterService,
    DATA_CHANGE_IMPORTS,
    ExecuteLocalFilterOverrideDirective,
    JobClickedEvent,
    PortCallDelayedDirective,
    PortCallPresentation,
    PortCallPresentationFactoryService,
    PresentationTableRegisterFilterRuleDirective,
    ScrollingToRowDataFactory,
    ScrollingToSpecificRowByVesselMapClickDirective,
    SignalRChangeHandlerService,
    TableFilteredDirective,
    TableFilteredService,
    TablePresentationSortDirective,
} from '@port-line-up/shared/feature-presentation-table';
import { BerthDelayFilterComponent } from '@port-line-up/shared/ui/berth-delay-filter';
import { CargoColumnFilterComponent } from '@port-line-up/shared/ui/cargo-column-filter';
import { DateColumnFilterComponent } from '@port-line-up/shared/ui/date-column-filter';
import { DateColumnViewComponent } from '@port-line-up/shared/ui/date-column-view';
import { IhsDateColumnViewComponent } from '@port-line-up/shared/ui/ihs-date-column-view';
import { ImageComponent } from '@port-line-up/shared/ui/image';
import { NoteDetailComponent } from '@port-line-up/shared/ui/note-detail';
import { OrderComponent } from '@port-line-up/shared/ui/order';
import { PartyColumnViewComponent } from '@port-line-up/shared/ui/party-column-view';
import { PluDatePipe, PluDecimalPipe, PluHoursToDaysAndHoursPipe } from '@port-line-up/shared/ui/pipes';
import {
    CargoMatchMode,
    Column,
    ColumnFilterClearNotificationDirective,
    DatesBackgroundColor,
    GridColumnDirective,
    GridFilterDirective,
    GridFilterItem,
    NotificationService,
    PresentationTableFilterIds, RowScrollService,
    ScrollableContainerService,
    ShowCloseButtonDirective,
    TableGlobalHorizontalScrollDirective,
    TableHeaderHorizontalScrollDirective
} from '@port-line-up/shared/util';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { VesselsLocationUpdateDirective } from '../../directives/vessels-location-update.directive';
import { BerthFilterComponent, NumberFilterComponent, NextDestinationNoteFilterComponent, OperationFilterComponent, TerminalFilterComponent, VesselFilterComponent, VesselStatusFilterComponent, VesselTypeFilterComponent, VoyageNumberFilterComponent } from '@port-line-up/shared/ui/table-filters';

@Component({
    selector: 'plu-shared-view-table',
    standalone: true,
    imports: [
        CommonModule,
        TableModule,
        MultiSelectModule,
        AutoCompleteModule,
        FormsModule,
        DateColumnViewComponent,
        PluDatePipe,
        PluDecimalPipe,
        OrderComponent,
        TooltipModule,
        ImageComponent,
        DATA_CHANGE_IMPORTS,
        PortCallDelayedDirective,
        GridFilterDirective,
        DateColumnFilterComponent,
        GridColumnDirective,
        TablePresentationSortDirective,
        ScrollingToSpecificRowByVesselMapClickDirective,
        ExecuteLocalFilterOverrideDirective,
        IhsDateColumnViewComponent,
        PartyColumnViewComponent,
        ButtonModule,
        ShowCloseButtonDirective,
        TableGlobalHorizontalScrollDirective,
        TableFilteredDirective,
        CargoColumnFilterComponent,
        BerthDelayFilterComponent,
        PluHoursToDaysAndHoursPipe,
        VesselsLocationUpdateDirective,
        TableHeaderHorizontalScrollDirective,
        ColumnFilterClearNotificationDirective,
        PresentationTableRegisterFilterRuleDirective,
        VesselFilterComponent,
        VesselTypeFilterComponent,
        VoyageNumberFilterComponent,
        VesselStatusFilterComponent,
        BerthFilterComponent,
        TerminalFilterComponent,
        OperationFilterComponent,
        NumberFilterComponent,
        NextDestinationNoteFilterComponent
    ],
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ColumnFilterService,
        PluDatePipe,
        DialogService,
        BerthPriorityService,
        ScrollingToRowDataFactory,
        RowScrollService,
        ColumnFiltersAutocompleteDataProviderService
    ],
})
export class SharedViewTableComponent {
    @Input() set portCalls(portCalls: PortCall[] | null) {
        if (portCalls === null) {
            return;
        }
        this._portCallsPresentation = this.portCallPresentationFactory
            .create(portCalls)
            .sort((a: PortCallPresentation, b: PortCallPresentation) => a.priority - b.priority);
        this.columnFilterService.init(this._portCallsPresentation);

        this.signalRChangeHandler.applyChanges();
    }
    get portCallsPresentation() {
        return this._portCallsPresentation;
    }

    @Input() port!: Port;
    @Input() initialFilters!: GridFilterItem;
    @Input() showPortCallOnMapButton = true;
    @Input() showPriority = true;
    @Input() showLastColumn = true;

    @Input() set tableHeightOffset(tableHeightOffset: number | null) {
        if (!tableHeightOffset) {
            return
        }

        this.scrollingToRowDataFactory.setHeightOffset(tableHeightOffset);
    }

    columns: Column[] = [];
    CargoMatchMode = CargoMatchMode;
    columnsToExclude = ['party'];
    readonly uiStore = inject(UIStore);

    DatesBackgroundColor = DatesBackgroundColor;
    PresentationTableFilterIds = PresentationTableFilterIds;

    private _portCallsPresentation: PortCallPresentation[] = [];

    constructor(
        public vesselStatusIconResolver: VesselStatusIconResolver,
        public tableFilteredService: TableFilteredService,
        public dialogService: DialogService,
        public columnFiltersAutocompleteDataProviderService: ColumnFiltersAutocompleteDataProviderService,
        public columnFilterService: ColumnFilterService,
        private berthPriorityService: BerthPriorityService,
        private signalRChangeHandler: SignalRChangeHandlerService,
        private portCallPresentationFactory: PortCallPresentationFactoryService,
        private notificationService: NotificationService,
        private scrollingToRowDataFactory: ScrollingToRowDataFactory,
        private scrollableContainerService: ScrollableContainerService
    ) {}

    getBerthPriorityTooltip(item: PortCallPresentation): string {
        return this.berthPriorityService.getBerthPriorityTooltip(item, this.portCallsPresentation);
    }

    openNoteDetailDialog($event: Event, notes: string): void {
        $event.stopPropagation();
        this.dialogService.open(NoteDetailComponent, {
            header: 'Note',
            width: '600px',
            height: 'auto',
            modal: true,
            data: { notes: notes, isEditable: false },
        });
    }

    onGacJobNumberClicked(event: Event, item: PortCallPresentation): void {
        event.preventDefault();
        this.notificationService.notify(new JobClickedEvent(item.gacJobNumber));
    }

    showVesselOnMap(portCallId: string): void {
        this.scrollableContainerService.scrollTo({top: 0, left: 0, behavior: "smooth"});
        this.tableFilteredService.selectVessel({ portCallId: portCallId, imoNumber: null });
    }

    setColumns(columns: Column[]): void {
        this.columns = columns;
    }
}
