import { ChangeDetectorRef, Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { GridColumnService } from './grid-column.service';
import { Column } from '../../models';
import { Table } from 'primeng/table';
import { Subject, takeUntil } from 'rxjs';
import { HandleColumnsWidthsService } from './handle-columns-widths.service';

@Directive({ selector: '[pluGridColumn]', standalone: true })
export class GridColumnDirective implements OnInit, OnDestroy {

    /*
    * setResizeTableWidth function is overrided because if we remove columns the initial width stays
    * and we will have blank space.
    * */

    /**
     * Define static column widths (columns that cannot be changed in any way)
     */
    @Input() staticColumnWidths?: StaticColumnWidths;
    @Input() columnsToExclude!: string[];
    @Output() columnsSet = new EventEmitter<Column[]>();

    private destroy$: Subject<void> = new Subject<void>();
    private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

    constructor(private gridColumnService: GridColumnService, private table: Table, private expandColumnsWidthsService: HandleColumnsWidthsService) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.table.setResizeTableWidth = () => {}
    }

    ngOnInit(): void {
        this.gridColumnService.readTableWidth$.subscribe((): void => {
            const tableWidth: number = this.table.el.nativeElement.offsetWidth;
            if(tableWidth) {
                this.gridColumnService.setTableWidth(tableWidth);
            }
        });

        this.gridColumnService.gridColumns$.pipe(takeUntil(this.destroy$)).subscribe((columns: Column[]): void => {

            const filteredColumns: Column[] = this.getFilteredColumns(columns);

            const tableWidth: number = this.table.el.nativeElement.offsetWidth

            if (tableWidth) {
                this.gridColumnService.setTableWidth(tableWidth);
                this.expandColumnsWidthsService.handleColumnsWidthsService(filteredColumns, tableWidth)
            }

            this.table.columns = filteredColumns;
            this.columnsSet.emit([...filteredColumns]);
            this.table.columnWidthsState = [
                ...(this.staticColumnWidths?.begining ?? []),
                ...filteredColumns.map((column: Column) => column.width),
                ...(this.staticColumnWidths?.end ?? []),
            ]
                .filter((s: string) => s)
                .join(',');
            this.table.restoreColumnWidths();
            this.cdr.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    @HostListener('onColResize', ['$event'])
    colResize(event: { element: HTMLTableCellElement; delta: number }): void {
        const index = event.element.cellIndex - (this.staticColumnWidths?.begining?.length ?? 0);
        const delta = event.delta;

        const tableWidth: number = this.table.el.nativeElement.offsetWidth;
        if (tableWidth) {
            this.gridColumnService.setTableWidth(tableWidth);
        }

        this.gridColumnService.resize((this.table.columns as Column[]), index, delta);
    }

    @HostListener('onColReorder', ['$event'])
    colReorder(event: { dragIndex: number; dropIndex: number; columns: Column[] }): void {
        this.gridColumnService.reorder(event.columns);
    }

    private getFilteredColumns(columns: Column[]): Column[] {
        if (!this.columnsToExclude?.length) return columns;

        return columns.filter(column => !this.columnsToExclude.includes(column.name));
    }
}

export interface StaticColumnWidths {
    begining?: string[];
    end: string[];
}
