import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WINDOW } from '../tokens';
import { IFrameMessage } from '../models';

@Injectable({ providedIn: 'root' })
export class IFrameService implements OnDestroy {
    private portalMessageSubject = new BehaviorSubject<IFrameMessage | null>(null);
    public portalMessage$ = this.portalMessageSubject.asObservable();

    private loadFinishedMessage = 'pluLoaded';

    constructor(@Inject(WINDOW) private window: Window) {}

    ngOnDestroy(): void {
        window.removeEventListener('message', this.handleParentMessage);
    }

    init(): void {
        this.notifyParentSiteThatLoadFinished();
        this.listenToParentMessages();
    }

    private notifyParentSiteThatLoadFinished(): void {
        // TODO If we want to avoid using '*' we need to have same number of environments as Portal
        this.window.parent.postMessage(this.loadFinishedMessage, '*');
    }

    private listenToParentMessages(): void {
        window.addEventListener('message', this.handleParentMessage);
    }

    private handleParentMessage = (event: MessageEvent) => {
        // TODO If we want to avoid using || we need to have same number of environments as Portal
        if (event.origin === 'http://localhost:4200' || event.origin.includes('portal.gac.com')) {
            this.portalMessageSubject.next(event.data);
        }
    };
}
