export class ScheduledMaintenanceSave {
    constructor(
        public readonly id: string | undefined,
        public readonly portId: string,
        public readonly terminalId: string,
        public readonly from: string,
        public readonly to: string,
        public readonly note: string,
    ) {}
}
