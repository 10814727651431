import { HttpParams } from '@angular/common/http';
import { SortDirection } from '../enums';

export class SortItem {
    constructor(public readonly propertyName: string, public readonly direction: string) {}

    public static create(propertyName: string, direction: SortDirection): SortItem {
        return new SortItem(propertyName, direction === SortDirection.Ascending ? "ascending": "descending");
    }

    public getHttpParams(index: number): HttpParams {
        return new HttpParams()
            .set(`sortItems[${index}].propertyName`, this.propertyName)
            .set(`sortItems[${index}].direction`, this.direction);
    }

    
    public updateHttpParams(httpParams: HttpParams, index: number): HttpParams {
        return httpParams
            .set(`sortItems[${index}].propertyName`, this.propertyName)
            .set(`sortItems[${index}].direction`, this.direction);
    }
}
