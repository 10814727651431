import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PortCallDelayedService {

    get areDelayedVesselsMarked$(): Observable<boolean> {
        return this.markDelayedVesselsSubject.asObservable();
    }

    private markDelayedVessels = false;
    private markDelayedVesselsSubject = new BehaviorSubject(this.markDelayedVessels);

    toggleMarkDelayedVessels(): void {
        this.markDelayedVessels = !this.markDelayedVessels;
        this.markDelayedVesselsSubject.next(this.markDelayedVessels);
    }
}
