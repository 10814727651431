import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { PortCall, EntityListResult, Fleet } from '../models';

@Injectable({ providedIn: 'root' })
export class FleetService {
    private readonly url = `${this.appEnvironment.baseApiUrl}/fleet`;

    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private appEnvironment: any) {}

    getAll(): Observable<EntityListResult<Fleet>> {
        return this.http.get<EntityListResult<Fleet>>(`${this.url}`);
    }

    getPortCallsByFleetId(fleetId: string): Observable<EntityListResult<PortCall>> { 
        return this.http.get<EntityListResult<PortCall>>(`${this.url}/${fleetId}/activePortcalls`);
    }

    getAllActivePortCalls(): Observable<EntityListResult<PortCall>> { 
        return this.http.get<EntityListResult<PortCall>>(`${this.url}/activePortcalls`);
    }

    getSailedPortCallsByFleetId(fleetId: string): Observable<EntityListResult<PortCall>> { 
        return this.http.get<EntityListResult<PortCall>>(`${this.url}/${fleetId}/sailedPortcalls`);
    }

    getAllSailedPortCalls(): Observable<EntityListResult<PortCall>> { 
        return this.http.get<EntityListResult<PortCall>>(`${this.url}/sailedPortcalls`);
    }

    save(fleet: Fleet): Observable<Fleet> {
        return this.http.post<Fleet>(`${this.url}`, fleet);
    }

    delete(fleetId: string): Observable<void> {
        return this.http.delete<void>(`${this.url}/${fleetId}`);
    }

    addVesselToFleet(fleetId: string, imoNumber: string): Observable<Fleet> {
        return this.http.post<Fleet>(`${this.url}/vessel`, {fleetId, imoNumber});
    }

    removeVesselFromFleet(fleetId: string, imoNumber: string): Observable<void> {
        return this.http.delete<void>(`${this.url}/${fleetId}/${imoNumber}`);
    }
}
