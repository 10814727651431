import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, catchError, throwError } from 'rxjs';
import { AuthService } from '../services';
import { MessageService } from 'primeng/api';

export const AuthenticationInterceptor: HttpInterceptorFn = (req, next) => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const messageService = inject(MessageService);

    return next(req).pipe(
        catchError((err) => {
            if (err.status === 401) {
                authService.logoutPopup();
                messageService.clear();
                messageService.add({ severity: 'error', summary: 'Error', detail: 'User is not authorized!' });
                router.navigate(['/', 'authentication', 'login']);
                return EMPTY;
            }
            return throwError(() => err);
        })
    );
};
