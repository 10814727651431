import { Injectable } from '@angular/core';
import { ExcelService } from '../excel.service';
import { PortCallExcelExport, PortCallExcelHeaders } from '../../models';

@Injectable({ providedIn: 'root' })
export class PortCallExportService {
    constructor(private excelService: ExcelService) {}

    exportExcel(portCalls: PortCallExcelExport[], fileName: string, headers: PortCallExcelHeaders[]): void {
        const maxNumberOfCargos = this.findMaxNumberOfCargos(portCalls);
        this.excelService.exportExcel(this.preparePortCallsForExport(portCalls, maxNumberOfCargos), fileName, this.prepareHeaders(headers, maxNumberOfCargos));
    }

    private findMaxNumberOfCargos(portCalls: PortCallExcelExport[]): number {
        if (portCalls.length > 0) {
            return portCalls.reduce((maxCargos, current) => {
                if (current.cargos.length > maxCargos.cargos.length) {
                    return current;
                }
                return maxCargos;
            }, portCalls[0]).cargos.length;
        }

        return 1;
    }

    private preparePortCallsForExport(portCalls: PortCallExcelExport[], maxNumberOfCargos: number): unknown[] {
        const items: unknown[] = [];

        portCalls.forEach((portCall) => {
            const item = this.createObject(portCall);
            for (let i = 0; i < maxNumberOfCargos; i++) {
                const index = i === 0 ? '' : i + 1;
                item[`cargo${index}`] = portCall.cargos[i]?.cargoTypeName;
                item[`cargoDescription${index}`] = portCall.cargos[i]?.cargoDescription;
                item[`cargoRate${index}`] = portCall.cargos[i]?.cargoRate;
                item[`cargoRateUomName${index}`] = portCall.cargos[i]?.cargoRateUomName;
                item[`cargoQuantity${index}`] = portCall.cargos[i]?.cargoQuantity;
                item[`cargoQuantityUomName${index}`] = portCall.cargos[i]?.cargoQuantityUomName;
            }
            items.push(item);
        });

        return items;
    }

    private createObject(portCall: PortCallExcelExport): any {
        const excludedProperties: (keyof PortCallExcelExport)[] = ['cargos'];
        return Object.assign(
            {},
            ...Object.entries(portCall)
                .filter(([key]) => !excludedProperties.includes(key as keyof PortCallExcelExport))
                .map(([key, value]) => ({ [key]: value }))
        );
    }

    private prepareHeaders(headers: PortCallExcelHeaders[], maxNumberOfCargos: number): PortCallExcelHeaders[] {
        for (let i = 0; i < maxNumberOfCargos; i++) {
            if (i > 0) {
                const index = i + 1;
                headers.push(`Cargo (${index})`);
                headers.push(`Cargo Description (${index})`);
                headers.push(`Cargo Rate (${index})`);
                headers.push(`UOM (CR) (${index})`);
                headers.push(`Quantity (${index})`);
                headers.push(`UOM (QT) (${index})`);
            }
        }

        return headers;
    }
}
