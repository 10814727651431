import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { ActionPendingInterceptor } from '@port-line-up/shared/util';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
    selector: 'plu-scoped-spinner',
    standalone: true,
    imports: [CommonModule, ProgressSpinnerModule],
    templateUrl: './scoped-spinner.component.html',
    styleUrls: ['./scoped-spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScopedSpinnerComponent {
    isPending$: Observable<boolean> = this.pendingService.isPending$;
    constructor(private pendingService: ActionPendingInterceptor) { }
}
