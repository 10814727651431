import { CompanyAccessRights, UserRole } from '../enums';

export class User {
    id: string | null = null;
    name: string | null = '';
    lastName: string | null = '';
    email: string | null = '';
    roleId: UserRole | null = null;
    isActive = false;
    companyId?: string;
    companyName?: string;
    companyAccessRights?: CompanyAccessRights;
    editablePortUnloCodes: string[] = [];
    isGacUser = false;
    
    static createFrom(user: User): User {
        return Object.assign(new User(), user);
    }

    isGlobalAdmin(): boolean {
        return this.roleId === UserRole.GlobalAdmin;
    }

    isLocalAdmin(): boolean {
        return this.roleId === UserRole.LocalAdmin;
    }

    isEditor(): boolean {
        return this.roleId === UserRole.Editor;
    }

    isViewer(): boolean {
        return this.roleId === UserRole.Viewer;
    }
}
