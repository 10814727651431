import { Injectable } from '@angular/core';
import { PortCallPresentation } from '@port-line-up/shared/feature-presentation-table';
import { PortCallReport } from '../models/port-call-report';

@Injectable()
export class GroupVesselsByCargoUomServices {
   groupVesselsByCargoUom(portCalls: PortCallPresentation[]): Map<string, PortCallReport[]> {
        const groupedVessels: Map<string, PortCallReport[]> = new Map<string, PortCallReport[]>();

        portCalls.forEach((portCallPresentation: PortCallPresentation) => {
            const vesselWithGroupedCargoUom: Map<string, PortCallReport> = this.groupCargoUomInVessel(portCallPresentation);
            for (const [key, value] of vesselWithGroupedCargoUom) {
                const portCallReports: PortCallReport[] | undefined = groupedVessels.get(key);
                if (portCallReports) {
                    portCallReports.push(value);
                    groupedVessels.set(key, portCallReports);
                } else {
                    groupedVessels.set(key, [value]);
                }
            }
        });
       return groupedVessels;
    }

    private groupCargoUomInVessel(portCall: PortCallPresentation): Map<string, PortCallReport> {
        const map: Map<string, PortCallReport> = new Map<string, PortCallReport>();

        portCall.cargoQuantityUomName.forEach((cargoQuantityUomName: string | null, index: number) => {
            if(cargoQuantityUomName && portCall.cargoQuantity[index]) {
                let portCallReport: PortCallReport | undefined = map.get(cargoQuantityUomName ?? '');
                if (portCallReport) {
                    portCallReport.cargoQuantity += portCall.cargoQuantity[index] ?? 0
                } else {
                    portCallReport = {
                        cargoQuantity: portCall.cargoQuantity[index] ?? 0,
                        dwt: portCall.dwt,
                        vesselStatus: portCall.vesselStatus,
                        vesselStatusId: portCall.vesselStatusId
                    } as PortCallReport;
                }
                map.set(portCall.cargoQuantityUomName[index] ?? '', portCallReport)
            }
        });
        return map;
    }
}
