export class LookupItem implements Lookup {
    constructor(public readonly id: string, public readonly displayText: string) {}
    static create(id: string, displayText: string): LookupItem {
        return new LookupItem(id, displayText);
    }

    static createEmpty(): LookupItem {
        return new LookupItem('', '');
    }
}

export interface Lookup {
    id: string;
    displayText: string;
}
