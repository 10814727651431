import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { PortCall } from '@port-line-up/shared/data-access';

@Directive({ selector: '[pluCellChange]', standalone: true })
export class CellChangedDirective {
    @Input('pluCellChange') propertyName!: (keyof PortCall)[];

    constructor(private el: ElementRef, private renderer: Renderer2) {}
    setClass(): void {
        this.renderer.addClass(this.el.nativeElement, 'datatable-presentation-cell-changed');
        this.renderer.addClass(this.el.nativeElement, 'ease-in-out');
        this.renderer.addClass(this.el.nativeElement, 'duration-300');
    }

    removeClass(): void {
        this.renderer.removeClass(this.el.nativeElement, 'datatable-presentation-cell-changed');
        this.renderer.addClass(this.el.nativeElement, 'inherit');
    }
}
