import {
    ChangeDetectionStrategy,
    Output, Component,
    input, EventEmitter,
    InputSignal
} from '@angular/core';
import { ChipModule } from 'primeng/chip';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { GridFilterChip } from '../../../../../util/src/lib/models/grid-filter-chip';
import { PartyChipItem, PartyGridFilterChip } from '../../../../../util/src/lib/models/party-grid-filter-chip';

@Component({
    selector: 'plu-grid-filter-chips',
    templateUrl: 'grid-filter-chips.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ CommonModule, ChipModule, TooltipModule ],
    standalone: true
})
export class GridFilterChipsComponent {
    filterChips: InputSignal<GridFilterChip[]> = input.required<GridFilterChip[]>();
    partyFilterChip: InputSignal<PartyGridFilterChip | null> = input<PartyGridFilterChip | null>(null);

    @Output() clearFilter: EventEmitter<string> = new EventEmitter<string>();
    @Output() clearAllFilters: EventEmitter<Event> = new EventEmitter<Event>();

    onClearFilter(filterId: string): void {
        this.clearFilter.emit(filterId);
    }

    generateTooltip(filterTitle: string, filterValues: string[]): string {
        const stringBuilder: string[] = [];
        filterValues.slice(1).forEach((value: string) => stringBuilder.push(`${filterTitle}: ${value}`));
        return stringBuilder.join('\n');
    }

    generatePartyTooltip(partyChipItems: PartyChipItem[]): string {
        const stringBuilder: string[] = [];
        partyChipItems.slice(1).forEach((partyChipItem: PartyChipItem) => stringBuilder.push(`${partyChipItem.title}: ${partyChipItem.value}`));
        return stringBuilder.join('\n');
    }
}
