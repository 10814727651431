import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { Observable, of } from 'rxjs';
import { VesselsLocationUpdate } from '../models';

@Injectable({providedIn: 'root'})
export class VesselLocationService {
    private readonly baseUrl = `${this.appEnvironment.baseApiUrl}/vesselLocation`;

    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private appEnvironment: any) {}

    getByUnlocode(unloCode: string): Observable<VesselsLocationUpdate[]> {
        if (!unloCode) {
            throw new Error('Unlo Code is required');
        }

        return this.http.get<VesselsLocationUpdate[]>(`${this.baseUrl}/byUnlocode/${unloCode}`);
    }

    getByUnlocodeForSharedView(unloCode: string,  token: string): Observable<VesselsLocationUpdate[]> {
        if (!unloCode) {
            throw new Error('Unlo Code is required');
        }
        const headers = { Authorization: `Bearer ${token}` };

        return this.http.get<VesselsLocationUpdate[]>(`${this.baseUrl}/byUnlocode/${unloCode}/anonymous`, {headers});
    }

    getByFleetId(fleetId: string | null): Observable<VesselsLocationUpdate[]> {
        const params = new HttpParams().set('fleetId', fleetId || '');

        return this.http.get<VesselsLocationUpdate[]>(`${this.baseUrl}/byFleetId`, {params});
    }

    getByVesselLocationId(ihsVesselLocationId: string | null): Observable<VesselsLocationUpdate | null> {
        if(!ihsVesselLocationId) {
            return of(null);
        }

        return this.http.get<VesselsLocationUpdate>(`${this.baseUrl}/byVesselLocationId/${ihsVesselLocationId}`);
    }    
}