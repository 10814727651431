import { ChangeDetectionStrategy, Component, computed, Input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartModule } from 'primeng/chart';
import { ChartData } from 'chart.js';
import { VesselsReport } from '../../models/vessels-report';
import { PortCallReport } from '../../models/port-call-report';
import { VesselReportService } from '../../services/vessel-report.service';

@Component({
    selector: 'plu-report-table',
    templateUrl: 'report-table.component.html',
    styleUrl: 'report-table.component.scss',
    imports: [CommonModule, ChartModule],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportTableComponent {
    @Input() set portCalls(portCalls: PortCallReport[] | null) {
        if (portCalls === null) {
            return;
        }
        this.creatVesselReports(portCalls);
    }


    @Input() quantityUom!: string;

    chartData!: ChartData;

    capesQuantity = signal<number>(0);
    postPanamaxesQuantity = signal<number>(0);
    handyMaxQuantity = signal<number>(0);
    unknownQuantity = signal<number>(0);

    alongSideReport = signal<VesselsReport>(new VesselsReport());
    atAnchorReport = signal<VesselsReport>(new VesselsReport());
    enRouteReport = signal<VesselsReport>(new VesselsReport());

    capesCount = computed(() => this.alongSideReport().capes + this.atAnchorReport().capes + this.enRouteReport().capes);
    postPanamaxesCount = computed(() => this.alongSideReport().postPanamaxes + this.atAnchorReport().postPanamaxes + this.enRouteReport().postPanamaxes);
    handyMaxCount = computed(() => this.alongSideReport().handyMax + this.atAnchorReport().handyMax + this.enRouteReport().handyMax);
    unknownCount = computed(() => this.alongSideReport().unknown + this.atAnchorReport().unknown + this.enRouteReport().unknown);

    constructor( private vesselReportService: VesselReportService) {
    }

    private creatVesselReports(portCalls: PortCallReport[]): void {

        const alongSideReports = new VesselsReport();
        const atAnchorReports = new VesselsReport();
        const enRouteReports = new VesselsReport();

        let capesQuantity = 0;
        let postPanamaxesQuantity = 0;
        let handyMaxQuantity = 0;
        let unknownQuantity = 0;

        portCalls.forEach((portCall: PortCallReport) => {
            const cargoQuantity = portCall.cargoQuantity;
            if (portCall.vesselStatus) {
                if (portCall.dwt) {
                    if(portCall.dwt > 95000) {
                        capesQuantity += cargoQuantity ?? 0;
                    } else if (portCall.dwt >= 56000 && portCall.dwt <= 95000) {
                        postPanamaxesQuantity += cargoQuantity ?? 0;
                    } else if (portCall.dwt < 56000 ) {
                        handyMaxQuantity += cargoQuantity ?? 0;
                    }
                } else {
                    unknownQuantity += cargoQuantity;
                }
            }

            this.vesselReportService.fillVesselReportBasedOnVesselStatus(portCall, alongSideReports, atAnchorReports, enRouteReports);
        });

        this.alongSideReport.set(alongSideReports);
        this.atAnchorReport.set(atAnchorReports);
        this.enRouteReport.set(enRouteReports);

        this.capesQuantity.set(capesQuantity);
        this.postPanamaxesQuantity.set(postPanamaxesQuantity);
        this.handyMaxQuantity.set(handyMaxQuantity);
        this.unknownQuantity.set(unknownQuantity);

        this.setChartData();
    }

    private setChartData(): void {
        this.chartData = {
            datasets: [
                {
                    data: [this.capesCount(), this.postPanamaxesCount(), this.handyMaxCount()],
                    backgroundColor: ['#ed7d31','#4472c4', '#a5a5a5'],
                },
            ],
            labels: ['CAPES (DWT>95K)', 'POST / PANAMAXES (DWT56K-95k)', 'HANDY / MAX (DWT<56k)'],
        }
    }
}
