import { Injectable } from '@angular/core';
import { VesselStatus } from '../enums';
import { LookupItem } from '@port-line-up/shared/util';
@Injectable({ providedIn: 'root' })
export class VesselStatusService {
  private vesselStatuses: LookupItem[] =[
    { id: VesselStatus.Expected, displayText: 'Expected' },
    { id: VesselStatus.Arrived, displayText: 'Arrived' },
    { id: VesselStatus.Berthed, displayText: 'Berthed' },
    { id: VesselStatus.Sailed, displayText: 'Sailed' },
    { id: VesselStatus.OperationsInProgress, displayText: 'Operations in progress' },
    { id: VesselStatus.OperationCompleted, displayText: 'Operation completed' },
  ];
  getAll(): LookupItem[] {
    return this.vesselStatuses
  }
}
