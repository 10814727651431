import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';

export function cancelableSearch<TResult, TSearchQuery>(searchFunction: (parameter: TSearchQuery) => Observable<TResult>, debounce = 300) {
    const resultSubject = new Subject<TResult>();
    const result$ = resultSubject.asObservable();
    const searchQuery = new Subject<TSearchQuery>();

    searchQuery
        .pipe(
            takeUntilDestroyed(),
            distinctUntilChanged(),
            debounceTime(debounce),
            switchMap((query: TSearchQuery) => searchFunction(query))
        )
        .subscribe((result: TResult) => {
            resultSubject.next(result);
        });

    return {
        search: (query: TSearchQuery): void => {
            searchQuery.next(query);
        },
        result$,
    };
}
