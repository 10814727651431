import { Injectable } from '@angular/core';
import { DateService } from '@port-line-up/shared/util';
import { SailedPortCalls } from '../models/changed-port-calls';

@Injectable()
export class SailedDataService {
    constructor(private date: DateService) {}

    add(id: string, sailedMap: SailedPortCalls): void {
        if (sailedMap.has(id)) {
            return;
        }

        sailedMap.set(id, this.date.now());
    }

    remove(sailedMap: SailedPortCalls, after: number): SailedPortCalls {
        const seconds = after * 1000;
        const currentTime = this.date.now();

        for (const [id, time] of sailedMap.entries()) {
            const shouldRemove = time + seconds < currentTime;
            if (shouldRemove) {
                sailedMap.delete(id);
            }
        }

        return sailedMap;
    }
}
