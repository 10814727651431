import { inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ComponentStore } from '@ngrx/component-store';
import { ApiResponse, ApiResponseType, ErrorMessageService } from '@port-line-up/shared/data-access';

export interface BaseState {
    apiResponse: ApiResponse | null;
}

export const baseInitialState: BaseState = {
    apiResponse: null
};

export class BaseStore<T extends BaseState> extends ComponentStore<T> {

    errorMessageService = inject(ErrorMessageService)
    constructor(initialState: T) {
        super(initialState);
    }

    readonly apiResponse$: Observable<ApiResponse | null> = this.select((state) => state.apiResponse);

    failure = this.updater((state, error: HttpErrorResponse) => ({
        ...state,
        apiResponse: new ApiResponse(ApiResponseType.Error, this.errorMessageService.handleErrorMessage(error.error)),
    }));
}
