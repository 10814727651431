import { Injectable } from '@angular/core';
import { FilterMatchMode, FilterMetadata } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class DwtMessageFactory {
  create(filterMetadata: FilterMetadata[]): string {
    const chipMessages: string[] = [];

    filterMetadata.forEach((filterMetadata: FilterMetadata) => {
      if (filterMetadata.value && filterMetadata.matchMode) {
        const matchModeMessage = this.getMatchModeMessage(filterMetadata.matchMode);
        const chipMessage = `${matchModeMessage} ${filterMetadata.value}`;

        chipMessages.push(chipMessage);
      }
    });

    return chipMessages.join(' and ');
  }

  private getMatchModeMessage(matchMode: string): string {
    switch (matchMode) {
      case FilterMatchMode.EQUALS:
        return 'Equals to';
      case FilterMatchMode.GREATER_THAN:
        return 'Greater then';
      case FilterMatchMode.GREATER_THAN_OR_EQUAL_TO:
        return 'Greater then or equal to';
      case FilterMatchMode.LESS_THAN:
        return 'Less than';
      case FilterMatchMode.LESS_THAN_OR_EQUAL_TO:
        return 'Less than or equal to';
      default:
        return '';
    }
  }
}
