export class ScheduledMaintenance {
    id = '';
    portId = '';
    portName = '';
    terminalId = '';
    terminalName = '';
    from = '';
    to = '';
    note = '';

    static create(portId: string, portName: string): ScheduledMaintenance {
        const respone = new ScheduledMaintenance();
        respone.portId = portId;
        respone.portName = portName;
        return respone; 
    }
}