import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NumberMatchModes } from '@port-line-up/shared/util';
import { TableModule } from 'primeng/table';

@Component({
    selector: 'plu-number-filter',
    standalone: true,
    imports: [TableModule],
    templateUrl: './number-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberFilterComponent {
    @Input({ required: true }) field!: string;
    @Input({ required: true }) placeholder!: string;
    NumberMatchModes = NumberMatchModes;
}
