import { inject, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FilterMatchMode, FilterMetadata } from 'primeng/api';
import { CustomMatchMode } from '../../models';

@Injectable({ providedIn: 'root' })
export class DateChipMessageFactory {

    private datePipe: DatePipe = inject(DatePipe);
    create(filterMetadata: FilterMetadata[]): string {
        const chipMessages: string[] = [];
        filterMetadata.forEach((filterMetadata: FilterMetadata) => {
            if (filterMetadata.value && filterMetadata.matchMode) {
                const matchModeMessage = this.handleMatchMode(filterMetadata.matchMode);
                const date = this.datePipe.transform(filterMetadata.value, 'dd/MM/yy');
                const chipMessage = `${matchModeMessage} ${date}`
                chipMessages.push(chipMessage);
            }
            if(!filterMetadata.value && filterMetadata.matchMode === CustomMatchMode.EmptyDate) {
                chipMessages.push('Empty');
            }
        });
        return chipMessages.join(' and ');
    }

    private handleMatchMode(matchMode: string): string {
        switch (matchMode) {
            case FilterMatchMode.DATE_IS:
                return 'Date is ';
            case FilterMatchMode.DATE_IS_NOT:
                return 'Date is not ';
            case FilterMatchMode.DATE_BEFORE:
                return 'Date is before ';
            case FilterMatchMode.DATE_AFTER:
                return 'Date is after ';
            default:
                return '';
        }
    }
}
