import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoCompleteColumnFilterCommonSettingsDirective, ColumnFilterItem, CustomMatchMode, ShowCloseButtonDirective } from '@port-line-up/shared/util';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table';

@Component({
    selector: 'plu-voyage-number-filter',
    standalone: true,
    imports: [TableModule, ShowCloseButtonDirective, AutoCompleteModule, FormsModule, AutoCompleteColumnFilterCommonSettingsDirective],
    templateUrl: './voyage-number-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoyageNumberFilterComponent {
    @Input({ required: true }) field!: string;
    @Input() suggestions: ColumnFilterItem<any, any>[] = [];
    @Output() clearFilters = new EventEmitter<void>();
    @Output() search = new EventEmitter<string>();
    @Output() applyFilter = new EventEmitter<{ filter: (filterValues: any[]) => void; value: any }>();
    @Output() unApplyFilter = new EventEmitter<{ filter: (filterValues: any[]) => void; value: any }>();

    CustomMatchMode = CustomMatchMode;
}
