import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ApplyFilterOnFilterMetadataChangeDirective, ShowCloseButtonDirective } from '@port-line-up/shared/util';
import { FilterMatchMode } from 'primeng/api';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { Nullable } from 'primeng/ts-helpers';
import { PluHoursToDaysPipe } from '../../pipes/hours-to-days.pipe';
import { PluHoursToRemainingHoursPipe } from '../../pipes/hours-to-remaining-hours.pipe';

@Component({
    selector: 'plu-berth-delay-filter',
    templateUrl: 'berth-delay-filter.component.html',
    imports: [
        CommonModule,
        FormsModule,
        InputNumberModule,
        TableModule,
        ShowCloseButtonDirective,
        ApplyFilterOnFilterMetadataChangeDirective,
        PluHoursToDaysPipe,
        PluHoursToRemainingHoursPipe
    ],
    standalone: true,
})
export class BerthDelayFilterComponent {
    @Input() fieldName!: string;
    @Input() placeholder!: string;

    readonly matchModeOptions = [
        {
            label: 'Less than',
            value: FilterMatchMode.LESS_THAN
        },
        {
            label: 'Less than or equal',
            value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        },
        {
            label: 'Greater than',
            value: FilterMatchMode.GREATER_THAN
        },
        {
            label: 'Greater than or equal',
            value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        {
            label: 'Equals',
            value: FilterMatchMode.EQUALS
        }
    ];

    checkIfAtLeastOnValueExist(days: Nullable<number>, hours: Nullable<number>): boolean {
        return !!(days || hours);
    }

    getFilterValue(days: Nullable<number>, hours: Nullable<number>): number {
        const hoursPerDay = 24;

        return ((days ?? 0) * hoursPerDay) + Number(hours ?? 0);
    }
}
