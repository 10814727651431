import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { VesselCompliance } from '../models';

@Injectable({ providedIn: 'root' })
export class VesselComplianceService {
    private readonly url = `${this.appEnvironment.baseApiUrl}/compliance`;

    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private appEnvironment: any) {}

    getVesselMovement(imoNumber: string): Observable<VesselCompliance> {
        return this.http.get<VesselCompliance>(`${this.url}?imoNumber=${imoNumber}`);
    }
}
