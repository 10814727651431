import { Injectable } from '@angular/core';
import { DynamicColumnResolver } from '../dynamic-column-resolver';
import { Column } from '@port-line-up/shared/util';
import { UserStateService } from '@port-line-up/shared/data-access';
import { first } from 'rxjs';
import { ExcelUploadViewAdminResolver } from './resolvers/admin.resolver';
import { ExcelUploadViewDefaultResolver } from './resolvers/default.resolver';
import { ExcelUploadViewGacUserResolver } from './resolvers/gac-user.resolver';

@Injectable({ providedIn: 'root' })
export class ExcelUploadViewColumnResolver {
    private resolvers: DynamicColumnResolver[] = [];
    constructor(userStateService: UserStateService) {
        userStateService.user$.pipe(first()).subscribe((user) => {
            if (!user) {
                return;
            }

            this.resolvers = [new ExcelUploadViewGacUserResolver(user), new ExcelUploadViewAdminResolver(user), new ExcelUploadViewDefaultResolver()];
        });
    }

    resolve(): Column[] {
        return (this.resolvers.find((r) => r.canResolve()) as DynamicColumnResolver).resolve();
    }
}
