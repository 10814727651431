export class PublicHolidaySave {
    constructor(
        public readonly id: string | undefined,
        public readonly portId: string,
        public readonly name: string,
        public readonly from: string,
        public readonly to: string,
        public readonly note: string
    ) {}
}
