<p-columnFilter 
  [field]="fieldName()"
  [matchMode]="matchMode()"
  display="menu"
  [showMatchModes]="false"
  [showOperator]="false"
  [showAddButton]="false"
  pluShowCloseButton
  >
    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
      <p-multiSelect 
        optionLabel="filterLabel"
        dataKey="value.id"
        [optionValue]="optionValue()"
        placeholder="Any"
        [ngModel]="value"
        [ngModelOptions]="{standalone: true}"
        [options]="options()"
        (onChange)="filter($event.value)"
        >
        <ng-template let-option pTemplate="item">
          <div class="font-medium">{{option.filterLabel}}</div>
        </ng-template>
      </p-multiSelect>
    </ng-template>
</p-columnFilter>