import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TimeDifferenceService {

    calculateTimeDifferenceInMinutesBetweenTwoDates(dt2: Date, dt1: Date): number {
        let diff =(dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
    }

    calculateTimeDifferenceInDaysBetweenTwoDates(dt2: Date, dt1: Date): number{
        const timeDifferenceInMinutes = this.calculateTimeDifferenceInMinutesBetweenTwoDates(dt2, dt1);
        const timeDifferenceInDays = timeDifferenceInMinutes / (60 * 24);
        return Math.abs(Math.round(timeDifferenceInDays));
    }
}
