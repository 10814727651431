<ng-template #headerTemplate>
    <div class="flex flex-row justify-between w-full">
        <div class="flex w-72">
            <div>
                <plu-breadcrumb [item]="breadcrumbItem" class="text-primary-800 text-xl font-semibold"></plu-breadcrumb>

                <p class="text-sm text-gray-500">Last updated at {{this.lastUpdate$ | async | date: 'H:mm'}}
                    <p-button icon="pi pi-refresh" [rounded]="true" [outlined]="true" styleClass="p-button-sm border-gray-300 p-0 rotate-45 w-5 h-5 text-gray-500"
                    (onClick)="refresh($event)"></p-button>
                </p>
            </div>
        </div>
      <div class=" flex justify-between" [ngClass]="showReport ? 'w-72' : 'w-40'">
        <div class="flex items-center">
          <div class="mr-2 flex items-center">
            <p class="text-sm block">Show map</p>
          </div>
          <p-inputSwitch styleClass="flex items-center" (onChange)="toggleMap($event)" [ngModel]="tableFilteredService.isMapOpened$ | async"></p-inputSwitch>
        </div>

        <div class="flex items-center" *ngIf="showReport">
          <div class="mr-2 flex items-center">
            <p class="text-sm block">Show report</p>
          </div>
          <p-inputSwitch styleClass="flex items-center" (onChange)="toggleReport($event)" [ngModel]="tableFilteredService.isReportOpened$ | async"></p-inputSwitch>
        </div>
      </div>
        <div class="flex">
            <div class="flex items-center">
                <plu-change-message></plu-change-message>
            </div>
            <div class="pr-2 flex">
                <div class="mr-2 flex items-center">
                    <p class="text-sm block">Overdue</p>
                </div>
                <p-inputSwitch class="flex items-center" (onChange)="toggleDelayedVessels($event)"></p-inputSwitch>
            </div>
            <div class="pr-2 flex items-center">
              <button pButton type="button" label="Documents" icon="pi pi-paperclip sm-max:mr-1" iconPos="left"
                      class="p-button-sm p-button-secondary sm-max:tracking-tight sm-max:pl-1 sm-max:text-xs flex-1 pointer-events-auto"
                      (click)="openAssetDownloadModal($event)"></button>
            </div>
            <div class="pr-2 flex items-center">
                <button pButton type="button" label="Export" icon="pi pi-file-excel sm-max:mr-1" iconPos="left"
                    class="p-button-sm p-button-secondary sm-max:tracking-tight sm-max:pl-1 sm-max:text-xs flex-1 pointer-events-auto"
                    (click)="export($event)"></button>
            </div>
            <div class="flex items-center pr-2" (click)="$event.stopPropagation()">
                <plu-presentation-table-settings-menu></plu-presentation-table-settings-menu>
            </div>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="(filterChipsService.filterChips$ | async) as filterChips">
    <div class="px-6" *ngIf="filterChips.length > 0">
        <plu-grid-filter-chips
                [filterChips]="filterChips"
                (clearFilter)="clearFilter($event)"
                (clearAllFilters)="clearAllFilters($event)" />
    </div>
</ng-container>

<div class="relative w-full" [style.height]="getSharedViewMapHeight()" *ngIf="shouldOpenMap$ | async">
    <plu-map mapContainerId="sharedMap" [initialCoordinates]="getPortCordinates()"></plu-map>
</div>

<div class="relative w-full min-h-[520px] px-6 py-6" *ngIf="(shouldOpenReport$ | async) && showReport">
  <plu-shared-report [token]="sharedViewStore.token$ | async" [portCalls]="(tableFilteredPortCalls$ | async) ?? []" [port]="port"></plu-shared-report>
</div>

<p-accordion [multiple]="true" [activeIndex]="[0]" styleClass="border-gray-300 py-6 pl-6 pr-8">
    <p-accordionTab header="Ongoing" headerStyleClass="border-0 border-0 p-accordion-tab-child" contentStyleClass="border-0 border-0 p-accordion-tab-child"
                    (pluAccordionTabHeaderHeight)="setAccordionTabHeaderHeight($event)"
        class="rounded-md border-solid border-[1px] block">
        <div class="relative border-b">
            <plu-shared-view-table
                    [portCalls]="portCalls$ | async"
                    [port]="port"
                    [initialFilters]="(initialFilters$ | async)!"
                    [tableHeightOffset]="tableHeightOffset()"
            ></plu-shared-view-table>
            <plu-scoped-spinner></plu-scoped-spinner>
        </div>
    </p-accordionTab>
    <p-accordionTab header="Sailed" headerStyleClass="border-0 p-accordion-tab-child" contentStyleClass="border-0 p-accordion-tab-child"
        class="rounded-md  border-solid border-[1px] mt-4 block">
        <ng-template pTemplate="content">
            <div class="relative">
                <plu-shared-view-sailed-table [port]="port"></plu-shared-view-sailed-table>
                <plu-scoped-spinner></plu-scoped-spinner>
            </div>
        </ng-template>
    </p-accordionTab>
    <plu-disclaimer class="mt-4" />
</p-accordion>
