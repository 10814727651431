<div class="w-full h-full flex justify-center flex-col overflow-auto relative mt-4">
    <div>
        <div class="flex justify-between">
            <div class="flex flex-col w-full">
                <table>
                    <thead>
                    <tr>
                        <th class="text-center w-72 bg-blue-900 text-white">VESSEL TYPE / STATUS</th>
                        <th class="text-center  bg-blue-900 text-white">ALONGSIDE</th>
                        <th class="text-center bg-blue-900 text-white">AT ANCHOR</th>
                        <th class="text-center bg-blue-900 text-white">EN ROUTE</th>
                        <th class="text-center bg-blue-900 text-white">TOTAL</th>
                        <th class="text-center w-72 bg-gray-600">TOTAL {{ quantityUom | uppercase }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="w-72">CAPES (DWT>95K)</td>
                        <td class="text-center">{{ alongSideReport().capes | number }}</td>
                        <td class="text-center">{{ atAnchorReport().capes | number }}</td>
                        <td class="text-center">{{ enRouteReport().capes | number }}</td>
                        <td class="text-center">{{ capesCount() | number }}</td>
                        <td class="text-end w-72">{{ capesQuantity() | number }}</td>
                    </tr>
                    <tr>
                        <td class="w-72">POST / PANAMAXES (DWT56K-95K)</td>
                        <td class="text-center">{{ alongSideReport().postPanamaxes | number }}</td>
                        <td class="text-center">{{ atAnchorReport().postPanamaxes | number }}</td>
                        <td class="text-center">{{ enRouteReport().postPanamaxes | number }}</td>
                        <td class="text-center">{{ postPanamaxesCount() | number }}</td>
                        <td class="text-end w-72">{{ postPanamaxesQuantity() | number }}</td>
                    </tr>
                    <tr>
                        <td class="w-72">HANDY / MAX (DWT<56K)</td>
                        <td class="text-center">{{ alongSideReport().handyMax | number }}</td>
                        <td class="text-center">{{ atAnchorReport().handyMax | number }}</td>
                        <td class="text-center">{{ enRouteReport().handyMax | number }}</td>
                        <td class="text-center">{{ handyMaxCount() | number }}</td>
                        <td class="text-end w-72">{{ handyMaxQuantity() | number }}</td>
                    </tr>
                    <tr>
                        <td class="w-72">UNKNOWN DWT</td>
                        <td class="text-center">{{ alongSideReport().unknown | number }}</td>
                        <td class="text-center">{{ atAnchorReport().unknown | number }}</td>
                        <td class="text-center">{{ enRouteReport().unknown | number }}</td>
                        <td class="text-center">{{ unknownCount() | number }}</td>
                        <td class="text-end w-72">{{ unknownQuantity() | number }}</td>
                    </tr>
                    <tr>
                        <td class="bg-yellow-500 font-bold w-72">TOTAL NUMBER OF VESSELS</td>
                        <td class="text-center bg-yellow-500 font-bold">{{ alongSideReport().totalNumberOfVessels() | number }}</td>
                        <td class="text-center bg-yellow-500 font-bold">{{ atAnchorReport().totalNumberOfVessels() | number }}</td>
                        <td class="text-center bg-yellow-500 font-bold">{{ enRouteReport().totalNumberOfVessels() | number }}</td>
                        <td class="text-center bg-yellow-500 font-bold">{{ (alongSideReport().totalNumberOfVessels() + atAnchorReport().totalNumberOfVessels() + enRouteReport().totalNumberOfVessels()) | number }}</td>
                        <td class="text-end bg-gray-600 w-72">{{ (capesQuantity() + postPanamaxesQuantity() + handyMaxQuantity() + unknownQuantity()) | number }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class=" flex items-center flex-col justify-center px-4">
                <p class="text-base font-bold">{{ quantityUom }}</p>
                <p-chart type="pie" [data]="chartData" height="170"></p-chart>
            </div>
        </div>
    </div>
</div>

