import { ColumnFilterItem } from '../models';

export function sortColumnFilterItemsAndSetEmptyAsFirst<TFilter, TData>(items: ColumnFilterItem<TFilter, TData>[]): ColumnFilterItem<TFilter, TData>[] {
    const sortedItems = items.sort((a, b) => a.filterLabel.localeCompare(b.filterLabel));
    const emptyItem = sortedItems.find((item) => item.filterLabel === 'Empty');
    if (emptyItem) {
        sortedItems.splice(sortedItems.indexOf(emptyItem), 1);
        sortedItems.unshift(emptyItem);
    }
    return sortedItems;
}
