import { signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';

export function initializeAutocompleteFilter<T>(source: Observable<T[]>, predicate: (suggestion: T, query: string) => boolean) {
  const suggestions = signal<T[]>([]);
  const appliedFilters = new Set<T>();
  let suggestionsCopy: T[];

  source.pipe(takeUntilDestroyed()).subscribe((data) => {
    suggestions.set(data);
    suggestionsCopy = data;
  });

  const applyFilter = (filterCallback: (filterValues: T[]) => void, filter: T): void => {
    appliedFilters.add(filter);
    filterCallback([...appliedFilters]);
  };

  const unApplyFilter = (filterCallback: (filterValues: T[]) => void, filter: T): void => {
    appliedFilters.delete(filter);
    filterCallback([...appliedFilters]);
  };

  const clearFilters = (): void => {
    appliedFilters.clear();
  };

  const search = (query: string): void => {
    suggestions.set([...suggestionsCopy]);

    suggestions.update((suggestions) => suggestions.filter((suggestion) => predicate(suggestion, query)));
  };

  return {
    suggestions,
    applyFilter,
    unApplyFilter,
    clearFilters,
    search,
  };
}
