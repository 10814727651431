import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { CompanyAccessRights } from '../enums';
import { LookupItem } from '@port-line-up/shared/util';

@Injectable({ providedIn: 'root' })
export class CompanyAccessRightService {
    private types: KeyValue<CompanyAccessRights, string>[] = [
        { key: CompanyAccessRights.Editor, value: 'Editor' },
        { key: CompanyAccessRights.Viewer, value: 'Viewer' },
    ];

    get(): KeyValue<CompanyAccessRights, string>[] {
        return this.types;
    }

    getValue(key: CompanyAccessRights | null): string {
        if (key === null || key === undefined) {
            return '';
        }

        const result = this.types.find((status) => status.key == key);
        return result ? result.value : '';
    }

    getLookups(): LookupItem[] {
        return this.types.map(entry => new LookupItem(entry.key.toString(), entry.value));
    }
}
