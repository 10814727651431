export enum ExpressionOperator {
    Equals = 0,
    Different = 1,
    Greater = 2,
    GreaterOrEquals = 3,
    Less = 4,
    LessOrEqual = 5,
    Contains = 6,
    StartsWith = 9,
}
