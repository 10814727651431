import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Table, TableFilterEvent } from 'primeng/table';
import { TableFilteredService } from '../services';

@Directive({ selector: '[pluTableFiltered]',standalone: true })
export class TableFilteredDirective implements OnInit, OnDestroy {

    /*
    * Turn off or on the directive. Default is true;
    */
    @Input('pluTableFiltered') isEnabled = true;
    private destroy$ = new Subject<void>();

    constructor(private table: Table, private tableFilteredService: TableFilteredService) {}

    ngOnInit(): void {
        if (this.isEnabled) {
            this.table.onFilter.pipe(takeUntil(this.destroy$)).subscribe((filterEvent: TableFilterEvent) => {
                        this.tableFilteredService.loadPortCalls(filterEvent.filteredValue)
                }
            );
            this.tableFilteredService.loadPortCalls(this.table.filteredValue || this.table.value)
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
