import { NavigationExtras } from '@angular/router';

export class BreadcrumbItem {
    public previousItem?: BreadcrumbItem;

    constructor(
        public readonly key: string,
        public readonly label: string,
        public readonly routerLink?: string,
        public readonly navigationExtras?: NavigationExtras,
        public readonly url?: string
    ) {}

    appendNext(item: BreadcrumbItem): BreadcrumbItem {
        item.previousItem = this;
        return item;
    }

    getAllPreviousItems(): BreadcrumbItem[] {
        return this.getPreviousItem(this.previousItem);
    }

    private getPreviousItem(item?: BreadcrumbItem): BreadcrumbItem[] {
        if (!item) {
            return [];
        }
        if (!item.previousItem) {
            return [item];
        }
        return [...this.getPreviousItem(item.previousItem), item];
    }
}
