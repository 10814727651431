import { Inject, Injectable } from '@angular/core';
import { HubConnectionBuilder, LogLevel, HubConnection } from '@microsoft/signalr';
import { catchError, from, lastValueFrom, map, Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';

@Injectable({
    providedIn: 'root',
})
export class SignalRService {
    constructor(private authService: AuthService, @Inject(APP_ENVIRONMENT) private appEnvironment: any) {}

    createConnection(url: string): ServerConnection {
        const token = this.authService.acquireTokenSilent().pipe(catchError((_) => {return of({accessToken: ''})}));
        return new ServerConnection(
            new HubConnectionBuilder()
                .configureLogging(LogLevel.Information)
                .withUrl(this.appEnvironment.signalRUrl + url, {
                    headers: { 'ocp-apim-subscription-key': this.appEnvironment.ocpApimSubscriptionKey },
                    accessTokenFactory: () => lastValueFrom(token.pipe(map((x) => x.accessToken))),
                })
                .withAutomaticReconnect()
                .build()
        );
    }
}

export class ServerConnection {
    constructor(private readonly connection: HubConnection) {}

    start(): Observable<void> {
        return from(this.connection.start());
    }

    invoke<T>(methodName: string, ...args: any[]): Observable<T> {
        return from(this.connection.invoke(methodName, ...args));
    }

    send(methodName: string, ...args: any[]): Observable<void> {
        return from(this.connection.send(methodName, ...args));
    }

    on(methodName: string, callback: (data: any) => void): void {
        return this.connection.on(methodName, callback);
    }

    stop(): Observable<void> {
        return from(this.connection.stop());
    }
}
