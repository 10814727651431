<plu-header></plu-header>
<plu-scrollable-container>
    <div class="plu-container max-w-[95%]">
        <p-accordion [activeIndex]="0" (pluAccordionHeaderHeight)="setAccordionHeaderHeight($event)">
            <p-accordionTab pluAccordionSetter *ngIf="true" headerStyleClass="border-0 p-accordion-tab-main position-icon"
                contentStyleClass="border-0 p-accordion-tab-main p-0" class="rounded-md border-solid border-[1px] mt-4 block">
                <plu-shared-view [port]="(port$ | async)!" [showReport]="(showReport$ | async)!" [assets]="(assets$ | async)!"
                    [accordionHeaderHeight]="accordionHeaderHeight()"></plu-shared-view>
            </p-accordionTab>
        </p-accordion>
    </div>
</plu-scrollable-container>


<plu-table-horizontal-scroll-bar class="pl-32 pr-32"></plu-table-horizontal-scroll-bar>