import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'plu-ihs-date-column-view',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './ihs-date-column-view.component.html',
    styleUrls: ['./ihs-date-column-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IhsDateColumnViewComponent {
    @Input() ihsDate!: string | null;
    @Input() buttonBgColorClass!: string;
    @Input() timeShorterName!: string;
}
