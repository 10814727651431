<p-table
    [value]="portCallsPresentation"
    dataKey="id"
    [scrollable]="true"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    pluExecuteLocalFilterOverride
    pluTablePresentationSort
    pluGridColumn
    [pluTableFiltered]="showPortCallOnMapButton"
    (columnsSet)="setColumns($event)"
    [columnsToExclude]="columnsToExclude"
    [pluGridFilter]="port.id"
    [initialFilters]="initialFilters"
    columnResizeMode="expand"
    pluTableGlobalHorizontalScroll
    [pluTableHeaderHorizontalScroll]="uiStore.headerHeight()"
    pluScrollingToSpecificRowByVesselMapClickDirective
    pluPresentationTableRegisterFilterRuleDirective
    pluVesselsLocationUpdate
    [port]="port"
    [customSort]="true">
  <ng-template pTemplate="header">
    <tr class="flex w-fit">
      @for (column of columns; track $index) {
        @switch (column.name) {
          @case ('vesselName') {
            <th pReorderableColumn [pReorderableColumnDisabled]="column.disableColumnReorder ?? false" pResizableColumn pFrozenColumn 
              class="border-r datatable-frozen-header z-10 last-frozen-column-from-left">
              <div class="flex items-center justify-between">
                {{ column.title }}
                <plu-vessel-filter
                [field]="PresentationTableFilterIds.vesselName"
                [suggestions]="columnFiltersAutocompleteDataProviderService.vesselNameFilter.suggestions()"
                (clearFilters)="columnFiltersAutocompleteDataProviderService.vesselNameFilter.clearFilters()"
                (search)="columnFiltersAutocompleteDataProviderService.vesselNameFilter.search($event)"
                (applyFilter)="columnFiltersAutocompleteDataProviderService.vesselNameFilter.applyFilter($event.filter, $event.value)"
                (unApplyFilter)="columnFiltersAutocompleteDataProviderService.vesselNameFilter.unApplyFilter($event.filter, $event.value)"
              ></plu-vessel-filter>
              </div>
            </th>
          }
          @case ('vesselType') {
          <th pReorderableColumn pResizableColumn class="border-r">
            <div class="flex items-center justify-between">
              {{ column.title }}
              <plu-vessel-type-filter [field]="PresentationTableFilterIds.vesselType" [options]="(columnFilterService.filterableVesselTypes$ | async) || []"></plu-vessel-type-filter>
            </div>
          </th>
          }
          @case ('voyageNumber') {
            <th pReorderableColumn pResizableColumn class="border-r">
              <div class="flex items-center justify-between">
                {{ column.title }}
                <plu-voyage-number-filter 
                [field]="PresentationTableFilterIds.voyageNumber"
                [suggestions]="columnFiltersAutocompleteDataProviderService.voyageNumberFilter.suggestions()"
                (clearFilters)="columnFiltersAutocompleteDataProviderService.voyageNumberFilter.clearFilters()"
                (search)="columnFiltersAutocompleteDataProviderService.voyageNumberFilter.search($event)"
                (applyFilter)="columnFiltersAutocompleteDataProviderService.voyageNumberFilter.applyFilter($event.filter, $event.value)"
                (unApplyFilter)="columnFiltersAutocompleteDataProviderService.voyageNumberFilter.unApplyFilter($event.filter, $event.value)"
            ></plu-voyage-number-filter>  
              </div>
            </th>
          }
          @case ('gacJobNumber') {
            <th pReorderableColumn pResizableColumn class="border-r">Gac Job</th>
          }
          @case ('vesselStatus') {
            <th pReorderableColumn pResizableColumn class="border-r">
              <div class="flex items-center justify-between">
                {{ column.title }}
                <plu-vessel-status-filter
                [field]="PresentationTableFilterIds.vesselStatusId"
                [options]="(columnFilterService.filterableStatuses$ | async) || []">
              </plu-vessel-status-filter>
            </div>
            </th>
          }
          @case ('terminalName') {
            <th pReorderableColumn pResizableColumn class="border-r" pSortableColumn="terminalName">
              <div class="flex items-center justify-between">
                <div class="flex items-center justify-center gap-1">
                  <p-sortIcon field="terminalName" />
                  <span>{{ column.title }}</span>
                </div>
                <plu-terminal-filter [field]="PresentationTableFilterIds.terminalId" [options]="(columnFilterService.filterableTerminals$ | async) || []" ></plu-terminal-filter>
              </div>
            </th>
          }
          @case ('berthName') {
            <th pReorderableColumn pResizableColumn class="border-r" pSortableColumn="berthName">
              <div class="flex items-center justify-between">
                <div class="flex items-center justify-center gap-1">
                  <p-sortIcon field="berthName" />
                  <span>{{ column.title }}</span>
                </div>
                <plu-berth-filter
                  [field]="PresentationTableFilterIds.berthId"
                  [options]="(columnFilterService.filterableBerths$ | async) || []"
              ></plu-berth-filter>
              </div>
            </th>
          }
          @case ('cargoTypeName') {
            <th pReorderableColumn pResizableColumn class="border-r">
              <div class="flex items-center justify-between">
                <span>{{ column.title }}</span>
                <plu-cargo-column-filter 
                [fieldName]="PresentationTableFilterIds.cargoType"
                [options]="(columnFilterService.filterableCargos$ | async) || []"
                [matchMode]="CargoMatchMode.CargoType">
              </plu-cargo-column-filter>
              </div>
            </th>
          }
          @case ('cargoDescription') {
            <th pReorderableColumn pResizableColumn class="border-r">
              <div class="flex items-center justify-between">
                {{ column.title }}
                <p-columnFilter class="ml-auto" type="text" [field]="PresentationTableFilterIds.cargoDescription" display="menu"
                  matchMode="contains" placeholder="Cargo Description" [showMatchModes]="false" [showAddButton]="false"
                  [showOperator]="false"></p-columnFilter>
              </div>
            </th>
          }
          @case ('cargoRate') {
            <th pReorderableColumn pResizableColumn class="border-r">{{ column.title }}</th>
          }
          @case ('quantity') {
            <th pReorderableColumn pResizableColumn class="border-r">{{ column.title }}</th>
          }
          @case ('operation') {
            <th pReorderableColumn pResizableColumn class="border-r">
              <div class="flex items-center justify-between">
                {{ column.title }}
                <plu-operation-filter
                [field]="PresentationTableFilterIds.operationId"
                [options]="(columnFilterService.filterableOperations$ | async) || []"
            ></plu-operation-filter> 
              </div>
            </th>
          }
          @case ('etaAta') {
          <th pReorderableColumn pResizableColumn class="border-r" pSortableColumn="ata">
            <div class="flex items-center justify-between">
              <span class="flex items-center gap-1">
                <p-sortIcon field="ata" /> {{ column.title }}
              </span>
              <plu-date-column-filter [fieldName]="PresentationTableFilterIds.ata" />
            </div>
          </th>
          }
          @case ('etbAtb') {
            <th pReorderableColumn pResizableColumn class="border-r" pSortableColumn="atb">
              <div class="flex items-center justify-between">
                <span class="flex items-center gap-1">
                  <p-sortIcon field="atb" /> {{ column.title }}
                </span>
                <plu-date-column-filter [fieldName]="PresentationTableFilterIds.atb" />
              </div>
            </th>
          }
          @case ('eccAcc') {
            <th pReorderableColumn pResizableColumn class="border-r" pSortableColumn="acc">
              <div class="flex items-center justify-between">
                <span class="flex items-center gap-1">
                  <p-sortIcon field="acc" /> {{ column.title }}
                </span>
                <plu-date-column-filter [fieldName]="PresentationTableFilterIds.acc" />
              </div>
            </th>
          }
          @case ('etcAtc') {
            <th pReorderableColumn pResizableColumn class="border-r" pSortableColumn="atc">
              <div class="flex items-center justify-between">
                <span class="flex items-center gap-1">
                  <p-sortIcon field="atc" /> {{ column.title }}
                </span>
                <plu-date-column-filter [fieldName]="PresentationTableFilterIds.atc" />
              </div>
            </th>
          }
          @case ('etdAtd') {
            <th pReorderableColumn pResizableColumn class="border-r" pSortableColumn="atd">
              <div class="flex items-center justify-between">
                <span class="flex items-center gap-1">
                  <p-sortIcon field="atd" /> {{ column.title }}
                </span>
                <plu-date-column-filter [fieldName]="PresentationTableFilterIds.atd" />
              </div>
            </th>
          }
          @case ('notes') {
            <th pReorderableColumn pResizableColumn class="border-r">
              <div class="flex items-center justify-between">
                {{ column.title }}
                  <p-columnFilter class="ml-auto" type="text" [field]="PresentationTableFilterIds.notes" display="menu"
                  matchMode="contains" placeholder="Notes" [showMatchModes]="false" [showAddButton]="false" [showOperator]="false" ></p-columnFilter>
              </div>
            </th>
          }
          @case ('dwt') {
            <th pReorderableColumn pResizableColumn pSortableColumn="dwt" class="border-r" >
              <div class="flex items-center justify-between">
                <div class="flex items-center justify-center gap-1">
                  <p-sortIcon field="dwt" />
                  <span>{{ column.title }}</span>
                </div>
                <plu-number-filter [field]="PresentationTableFilterIds.dwt" placeholder="Dwt" />
              </div>
            </th>
          }
          @case ('berthDelay') {
            <th pReorderableColumn pResizableColumn pSortableColumn="berthDelay" class="border-r">
              <div class="flex items-center justify-between">
                <div class="flex items-center justify-center gap-1">
                  <p-sortIcon field="berthDelay" />
                  <span>{{ column.title }}</span>
                </div>
                <plu-berth-delay-filter
                  [fieldName]="PresentationTableFilterIds.berthDelay"
                  placeholder="Delay (days/hours)"
                />
              </div>
            </th>
          }
          @case ('nextDestinationNote') {
            <th pReorderableColumn pResizableColumn class="border-r">
              <div class="flex items-center justify-between">
                {{ column.title }}
                <plu-next-destination-note-filter
                [field]="PresentationTableFilterIds.nextDestinationNote"
                [suggestions]="columnFiltersAutocompleteDataProviderService.nextDestinationsFilter.suggestions()"
                (clearFilters)="columnFiltersAutocompleteDataProviderService.nextDestinationsFilter.clearFilters()"
                (search)="columnFiltersAutocompleteDataProviderService.nextDestinationsFilter.search($event)"
                (applyFilter)="columnFiltersAutocompleteDataProviderService.nextDestinationsFilter.applyFilter($event.filter, $event.value)"
                (unApplyFilter)="columnFiltersAutocompleteDataProviderService.nextDestinationsFilter.unApplyFilter($event.filter, $event.value)"
                ></plu-next-destination-note-filter>
              </div>
            </th>
          }
        }
      }
      @if (showLastColumn) {
        <th pFrozenColumn alignFrozen="right" class="datatable-frozen-header min-w-[70px] last-frozen-column-from-right"></th>
      }
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-item let-column="columns" let-i="rowIndex">
    <tr
      [pluRowChange]="item.id"
      [attr.imonumber]="item.imoNumber"
      [pluRowAdded]="item.id"
      [pluPortCallDelayed]="item"
      class="flex min-h-18 h-fit">
      @for (column of columns; track $index) {
        @switch (column.name) {
          @case ('vesselName') {
            <td pFrozenColumn class="z-10 last-frozen-column-from-left">
              <div class="flex flex-col">
                <span>
                  {{item.vesselName}}
                </span>
                @if (showPriority) {
                  <div [pluCellChange]="['berthPriority']">
                    <plu-order
                      [activeItem]="item.berthPriority"
                      [pTooltip]="getBerthPriorityTooltip(item)"
                      tooltipPosition="top"
                      tooltipStyleClass="w-auto whitespace-nowrap max-w-fit"
                    />
                  </div>
                }
              </div>
            </td>
          }
          @case ('vesselType') {
            <td [pluCellChange]="['vesselType']">{{item.vesselType}}</td>
          }
          @case ('voyageNumber') {
            <td [pluCellChange]="['voyageNumber']">{{item.voyageNumber}}</td>
          }
          @case ('gacJobNumber') {
            <td [pluCellChange]="['gacJobNumber']">
              <a class="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                (click)="onGacJobNumberClicked($event, item)">{{item.gacJobNumber}}</a>
            </td>
          }
          @case ('vesselStatus') {
            <td [pluCellChange]="['vesselStatusId']">
              <div class="flex justify-between">
                <div class="flex items-center w-[35px]">
                  <plu-image [src]="vesselStatusIconResolver.resolve(item.vesselStatus)"></plu-image>
                </div>
                <div class="flex justify-center items-center w-full p-3 text-center">
                  {{item.vesselStatus}}
                </div>
              </div>
            </td>
          }
          @case ('terminalName') {
            <td [pluCellChange]="['terminalId']">{{item.terminalName}}</td>
          }
          @case ('berthName') {
            <td [pluCellChange]="['berthId']">{{item.berthName}}</td>
          }
          @case ('cargoTypeName') {
            <td [pluCellChange]="['cargos']">
              <div class="multiple-cargos-cell">
                @for(cargoType of item.cargoType; track $index){
                <div class="p-2">
                    {{ cargoType?.displayText}}
                </div>
                }
               </div>            
            </td>
          }
          @case ('cargoDescription') {
            <td [pluCellChange]="['cargos']">
              <div class="multiple-cargos-cell">
                @for(cargoDescription of item.cargoDescription; track $index){
                <div class="p-2">
                    {{ cargoDescription}}
                </div>
                }
             </div>         
            </td>
          }
          @case ('cargoRate') {
            <td [pluCellChange]="['cargos']">
              <div class="multiple-cargos-cell">
                @for(cargoRate of item.cargoRate; let index = $index; track index){
                <div class="p-2">
                  {{ cargoRate | number}} {{item.cargoRateUomName[index]}}
                </div>
                }
              </div>
            </td>
          }
          @case ('quantity') {
            <td [pluCellChange]="['cargos']">
              <div class="multiple-cargos-cell">
                @for(cargoQuantity of item.cargoQuantity; let index = $index; track index){
                <div class="p-2">
                  {{ cargoQuantity | number}} {{item.cargoQuantityUomName[index]}}
                </div>
                }
              </div>  
            </td>
          }
          @case ('operation') {
            <td [pluCellChange]="['operationId']">{{item.operationName}}</td>
          }
          @case ('etaAta') {
            <td [pluCellChange]="['eta', 'ata']">
              <div class="flex flex-col">
                <plu-date-column-view [expectedTime]="item.eta" [actualTime]="item.ata"
                                      expectedTimeShorterName="ETA"
                                      actualTimeShorterName="ATA"
                                      [expectedTimeButtonBgColorClass]="DatesBackgroundColor.eta"
                                      [actualTimeButtonBgColorClass]="DatesBackgroundColor.ata">
                </plu-date-column-view>
                @if (item.ataihs) {
                  <span class="text-sm flex" [pluCellChange]="['ataihs']">
                        <div class="items-center mt-2">
                          <plu-ihs-date-column-view
                            timeShorterName="ATA"
                            [buttonBgColorClass]="DatesBackgroundColor.ata"
                            [ihsDate]="item.ataihs | pluDatePipe"
                          />
                        </div>
                  </span>
                }
                @if (item.etaihs && !item.ataihs) {
                  <span class="text-sm flex" [pluCellChange]="['etaihs']">
                        <div class="flex items-center mt-2">
                          <plu-ihs-date-column-view
                          timeShorterName="ETA"
                          [buttonBgColorClass]="DatesBackgroundColor.eta"
                          [ihsDate]="item.etaihs | pluDatePipe">
                          </plu-ihs-date-column-view>
                        </div>
                  </span>
                }
              </div>
            </td>
          }
          @case ('etbAtb') {
            <td [pluCellChange]="['atb', 'etb']">
                <div class="flex flex-col">
                  <plu-date-column-view [expectedTime]="item.etb" [actualTime]="item.atb"
                  expectedTimeShorterName="ETB"
                  actualTimeShorterName="ATB"
                  [expectedTimeButtonBgColorClass]="DatesBackgroundColor.etb"
                  [actualTimeButtonBgColorClass]="DatesBackgroundColor.atb"></plu-date-column-view>
                  <span class="text-sm flex" [pluCellChange]="['atbihs']">
                      @if (item.atbihs) {
                        <div class="flex items-center mt-2">
                          <plu-ihs-date-column-view 
                          timeShorterName="ATB"
                          [buttonBgColorClass]="DatesBackgroundColor.atb"
                          [ihsDate]="item.atbihs | pluDatePipe">
                          </plu-ihs-date-column-view>
                        </div>
                      }
                  </span>
                </div>
            </td>
          }
          @case ('eccAcc') {
            <td [pluCellChange]="['acc', 'ecc']">
              <plu-date-column-view [expectedTime]="item.ecc" [actualTime]="item.acc"
                                    expectedTimeShorterName="ECC"
                                    actualTimeShorterName="ACC"
                                    [expectedTimeButtonBgColorClass]="DatesBackgroundColor.ecc"
                                    [actualTimeButtonBgColorClass]="DatesBackgroundColor.acc"></plu-date-column-view>
            </td>
          }
          @case ('etcAtc') {
            <td [pluCellChange]="['atc', 'etc']">
              <plu-date-column-view [expectedTime]="item.etc" [actualTime]="item.atc"
                                    expectedTimeShorterName="ETC"
                                    actualTimeShorterName="ATC"
                                    [expectedTimeButtonBgColorClass]="DatesBackgroundColor.etc"
                                    [actualTimeButtonBgColorClass]="DatesBackgroundColor.atc"></plu-date-column-view>
            </td>
          }
          @case ('etdAtd') {
            <td [pluCellChange]="['atd', 'etd']">
              <div class="flex flex-col">
                <plu-date-column-view [expectedTime]="item.etd" [actualTime]="item.atd"
                expectedTimeShorterName="ETD"
                actualTimeShorterName="ATD"
                [expectedTimeButtonBgColorClass]="DatesBackgroundColor.etd"
                [actualTimeButtonBgColorClass]="DatesBackgroundColor.atd"></plu-date-column-view>
                <span class="text-sm flex" [pluCellChange]="['atdihs']">
                    @if (item.atdihs) {
                      <div class="flex items-center mt-2">
                        <plu-ihs-date-column-view 
                        timeShorterName="ATD"
                        [buttonBgColorClass]="DatesBackgroundColor.atd"
                        [ihsDate]="item.atdihs | pluDatePipe">
                        </plu-ihs-date-column-view>
                      </div>
                    }
                </span>
              </div>
            </td>
          }
          @case ('notes') {
            <td [pluCellChange]="['notes']" (click)="openNoteDetailDialog($event, item.notes)" class="cursor-pointer">
              <div class="w-44 truncate">
                {{item.notes}}
              </div>
            </td>
          }
          @case ('dwt') {
            <td [pluCellChange]="['dwt']">{{item.dwt | pluDecimalPipe}}</td>
          }
          @case ('berthDelay') {
            <td>{{item.berthDelay | pluHoursToDaysAndHoursPipe}}</td>
          }
          @case ('nextDestinationNote') {
            <td [pluCellChange]="['nextDestinationNote']">
              <div class="w-44 truncate">
                {{item.nextDestinationNote}}
              </div>
            </td>
          }
        }
      }
      @if (showLastColumn && showPortCallOnMapButton) {
        <td pFrozenColumn alignFrozen="right" class="z-10 min-w-[70px] last-frozen-column-from-right">
            <p-button icon="pi pi-eye"
                      [rounded]="true" [text]="true"
                      pTooltip="View on map"
                      tooltipPosition="bottom"
                      (onClick)="showVesselOnMap(item.id)" 
            />
        </td>
      }
    </tr>
  </ng-template>
</p-table>
