import { Injectable } from '@angular/core';
import { VesselStatus } from '@port-line-up/shared/data-access';
import { VesselsReport } from '../models/vessels-report';
import { PortCallReport } from '../models/port-call-report';

@Injectable()
export class VesselReportService {

    fillVesselReportBasedOnVesselStatus(portCall: PortCallReport, allongSideReports: VesselsReport, atAnchorReports: VesselsReport, enRouteReports: VesselsReport): void {
        if (!portCall.vesselStatusId) {
            return;
        }

        if ([VesselStatus.Berthed.toString(), VesselStatus.Sailed.toString(),
            VesselStatus.OperationsInProgress.toString(), VesselStatus.OperationCompleted.toString()].includes(portCall.vesselStatusId)) {
            this.handleVesselCountBasedOnVesselDWT(portCall, allongSideReports);
        }

        if (portCall.vesselStatusId === VesselStatus.Arrived.toString()) {
            this.handleVesselCountBasedOnVesselDWT(portCall, atAnchorReports);
        }

        if (portCall.vesselStatusId === VesselStatus.Expected.toString()) {
            this.handleVesselCountBasedOnVesselDWT(portCall, enRouteReports);
        }
    }

    private handleVesselCountBasedOnVesselDWT(portCall: PortCallReport, vesselReport: VesselsReport): void {
       if (portCall.dwt) {
           if (portCall.dwt > 95000) {
               vesselReport.capes = vesselReport.capes + 1;
           } else if (portCall.dwt >= 56000 && portCall.dwt <= 95000) {
               vesselReport.postPanamaxes = vesselReport.postPanamaxes + 1;
           } else if (portCall.dwt < 56000) {
               vesselReport.handyMax = vesselReport.handyMax + 1;
           }
       } else {
           vesselReport.unknown = vesselReport.unknown + 1;
       }
    }
}
