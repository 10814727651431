import { inject, Injectable } from '@angular/core';
import { VesselStatusStore } from '../+state/vessel-status.store';
import { LookupItem } from '@port-line-up/shared/util';
import { VesselStatus } from '../enums';
import { VesselStatusService } from './vessel-status.service';

@Injectable({ providedIn: 'root' })
export class VesselStatusFacadeService {

  private vesselStatusStore = inject(VesselStatusStore)

  constructor(private vesselStatusHttpService: VesselStatusService) {
    this.vesselStatusStore.loadVesselStatuses(this.vesselStatusHttpService.getAll())
  }

  getAll(): LookupItem[] {
    return this.vesselStatusStore.entities();
  }

  getById(vesselStatusId: VesselStatus): LookupItem | undefined {
    return this.vesselStatusStore.entityMap()[vesselStatusId]
  }

  getDisplayTextById(vesselStatusId: VesselStatus): string {
    return this.vesselStatusStore.entityMap()[vesselStatusId].displayText;
  }
}
