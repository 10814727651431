import { Provider, Type } from '@angular/core';
import { GridColumnService } from './grid-column.service';
import { GridColumnLocalStorageService } from './grid-column-local-storage.service';
import { GridColumnFactory } from './grid-column-factory';
import { GRID_COLUMN_STORAGE_KEY } from './storage-key.token';

export const GRID_COLUMN_PROVIDER = (factory: Type<GridColumnFactory>, key?: string, useExistingFactory = false) => {
    const response = [GridColumnService, GridColumnLocalStorageService, { provide: GRID_COLUMN_STORAGE_KEY, useValue: key }] as Provider[];

    if (useExistingFactory) {
        response.push({ provide: GridColumnFactory, useExisting: factory });
    } else {
        response.push({ provide: GridColumnFactory, useClass: factory });
    }

    return response;
};
