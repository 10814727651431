export class VesselDelayReport {
    terminalName = '';
    delayForVlAtAnchorSum = 0;
    delayForVlAtAnchorCount = 0;

    delayForVlArrivingIn0To7DaysSum = 0;
    delayForVlArrivingIn0To7DaysCount = 0;

    delayForVlArrivingIn7To14DaysSum = 0;
    delayForVlArrivingIn7To14DaysCount = 0;

    delayForVlArrivingIn14To21DaysSum = 0;
    delayForVlArrivingIn14To21DaysCount = 0;

    delayForVlArrivingIn21PlusDaysSum = 0;
    delayForVlArrivingIn21PlusDaysCount = 0;

    constructor(terminalName: string) {
        this.terminalName = terminalName;
    }

    averageDelayForVlAtAnchor(): number {
        return this.delayForVlAtAnchorCount > 0 ? this.delayForVlAtAnchorSum / this.delayForVlAtAnchorCount : 0;
    }

    averageDaysDelayForVlArrivingIn0To7Days(): number {
        return this.delayForVlArrivingIn0To7DaysCount > 0 ? this.delayForVlArrivingIn0To7DaysSum / this.delayForVlArrivingIn0To7DaysCount : 0;
    }

    averageDaysDelayForVlArrivingIn7To14Days(): number {
        return this.delayForVlArrivingIn7To14DaysCount > 0 ? this.delayForVlArrivingIn7To14DaysSum / this.delayForVlArrivingIn7To14DaysCount : 0;
    }

    averageDaysDelayForVlArrivingIn14To21Days(): number {
        return this.delayForVlArrivingIn14To21DaysSum > 0 ? this.delayForVlArrivingIn14To21DaysCount / this.delayForVlArrivingIn14To21DaysSum : 0;
    }

    averageDaysDelayForVlArrivingIn21PlusDays(): number {
        return this.delayForVlArrivingIn21PlusDaysCount > 0 ? this.delayForVlArrivingIn21PlusDaysSum / this.delayForVlArrivingIn21PlusDaysCount : 0;
    }
}
