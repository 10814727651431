<p-columnFilter pluShowCloseButton (pluOnClear)="clearFilters.emit()" [field]="field" [matchMode]="CustomMatchMode.ColumnFilterItemIn" display="menu"
    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
        <p-autoComplete [ngModel]="value" [ngModelOptions]="{standalone: true}" pluAutoCompleteColumnFilterCommonSettings [suggestions]="suggestions"
            (completeMethod)="search.emit($event.query)" (onSelect)="applyFilter.emit({filter, value: $event.value})"
            (onUnselect)="unApplyFilter.emit({filter, value: $event.value})" placeholder="Next Destination" filterPlaceholder="Search for Next Destination"
            field="filterLabel">
            <ng-template let-item pTemplate="item">
                <span>{{item.filterLabel}}</span>
            </ng-template>
        </p-autoComplete>
    </ng-template>
</p-columnFilter>