import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Subject, takeUntil, withLatestFrom } from 'rxjs';
import { ApplyChangesStatus, DataUpdateState, DataUpdateStore } from '../../services';

@Directive({ selector: '[pluRowAdded]', standalone: true })
export class RowAddedDirective implements AfterViewInit, OnDestroy {
    @Input('pluRowAdded') id!: string;

    private destroy$ = new Subject<void>();
    constructor(private dataUpdateStore: DataUpdateStore<DataUpdateState>, private el: ElementRef, private renderer: Renderer2) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewInit(): void {
        this.dataUpdateStore.applyChanges$
            .pipe(withLatestFrom(this.dataUpdateStore.addedPortCalls$, this.dataUpdateStore.changedPortCalls$), takeUntil(this.destroy$))
            .subscribe(([apply, addedPortCalls, changedPortCalls]) => {
                if (apply.status === ApplyChangesStatus.NothingToApply || changedPortCalls.has(this.id)) {
                    return;
                }

                if (addedPortCalls.has(this.id)) {
                    this.setClass();
                } else {
                    this.removeClass();
                }
            });
    }

    private setClass(): void {
        this.renderer.addClass(this.el.nativeElement, 'datatable-presentation-row-added');
        this.renderer.addClass(this.el.nativeElement, 'ease-in-out');
        this.renderer.addClass(this.el.nativeElement, 'duration-300');
    }

    private removeClass(): void {
        this.renderer.removeClass(this.el.nativeElement, 'datatable-presentation-row-added');
        this.renderer.addClass(this.el.nativeElement, 'inherit');
    }
}
