import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { GridColumnLocalStorageService } from "./grid-column-local-storage.service";
import { GridColumnFactory } from './grid-column-factory';
import { Column } from '../../models';
import { HandleColumnsWidthsService } from './handle-columns-widths.service';

@Injectable()
export class GridColumnService {
    private gridColumnsSubject = new BehaviorSubject<Column[]>([]);
    gridColumns$: Observable<Column[]> = this.gridColumnsSubject.asObservable();

    private readTableWidthSubject = new BehaviorSubject<void>(undefined);
    readTableWidth$: Observable<void> = this.readTableWidthSubject.asObservable();

    private tableWidth = 0;

    constructor(private gridColumnLocalStorageService: GridColumnLocalStorageService,
                private gridColumnsFactory: GridColumnFactory,
                private expandColumnsWidthsService: HandleColumnsWidthsService) {
        this.gridColumnsSubject.next(this.gridColumnLocalStorageService.read());
    }

    resize(columns: Column[], columnIndex: number, delta: number): void {
        columns[columnIndex].width = (+columns[columnIndex].width + delta).toString();
        this.saveColumns(columns);
    }

    reorder(columns: Column[]): void {
       this.saveColumns(columns);
    }

    resetColumns(): void {
        const columns = this.gridColumnsFactory.create();
        this.saveColumns(columns);
    }

    saveColumns(columns: Column[]): void {
        if (this.tableWidth) {
            this.expandColumnsWidthsService.handleColumnsWidthsService(columns, this.tableWidth);
        }
        this.gridColumnLocalStorageService.write(columns);
        this.gridColumnsSubject.next(columns);
    }


    getColumns(): Column[] {
        return this.gridColumnLocalStorageService.read()
    }

    readTableWidth(): void {
        this.readTableWidthSubject.next();
    }

    setTableWidth(tableWidth: number): void {
        this.tableWidth = tableWidth;
    }
}

