import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluHoursToDaysAndHoursPipe',
  standalone: true,
})

export class PluHoursToDaysAndHoursPipe implements PipeTransform {
  transform(berthDelayInDays: number | null): string | null {
    if (berthDelayInDays === null) return null;

    const hoursPerDay = 24;

    if (berthDelayInDays < hoursPerDay) {
      return `${berthDelayInDays} h`;
    }

    const days = Math.floor(berthDelayInDays / hoursPerDay);
    const remainingHours = berthDelayInDays % hoursPerDay;

    return remainingHours === 0 ? `${days} d` : `${days} d ${remainingHours} h`;
  }
} 