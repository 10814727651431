import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { sharedViewResolver } from '@port-line-up/port-line-up/feature-shared-view';

export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'authentication' },
    { path: '', canActivate: [MsalGuard], loadChildren: () => import('@port-line-up/port-line-up/feature-shell').then((m) => m.featureShellRoutes) },
    { path: 'shared/:id/:token', loadComponent: () => import('@port-line-up/port-line-up/feature-shared-view').then((m) =>m.PortLineUpFeatureSharedViewComponent), resolve: {data: sharedViewResolver}},
    { path: 'authentication', loadChildren: () => import('@port-line-up/port-line-up/feature-authentication').then(m => m.authenticationRoutes) }
];
