export class VesselsReport {
    capes = 0;
    postPanamaxes = 0;
    handyMax = 0;
    unknown = 0;

    totalNumberOfVessels(): number {
        return this.capes + this.postPanamaxes + this.handyMax + this.unknown;
    }
}
