import { Injectable } from '@angular/core';
import { Error } from '../models/error';

@Injectable({providedIn: 'root'})
export class ErrorMessageService {
    handleErrorMessage(error: Error): string {
        if (error?.Items) {
            const stringBuilder: string[] = [];
            error.Items.forEach((errorItem) => stringBuilder.push(errorItem.Message));
            return stringBuilder.join('\n');
        }

        if (error?.Message) {
            return error.Message;
        }

        return 'There was an error!';
    }
}
