import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'plu-image',
    standalone: true,
    imports: [CommonModule, NgOptimizedImage],
    templateUrl: './image.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
    @Input() set src(src: string) {
        if (src) {
            this._src = `assets/images/${src}`;
            this.hasImage = true;
        } else {
            this.hasImage = false;
        }
    }
    get src() {
        return this._src;
    }
    @Input() width?: number;
    @Input() height?: number;
    @Input() fill?: boolean;

    hasImage = false;
    fallbackImageUrl = 'assets/images/no-image.png';

    private _src = '';

    fallbackImage(): void {
        this.hasImage = false;
    }
}
