import { HttpParams } from '@angular/common/http';
import { ExpressionOperator, FilterLogicalOperator } from '../enums';

export class FilterItem {
    constructor(
        public readonly propertyName: string,
        public readonly value: string,
        public readonly expressionOperator: ExpressionOperator = ExpressionOperator.Equals,
        public readonly logicalOperator: FilterLogicalOperator = FilterLogicalOperator.AND
    ) {}

    public static create(
        propertyName: string,
        value: string,
        expressionOperator = ExpressionOperator.Equals,
        logicalOperator = FilterLogicalOperator.AND
    ): FilterItem {
        return new FilterItem(propertyName, value, expressionOperator, logicalOperator);
    }

    public getHttpParams(index: number): HttpParams {
        return new HttpParams()
            .set(`filterItems[${index}].propertyName`, this.propertyName)
            .set(`filterItems[${index}].value`, this.value)
            .set(`filterItems[${index}].expressionOperator`, this.expressionOperator)
            .set(`filterItems[${index}].logicalOperator`, this.logicalOperator);
    }

    public updateHttpParams(httpParams: HttpParams, index: number): HttpParams {
        return httpParams
            .set(`filterItems[${index}].propertyName`, this.propertyName)
            .set(`filterItems[${index}].value`, this.value)
            .set(`filterItems[${index}].expressionOperator`, this.expressionOperator)
            .set(`filterItems[${index}].logicalOperator`, this.logicalOperator);
    }
}
