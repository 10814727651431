import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Subject, first, takeUntil } from 'rxjs';
import { PortCallPresentation } from '../../models';
import { PortCallDelayedService } from './port-call-delayed.service';

@Directive({ selector: '[pluPortCallDelayed]', standalone: true })
export class PortCallDelayedDirective implements AfterViewInit, OnDestroy {
    @Input('pluPortCallDelayed') set portCall(portCall: PortCallPresentation) {
        this._portCall = portCall;
        this.portCallDelayedService.areDelayedVesselsMarked$.pipe(first()).subscribe((areDelayedVesselsMarked) => {
            this.markDelayedVessel(areDelayedVesselsMarked);
        });
    }

    private _portCall!: PortCallPresentation;

    private destroy$ = new Subject<void>();
    constructor(private portCallDelayedService: PortCallDelayedService, private el: ElementRef, private renderer: Renderer2) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewInit(): void {
        this.portCallDelayedService.areDelayedVesselsMarked$.pipe(takeUntil(this.destroy$)).subscribe((areDelayedVesselsMarked) => {
            this.markDelayedVessel(areDelayedVesselsMarked);
        });
    }

    private markDelayedVessel(shouldMark: boolean): void {
        if (!shouldMark) {
            this.removeClass();
            return;
        }

        if (this.isVesselDelayed(this._portCall.ata, this._portCall.ataihs, this._portCall.eta, this._portCall.etaihs)) {
            this.setClass();
        } else {
            this.removeClass();
        }
    }

    isVesselDelayed(ata: Date | null, ataIhs: Date | null, eta: Date | null, etaIhs: Date | null): boolean {

        if (ata || ataIhs) {
            return false;
        }

        const now: number = new Date().getTime();

        if (eta) {
            return now > new Date(eta).getTime()
        }

        if (etaIhs) {
            return now > new Date(etaIhs).getTime()
        }

        return false;
    }

    private setClass(): void {
        this.renderer.addClass(this.el.nativeElement, 'datatable-row-delayed');
        this.renderer.addClass(this.el.nativeElement, 'ease-in-out');
        this.renderer.addClass(this.el.nativeElement, 'duration-300');
    }

    private removeClass(): void {
        this.renderer.removeClass(this.el.nativeElement, 'datatable-row-delayed');
        this.renderer.addClass(this.el.nativeElement, 'inherit');
    }
}
