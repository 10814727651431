import { FilterMetadata } from 'primeng/api';

export class GridFilter {
    key: string;
    filters?: GridFilterItem;

    constructor(key: string, filters: GridFilterItem) {
        this.key = key;
        this.filters = filters;
    }
}

export type GridFilterItem =  { [s: string]: FilterMetadata[] | FilterMetadata };

