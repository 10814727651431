import { Directive } from '@angular/core';
import { ColumnFilter } from 'primeng/table';

@Directive({ selector: '[pluShowCloseButton]', standalone: true })
export class ShowCloseButtonDirective {
    constructor(private columnFilter: ColumnFilter) {
        this.overrideApplyButtonLabel();
        this.overrideApplyFilterMethod(columnFilter);
    }

    private overrideApplyButtonLabel(): void {
        Object.defineProperty(this.columnFilter, 'applyButtonLabel', {
            value: 'Close',
        });
    }

    private overrideApplyFilterMethod(columnFilter: ColumnFilter): void {
        const handler = {
            apply: function (_target: any, _thisArg: any, _argumentsList: any) {
                return columnFilter.hide();
            },
        };
        const proxy = new Proxy(columnFilter.applyFilter, handler);
        columnFilter.applyFilter = proxy;
    }
}
