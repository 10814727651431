import { ChangeDetectionStrategy, EventEmitter, Component, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PluDatePipe } from '@port-line-up/shared/ui/pipes';
import { ButtonModule } from "primeng/button";

@Component({
    selector: 'plu-date-column-view',
    standalone: true,
    imports: [CommonModule, PluDatePipe, ButtonModule],
    templateUrl: './date-column-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateColumnViewComponent {
    @Input() actualTime!: Date | null;
    @Input() expectedTime!: Date | null;
    @Input() actualTimeButtonBgColorClass!: string;
    @Input() expectedTimeButtonBgColorClass!: string;
    @Input() actualTimeShorterName!: string;
    @Input() expectedTimeShorterName!: string;
    @Input() isClickable = false;
    @Output() switchToExpectedTime = new EventEmitter<void>();
    @Output() switchToActualTime = new EventEmitter<void>();

    onSwitchToExpectedTime(event: Event): void {
        this.switchToExpectedTime.emit();
        event.stopPropagation();
    }

    onSwitchToActualTime(event: Event): void {
        this.switchToActualTime.emit();
        event.stopPropagation();
    }
}
