<div class="py-6">
  <div class="u-body-normal">
    <form [formGroup]="form">
      <textarea pInputTextarea [autoResize]="true" formControlName="notes" class="w-full h-[300px] overflow-y-scroll"></textarea>
    </form>
  </div>
  <div class="mt-6 flex justify-end" *ngIf="isEditable">
    <p-button label="Apply" styleClass="p-button" (onClick)="apply()"></p-button>
    <p-button label="Cancel" [outlined]="true" styleClass="p-button ml-5" (onClick)="close()"></p-button>
  </div>

  <div class="mt-6 flex justify-end" *ngIf="!isEditable">
    <p-button label="Cancel" [outlined]="true" styleClass="p-button ml-5" (onClick)="close()"></p-button>
  </div>
</div>
