import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastModule } from "primeng/toast";

@Component({
  standalone: true,
    imports: [RouterModule, ToastModule],
  selector: 'plu-root',
  template: `<router-outlet></router-outlet>
             <p-toast></p-toast>`,
})
export class AppComponent {
}
