import { Injectable } from '@angular/core';
import { DynamicColumnFacadeService } from '@port-line-up/shared/feature-user-specific-rules';
import { Column, GridColumnFactory } from '@port-line-up/shared/util';

@Injectable({ providedIn: 'root' })
export class GridColumnsFactoryService implements GridColumnFactory {
    constructor(private dynamicColumnFacadeService: DynamicColumnFacadeService) {}

    create(): Column[] {
        return this.dynamicColumnFacadeService.getGridColumnsForPresentationView();
    }
}
