import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { Port, PortCall, SharedViewService } from '@port-line-up/shared/data-access';
import { Observable, switchMap, withLatestFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ActionPendingInterceptor, GridFilterItem } from '@port-line-up/shared/util';
import { DataUpdateState, DataUpdateStore, dataUpdateInitialState } from '@port-line-up/shared/feature-presentation-table';
import { Asset } from './shared-view/models/assets';

export interface SharedViewState extends DataUpdateState {
    id: string;
    token: string;
    filters: GridFilterItem;
    portCalls: PortCall[];
    sailedPortCalls: PortCall[];
    areSailedPortCallsLoaded: boolean;
    port: Port;
    assets: Asset[];
    showReport: boolean;
}

const initialState = (): SharedViewState => ({
    ...{
        id: '',
        token: '',
        filters: {},
        portCalls: [],
        sailedPortCalls: [],
        areSailedPortCallsLoaded: false,
        port: new Port(),
        assets: [],
        showReport: false
    },
    ...dataUpdateInitialState(),
});

@Injectable()
export class SharedViewStore extends DataUpdateStore<SharedViewState> {
    constructor(
        private sharedViewService: SharedViewService,
        actionPendingInterceptor: ActionPendingInterceptor
    ) {
        super(initialState());
        this.sharedViewService = actionPendingInterceptor.createInterceptor(this.sharedViewService);
    }

    readonly portCalls$: Observable<PortCall[]> = this.select((state) => state.portCalls);
    readonly sailedPortCalls$: Observable<PortCall[]> = this.select((state) => state.sailedPortCalls);
    readonly areSailedPortCallsLoaded$: Observable<boolean> = this.select((state) => state.areSailedPortCallsLoaded);

    readonly port$: Observable<Port> = this.select((state) => state.port);
    readonly id$: Observable<string> = this.select((state) => state.id);
    readonly token$: Observable<string> = this.select((state) => state.token);
    readonly filters$: Observable<GridFilterItem> = this.select((state) => state.filters);

    setId = this.updater((state, id: string) => ({
        ...state,
        id,
    }));

    setToken = this.updater((state, token: string) => ({
        ...state,
        token,
    }));

    setFilters = this.updater((state, filters: GridFilterItem) => ({
        ...state,
        filters,
    }));

    getPortCallsSuccess = this.updater((state, portCalls: PortCall[]) => ({
        ...state,
        portCalls: portCalls,
    }));

    getSailedPortCallsSuccess = this.updater((state, portCalls: PortCall[]) => ({
        ...state,
        sailedPortCalls: portCalls,
        areSailedPortCallsLoaded: true,
    }));

    getPortSuccess = this.updater((state, port: Port) => ({
        ...state,
        port: port,
    }));

    getAssetsSuccess = this.updater((state, assets: Asset[]) => ({
        ...state,
        assets: assets
    }));

    getShowReportSuccess = this.updater((state, showReport: boolean) => ({
        ...state,
        showReport: showReport
    }));

    getPortCalls = this.effect(($) =>
        $.pipe(
            withLatestFrom(this.id$, this.token$),
            switchMap(([_, id, token]) => {
                return this.sharedViewService.getActive(id, token).pipe(
                    tapResponse(
                        (response) => {
                            this.getPortCallsSuccess(response.portCallsDto.items);
                            this.getPortSuccess(response.port);
                            this.setLastUpdate();
                        },
                        (error: HttpErrorResponse) => {
                            this.failure(error);
                        }
                    )
                );
            })
        )
    );

    getSailedPortCalls = this.effect(($) =>
        $.pipe(
            withLatestFrom(this.id$, this.token$),
            switchMap(([_, id, token]) => {
                return this.sharedViewService.getSailed(id, token).pipe(
                    tapResponse(
                        (response) => {
                            this.getSailedPortCallsSuccess(response.portCallsDto.items);
                            this.setLastUpdate();
                        },
                        (error: HttpErrorResponse) => {
                            this.failure(error);
                        }
                    )
                );
            })
        )
    );
}
