export class PresentationTableFilterIds {
    static readonly vessel: string  = 'vessel';
    static readonly vesselStatusId: string  = 'vesselStatusId';
    static readonly vesselName: string  = 'vesselName';
    static readonly vesselType: string = 'vesselType';
    static readonly terminalId: string  = 'terminalId';
    static readonly terminal: string  = 'terminal';
    static readonly berthId: string  = 'berthId';
    static readonly berth: string  = 'berth';
    static readonly cargoType: string = 'cargoType';
    static readonly ata: string  = 'ata';
    static readonly ataihs: string  = 'ataihs';
    static readonly eta: string  = 'eta';
    static readonly etaihs: string  = 'etaihs';
    static readonly atb: string  = 'atb';
    static readonly etb: string  = 'etb';
    static readonly acc: string  = 'acc';
    static readonly ecc: string  = 'ecc';
    static readonly atc: string  = 'atc';
    static readonly etc: string  = 'etc';
    static readonly atd: string  = 'atd';
    static readonly etd: string  = 'etd';
    static readonly party: string = 'party';
    static readonly voyageNumber: string  = 'voyageNumber';
    static readonly cargoDescription: string  = 'cargoDescription';
    static readonly cargoRate: string = 'cargoRate';
    static readonly cargoUom: string = 'cargoUom'
    static readonly notes: string  = 'notes';
    static readonly operation: string  = 'operation';
    static readonly operationId: string  = 'operationId';
    static readonly cargoQuantity: string = 'cargoQuantity';
    static readonly cargoQuantityUom: string = 'cargoQuantityUom';
    static readonly portName: string  = 'portName';
    static readonly dwt: string  = 'dwt';
    static readonly berthDelay: string  = 'berthDelay';
    static readonly nextDestinationNote: string = 'nextDestinationNote';
}

