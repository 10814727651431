import { Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { TableModule } from "primeng/table";
import { CalendarModule } from "primeng/calendar";
import { ApplyFilterOnFilterMetadataChangeDirective, DateMatchModes, CustomMatchMode, ShowCloseButtonDirective } from '@port-line-up/shared/util';

@Component({
    selector: 'plu-date-column-filter',
    templateUrl: 'date-column-filter.component.html',
    imports: [CommonModule, FormsModule, TableModule, CalendarModule, ShowCloseButtonDirective, ApplyFilterOnFilterMetadataChangeDirective, CalendarModule],
    standalone: true
})
export class DateColumnFilterComponent {
    @Input() fieldName!: string;
    DateMatchModes = DateMatchModes;

    isEmptyDateMatchMode(matchMode: string): boolean {
        return matchMode === CustomMatchMode.EmptyDate;
    }
}
