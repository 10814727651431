import { Directive, HostListener } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { PresentationTableSortService } from '../services';

@Directive({ selector: '[pluTablePresentationSort]',standalone: true })
export class TablePresentationSortDirective {
    constructor(private sortService: PresentationTableSortService) {}

    @HostListener('sortFunction', ['$event'])
    customSort(event: SortEvent): void {
        this.sortService.sort(event);
    }
}
