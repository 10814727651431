<p-columnFilter pluShowCloseButton [field]="field" [matchMode]="CustomMatchMode.ColumnFilterItemIn" display="menu" [showMatchModes]="false" [showOperator]="false"
[showAddButton]="false">
<ng-template pTemplate="filter" let-value let-filter="filterCallback">
  <p-multiSelect [ngModel]="value" [ngModelOptions]="{standalone: true}" [options]="options"
    placeholder="Any" (onChange)="filter($event.value)" optionLabel="filterLabel" dataKey="value.id">
    <ng-template let-option pTemplate="item">
      <div class="font-medium">{{option.filterLabel}}</div>
    </ng-template>
  </p-multiSelect>
</ng-template>
</p-columnFilter>