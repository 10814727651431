import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StorageService {
    setItem(key: string, value: string): Observable<void> {
        return of(localStorage.setItem(key, value));
    }

    getItem(key: string): Observable<string | null> {
        return of(localStorage.getItem(key));
    }

    removeItem(key: string): Observable<void> {
        return of(localStorage.removeItem(key));
    }
}
