<ul class="flex p-0">
  <li *ngFor="let item of item?.getAllPreviousItems()" class="list-none whitespace-nowrap">
    <a [routerLink]="item?.routerLink" class="previous-item"
       [queryParamsHandling]="item.navigationExtras?.queryParamsHandling"
       (click)="onItemClick(item)">
      {{item.label}}
    </a>
    /&nbsp;
  </li>
  <li class="list-none whitespace-nowrap" *ngIf="item">
    <a *ngIf="item.url" [href]="item.url"
       class="item cursor-pointer" (click)="onItemClick(item!)">
      <i class="pi pi-check"></i>
      {{item.label}}
    </a>
    <a *ngIf="!item.url" [routerLink]="item.routerLink" [queryParams]="item.navigationExtras?.queryParams" [fragment]="item.navigationExtras?.fragment"
       class="item cursor-text" (click)="onItemClick(item!)">
      {{item.label}}
    </a>
  </li>
</ul>
