import { Injectable } from '@angular/core';
import { AppConfig, LocalStorageKeys } from '../models';
import { StorageService } from './storage.service';
import { Observable, concatMap, forkJoin, map, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalStorageVersionHandlerService {
    private readonly localStorageVersionKey = LocalStorageKeys.LocalStorageVersionKey;

    constructor(private appConfig: AppConfig, private storageService: StorageService) {}

    handle(): Observable<boolean> {
        return this.storageService.getItem(this.localStorageVersionKey).pipe(
            concatMap((value) => {
                if (!value || value !== this.appConfig.localStorageVersion) {
                    return this.clearStorage();
                }
                return of(true);
            }),
            concatMap(() => this.storageService.setItem(this.localStorageVersionKey, this.appConfig.localStorageVersion)),
            map(() => true)
        );
    }

    private clearStorage(): Observable<boolean> {
        return forkJoin([
            this.storageService.removeItem(LocalStorageKeys.FiltersEdit),
            this.storageService.removeItem(LocalStorageKeys.FiltersFleet),
            this.storageService.removeItem(LocalStorageKeys.FiltersOngoing),
            this.storageService.removeItem(LocalStorageKeys.ColumnsEdit),
            this.storageService.removeItem(LocalStorageKeys.ColumnsFleet),
            this.storageService.removeItem(LocalStorageKeys.ColumnsOngoing)
        ]).pipe(map(() => true));
    }
}
