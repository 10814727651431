import { AfterViewInit, Directive, EventEmitter, Output } from '@angular/core';
import { TabView } from 'primeng/tabview';

@Directive({ selector: '[pluTabNavContainerHeight]', standalone: true })
export class TabNavContainerHeightDirective implements AfterViewInit {
    // eslint-disable-next-line @angular-eslint/no-output-rename
    @Output('pluTabNavContainerHeight') height = new EventEmitter<number>();
    constructor(private tabView: TabView) { }

    ngAfterViewInit(): void {
        const tabViewContainer = Array.from(this.tabView.el.nativeElement.getElementsByClassName('p-tabview-nav-container'))?.[0];
        this.height.emit(tabViewContainer ? (tabViewContainer as HTMLDivElement).offsetHeight : 0);
    }
}