<div class="flex py-4 justify-between w-full">
  <div class="w-[49%]">
    <p>Available Columns</p>
      <div class="border overflow-y-auto h-96 drop-column px-2 py-1" pDroppable (onDrop)="dropToAvailableColumnsContainer()">
        <ul>
          <li *ngFor="let column of availableColumns"
              class="flex items-center cursor-move p-1 grid-settings-draggable-element rounded mt-1"
              pDraggable (onDragStart)="dragStart(column, DraggedColumnFrom.AvailableContainer)" (onDragEnd)="dragEnd()">
            <i class="pi pi-align-justify"></i>
            <span class="ml-2">{{column.title}}</span>
          </li>
        </ul>
      </div>
  </div>
  <div class="w-[49%]">
    <p>Visible Columns</p>
    <div class="flex flex-col border overflow-y-auto h-96 drop-column" >
      <ul>
        <div *ngFor="let column of columnsWithDisabledReorder; let i = index" class="px-1 pb-1" [ngClass]="{'pt-1': i === 0}">
          <li class="flex items-center cursor-not-allowed p-1 grid-settings-draggable-element rounded">
            <i class="pi pi-align-justify"></i>
            <span class="ml-2">{{column.title}}</span>
          </li>
        </div>
        <div *ngFor="let column of visibleColumns; let i = index" class="px-1 pb-1"
             pDraggable (onDragStart)="dragStart(column, DraggedColumnFrom.VisibleContainer)"
             (onDragEnd)="dragEnd()"
             pDroppable (onDrop)="dropToVisibleColumnsContainer(i)">
          <li class="flex items-center cursor-move p-1 grid-settings-draggable-element rounded">
            <i class="pi pi-align-justify"></i>
            <span class="ml-2">{{column.title}}</span>
          </li>
        </div>
      </ul>
      <div class="flex flex-1" pDroppable (onDrop)="dropToVisibleColumnsContainer(visibleColumns.length)"></div>
    </div>
  </div>
</div>
<div class="flex justify-end py-4">
  <p-button label="Apply" styleClass="p-button" (onClick)="apply()"></p-button>
  <p-button label="Cancel" [outlined]="true" styleClass="p-button ml-5" (onClick)="close()"></p-button>
</div>
