import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

@Component({
    selector: 'plu-order',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderComponent {
    activeItem = input.required<number | null>();
    numberOfItems = input(5);
    items = computed(() => Array.from({ length: this.numberOfItems() }, (_, i) => i + 1).reverse());
}
