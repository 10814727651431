import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';

@Component({
    selector: 'plu-table-settings-menu',
    templateUrl: 'table-settings-menu.component.html',
    imports: [CommonModule, ButtonModule, MenuModule],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableSettingsMenuComponent {
    @Input({required: true})  tableSettingsMenuItems: MenuItem[] = [];
}
