import { Injectable } from '@angular/core';
import { DynamicColumnResolver } from '../dynamic-column-resolver';
import { Column } from '@port-line-up/shared/util';
import { UserStateService } from '@port-line-up/shared/data-access';
import { first } from 'rxjs';
import { PresentationViewGacUserResolver } from './resolvers/gac-user.resolver';
import { PresentationViewAdminResolver } from './resolvers/admin.resolver';
import { PresentationViewDefaultResolver } from './resolvers/default.resolver';

@Injectable({ providedIn: 'root' })
export class PresentationViewColumnResolver {
    private resolvers: DynamicColumnResolver[] = [];
    constructor(userStateService: UserStateService) {
        userStateService.user$.pipe(first()).subscribe((user) => {
            if (!user) {
                return;
            }

            this.resolvers = [new PresentationViewGacUserResolver(user), new PresentationViewAdminResolver(user), new PresentationViewDefaultResolver()];
        });
    }

    resolve(): Column[] {
        return (this.resolvers.find((r) => r.canResolve()) as DynamicColumnResolver).resolve();
    }
}
