import { HttpParams } from '@angular/common/http';

export class PaginationItem {
    constructor(public readonly pageIndex: number, public readonly pageSize: number) {}

    public static create(pageIndex: number, pageSize: number): PaginationItem {
        return new PaginationItem(pageIndex, pageSize);
    }

    public getHttpParams(): HttpParams {
        return new HttpParams().set(`pageIndex`, this.pageIndex).set(`pageSize`, this.pageSize);
    }

    public updateHttpParams(httpParams: HttpParams): HttpParams {
        return httpParams.set(`pageIndex`, this.pageIndex).set(`pageSize`, this.pageSize);
    }
}
