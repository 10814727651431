import { Lookup } from '../../../../util/src/lib/models/lookup-item';

export class Port {
    id = '';
    name = '';
    countryName? = '';
    countryId? = '';
    isSupported = false;
    unloCode = '';
    latitude = 0;
    longitude = 0;
    generalRemarks = '';
}

export class PortLookup implements Lookup {
    constructor(public readonly id: string, public readonly displayText: string, public readonly countryName: string) {}

    static create(id: string, displayText: string, countryName: string): PortLookup {
        return new PortLookup(id, displayText, countryName);
    }
}
