import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { ColumnFilterItem, CustomMatchMode } from '@port-line-up/shared/util';

@Component({
    selector: 'plu-vessel-status-filter',
    standalone: true,
    imports: [TableModule, MultiSelectModule, FormsModule],
    templateUrl: './vessel-status-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VesselStatusFilterComponent {
    @Input({ required: true }) field!: string;
    @Input() options: ColumnFilterItem<any, any>[] = [];

    CustomMatchMode = CustomMatchMode;
}
