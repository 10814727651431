import { Injectable } from '@angular/core';
import { VesselStatus } from '@port-line-up/shared/data-access';
import { TimeDifferenceService } from '@port-line-up/shared/util';
import { VesselDelayReport } from '../models/vessel-delay-report';
import { PortCallPresentation } from '@port-line-up/shared/feature-presentation-table';

@Injectable()
export class VesselDelayService {

    constructor(private timeDifferenceService: TimeDifferenceService) {}

    getVesselDelayReport(terminalNames: string[], portCalls: PortCallPresentation[]): VesselDelayReport[] {

        const vesselDelayReports: VesselDelayReport[] = [];

        terminalNames.forEach((terminalName) => {
            vesselDelayReports.push(new VesselDelayReport(terminalName));
        });

        portCalls.forEach((portCall: PortCallPresentation) => {
            if (portCall.vesselStatusId === VesselStatus.Arrived.toString() && portCall.ata && !portCall.atb && portCall.berthDelay && portCall.terminalName) {
                const vesselDelayReport = vesselDelayReports.find(x => x.terminalName === portCall.terminalName);
                if (vesselDelayReport) {
                    vesselDelayReport.delayForVlAtAnchorSum += portCall.berthDelay;
                    vesselDelayReport.delayForVlAtAnchorCount += 1;
                }
            }

            const eta = portCall.eta ?? portCall.etaihs;

            if (eta && !portCall.ata && !portCall.ataihs && portCall.berthDelay && portCall.terminalName) {
                const vesselDelayReport = vesselDelayReports.find(x => x.terminalName === portCall.terminalName);

                const timeDifferenceInDays =
                    this.timeDifferenceService.calculateTimeDifferenceInDaysBetweenTwoDates(new Date(), new Date(eta));

                if (vesselDelayReport) {
                    if (timeDifferenceInDays < 7) {
                        vesselDelayReport.delayForVlArrivingIn0To7DaysSum += Math.abs(portCall.berthDelay);
                        vesselDelayReport.delayForVlArrivingIn0To7DaysCount += 1;
                    }

                    if (timeDifferenceInDays > 7 && timeDifferenceInDays <= 14) {
                        vesselDelayReport.delayForVlArrivingIn7To14DaysSum += Math.abs(portCall.berthDelay);
                        vesselDelayReport.delayForVlArrivingIn7To14DaysCount += 1;
                    }


                    if (timeDifferenceInDays > 14 && timeDifferenceInDays <= 21) {
                        vesselDelayReport.delayForVlArrivingIn14To21DaysSum += Math.abs(portCall.berthDelay);
                        vesselDelayReport.delayForVlArrivingIn14To21DaysCount += 1;
                    }

                    if (timeDifferenceInDays > 21) {
                        vesselDelayReport.delayForVlArrivingIn21PlusDaysSum += Math.abs(portCall.berthDelay);
                        vesselDelayReport.delayForVlArrivingIn21PlusDaysCount += 1;
                    }
                }
            }
        });

        return vesselDelayReports;
    }
}
