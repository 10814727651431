import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Directive, OnDestroy, inject } from '@angular/core';
import { ErrorMessageService, VesselsLocationUpdate } from '@port-line-up/shared/data-access';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { EMPTY, Observable, Subject, interval } from 'rxjs';
import { catchError, filter, skip, startWith, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { PortCallPresentation } from '../models';
import { TableFilteredService } from '../services';

const MINUTES = 15;
const MILLISECONDS_IN_MINUTE = 60 * 1_000;
const POLLING_INTERVAL = MINUTES * MILLISECONDS_IN_MINUTE;

@Directive()
export abstract class VesselsLocationUpdater implements AfterViewInit, OnDestroy {
  shouldGetUpdatedVesselsLocationData = true;

  protected readonly tableFilteredService = inject(TableFilteredService);
  protected readonly table = inject(Table);
  protected readonly messageService = inject(MessageService);
  protected readonly errorMessageService = inject(ErrorMessageService);

  private shouldGetUpdatedVesselsLocationDataSubject = new Subject<void>();
  private shouldGetUpdatedVesselsLocationData$ = this.shouldGetUpdatedVesselsLocationDataSubject.asObservable();
  private destroy$ = new Subject<void>();
  private destroyInterval$ = new Subject<void>();

  abstract selectedIdentifier$: Observable<string | null>;
  abstract getVesselsLocation(identifier: string | null): Observable<VesselsLocationUpdate[]>;

  ngAfterViewInit(): void {
    const isMapOpened$ = this.tableFilteredService.isMapOpened$;
    const portCalls$ = this.tableFilteredService.portCalls$;
    const interval$ = interval(POLLING_INTERVAL)

    this.subscribeToPortCallsChanged(portCalls$, this.selectedIdentifier$, isMapOpened$);
    this.subscribeToMapOpened(isMapOpened$, interval$, this.selectedIdentifier$);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getPortCalls(vesselsLocations: VesselsLocationUpdate[]): PortCallPresentation[] {
    const tableData = (this.table.filteredValue ?? this.table.value) as PortCallPresentation[];

    return tableData.map((item) => {
      const matchingElement = vesselsLocations.find((vesselLocations) => item.imoNumber === vesselLocations.imoNumber);

      if (matchingElement) {
        return { ...item, ...matchingElement };
      }

      return item;
    });
  }

  private subscribeToPortCallsChanged(portCalls$: Observable<PortCallPresentation[]>, selectedIdentifier$: Observable<string | null>, isMapOpened$: Observable<boolean>): void {
    portCalls$
      .pipe(
        skip(2), // skip behavior subject initial and first portCalls change emit
        withLatestFrom(isMapOpened$),
        filter(([portCalls, isMapOpened]) => isMapOpened),
        tap((): void => {
          if (this.shouldGetUpdatedVesselsLocationData ) {
            this.shouldGetUpdatedVesselsLocationDataSubject.next();
          }
          this.shouldGetUpdatedVesselsLocationData = true;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.shouldGetUpdatedVesselsLocationData$
      .pipe(
        withLatestFrom(selectedIdentifier$),
        switchMap(([, identifier]) => this.getVesselsLocation(identifier)),
        tap((response) => this.handleResponse(response)),
        catchError(this.handleErrorMessage),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private subscribeToMapOpened(isMapOpened$: Observable<boolean>, interval$: Observable<number>, selectedIdentifier$: Observable<string | null>): void {
    isMapOpened$
      .pipe(
        skip(1),
        filter((isMapOpened) => {
          if (isMapOpened) {
            return true;
          }

          this.destroyInterval$.next();
          return false;
        }),
        switchMap(() =>
          interval$.pipe(
            takeUntil(this.destroyInterval$),
            startWith(0),
            withLatestFrom(selectedIdentifier$),
            switchMap(([, identifier]) => this.getVesselsLocation(identifier)),
            tap((response) => this.handleResponse(response)),
            catchError(this.handleErrorMessage)
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private handleErrorMessage = (err: HttpErrorResponse): Observable<never> => {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: this.errorMessageService.handleErrorMessage(err.error) });

    return EMPTY;
  };

  private handleResponse(vesselsLocations: VesselsLocationUpdate[]): void { 
    this.shouldGetUpdatedVesselsLocationData = false;
    this.tableFilteredService.loadPortCalls(this.getPortCalls(vesselsLocations));
  }
}
