import { Component, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CargoMatchMode, ColumnFilterItem, ShowCloseButtonDirective, LookupItem } from '@port-line-up/shared/util';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';

@Component({
    selector: 'plu-cargo-column-filter',
    templateUrl: 'cargo-column-filter.component.html',
    imports: [FormsModule, ShowCloseButtonDirective, TableModule, MultiSelectModule],
    standalone: true,
})
export class CargoColumnFilterComponent {
    fieldName = input.required<string>();
    options = input.required<ColumnFilterItem<LookupItem, LookupItem>[]>();
    matchMode = input.required<CargoMatchMode>();
    optionValue = input<string>();
}
