import { Injectable, OnDestroy } from '@angular/core';
import { Subject, delay, skip, takeUntil, withLatestFrom } from 'rxjs';
import { ApplyChangesStatus } from './models/apply-changes-status';
import { DataUpdateState, DataUpdateStore } from './data-update.store';

@Injectable()
export class SignalRChangeHandlerService implements OnDestroy {
    private destroy$ = new Subject<void>();

    constructor(private store: DataUpdateStore<DataUpdateState>) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public applyChanges(): void {
        this.store.applyChanges(ApplyChangesStatus.Apply);
    }

    public resetChangesAfter(seconds: number): void {
        this.store.applyChanges$
            .pipe(
                takeUntil(this.destroy$),
                skip(1),
                delay(seconds * 1000),
                withLatestFrom(this.store.changedPortCalls$, this.store.addedPortCalls$, this.store.deletedPortCalls$, this.store.sailedPortCallChanges$)
            )
            .subscribe(([status, changePortCalls, addedPortCalls, deletedPortCalls, sailedPortCallsChange]) => {
                if (status.status === ApplyChangesStatus.Apply) {
                    if (changePortCalls.size > 0) {
                        this.store.removeChangedPortCallsAfter(seconds);
                        this.store.applyChanges(ApplyChangesStatus.Apply);
                    } else if (addedPortCalls.size > 0) {
                        this.store.removeAddedPortCallsAfter(seconds);
                        this.store.applyChanges(ApplyChangesStatus.Apply);
                    } else if (deletedPortCalls.size > 0) {
                        this.store.removeDeletedPortCallsAfter(seconds);
                        this.store.applyChanges(ApplyChangesStatus.Apply);
                    } else if (sailedPortCallsChange.size > 0) {
                        this.store.removeSailedPortCallChangesAfter(seconds);
                        this.store.applyChanges(ApplyChangesStatus.Apply);
                    } else {
                        this.store.applyChanges(ApplyChangesStatus.NothingToApply);
                    }
                }
            });
    }
}
