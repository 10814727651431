import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimations, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { AuthenticationInterceptor, ServerErrorInterceptor, OcpApimInterceptor } from '@port-line-up/shared/data-access';
import { importProvidersFrom } from '@angular/core';
import { MSALProviders } from './b2c-config';
import { MessageService } from 'primeng/api';
import { APP_ENVIRONMENT, AppConfig } from '@port-line-up/shared/util';
import { DatePipe, DecimalPipe } from '@angular/common';
import { environment } from '../environments';
export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(appRoutes),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi(), withInterceptors([AuthenticationInterceptor, OcpApimInterceptor, ServerErrorInterceptor])),
        importProvidersFrom(BrowserAnimationsModule),
        MSALProviders,
        MessageService,
        {
            provide: APP_ENVIRONMENT,
            useValue: environment,
        },
        {
            provide: AppConfig,
            useValue: new AppConfig(),
        },
        DatePipe,
        DecimalPipe
    ],
};
