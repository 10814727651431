import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Port } from '@port-line-up/shared/data-access';
import { SharedViewStore } from '../../../shared-view.store';
import { SharedViewTableComponent } from '../table/table.component';

@Component({
    selector: 'plu-shared-view-sailed-table',
    standalone: true,
    imports: [CommonModule, SharedViewTableComponent],
    templateUrl: './sailed-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SailedTableComponent implements OnInit {
    @Input() port!: Port;
    initialFilters$ = this.sharedViewStore.filters$;
    portCalls$ = this.sharedViewStore.sailedPortCalls$;

    constructor(private sharedViewStore: SharedViewStore) {}

    ngOnInit(): void {
        this.setPortCalls();
    }

    private setPortCalls(): void {
        this.sharedViewStore.getSailedPortCalls();
    }
}
