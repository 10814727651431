import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PortCallPresentation } from '../models';
import { ColumnFilterItem, PartyLookup, LookupItem, sortColumnFilterItemsAndSetEmptyAsFirst } from '@port-line-up/shared/util';

@Injectable()
export class ColumnFilterService {
    private filterableStatusesSubject = new BehaviorSubject<ColumnFilterItem<LookupItem, string>[]>([]);
    filterableStatuses$ = this.filterableStatusesSubject.asObservable();

    private filterableTerminalsSubject = new BehaviorSubject<ColumnFilterItem<LookupItem, string>[]>([]);
    filterableTerminals$ = this.filterableTerminalsSubject.asObservable();

    private filterableBerthsSubject = new BehaviorSubject<ColumnFilterItem<LookupItem, string>[]>([]);
    filterableBerths$ = this.filterableBerthsSubject.asObservable();

    private filterableCargosSubject = new BehaviorSubject<ColumnFilterItem<LookupItem, LookupItem>[]>([]);
    filterableCargos$ = this.filterableCargosSubject.asObservable();

    private filterableOperationsSubject = new BehaviorSubject<ColumnFilterItem<LookupItem, string>[]>([]);
    filterableOperations$ = this.filterableOperationsSubject.asObservable();

    private filterableCharterersSubject = new BehaviorSubject<ColumnFilterItem<PartyLookup, string>[]>([]);
    filterableCharterers$ = this.filterableCharterersSubject.asObservable();

    private filterableShippingAgentsSubject = new BehaviorSubject<ColumnFilterItem<PartyLookup, string>[]>([]);
    filterableShippingAgents$ = this.filterableShippingAgentsSubject.asObservable();

    private filterableShippersSubject = new BehaviorSubject<ColumnFilterItem<PartyLookup, string>[]>([]);
    filterableShippers$ = this.filterableShippersSubject.asObservable();

    private filterableVesselNamesSubject = new BehaviorSubject<ColumnFilterItem<LookupItem, string>[]>([]);
    filterableVesselNames$ = this.filterableVesselNamesSubject.asObservable();

    private filterableVesselTypesSubject = new BehaviorSubject<ColumnFilterItem<string, string>[]>([]);
    filterableVesselTypes$ = this.filterableVesselTypesSubject.asObservable();

    private filterableVoyageNumbersSubject = new BehaviorSubject<ColumnFilterItem<string, string>[]>([]);
    filterableVoyageNumbers$ = this.filterableVoyageNumbersSubject.asObservable();

    private filterableNextDestinationsSubject = new BehaviorSubject<ColumnFilterItem<string, string>[]>([]);
    filterableNextDestinations$ = this.filterableNextDestinationsSubject.asObservable();

    init(portCalls: PortCallPresentation[]): void {
        this.updateAll(portCalls);
    }

    private updateAll(portCalls: PortCallPresentation[]): void {
        const terminals = new Map<string, ColumnFilterItem<LookupItem, string>>();
        const vesselStatuses = new Map<string, ColumnFilterItem<LookupItem, string>>();

        const berths = new Map<string, ColumnFilterItem<LookupItem, string>>();
        const cargos = new Map<string, ColumnFilterItem<LookupItem, LookupItem>>();
        const operations = new Map<string, ColumnFilterItem<LookupItem, string>>();

        const charterers = new Map<string, ColumnFilterItem<PartyLookup, string>>();
        const shippers = new Map<string, ColumnFilterItem<PartyLookup, string>>();
        const shippingAgents = new Map<string, ColumnFilterItem<PartyLookup, string>>();
        const vesselNames = new Map<string, ColumnFilterItem<LookupItem, string>>();
        const voyageNumbers = new Map<string, ColumnFilterItem<string, string>>();
        const nextDestinations: Map<string, ColumnFilterItem<string, string>> = new Map();
        const vesselTypes = new Map<string, ColumnFilterItem<string, string>>();

        portCalls.forEach((item: PortCallPresentation) => {
            if (item.terminalId) {
                terminals.set(item.terminalId, ColumnFilterItem.create(item.terminalName, LookupItem.create(item.terminalId, item.terminalName), 'id'));
            } else {
                terminals.set('', ColumnFilterItem.createEmpty());
            }

            if (item.berthId) {
                berths.set(item.berthId, ColumnFilterItem.create(item.berthName, LookupItem.create(item.berthId, item.berthName), 'id'));
            } else {
                berths.set('', ColumnFilterItem.createEmpty());
            }

            item.cargoType.forEach((type) => {
                if (type?.id) {
                    cargos.set(type.id, ColumnFilterItem.create(type.displayText, type, 'id', 'id'));
                } else {
                    cargos.set('', ColumnFilterItem.createEmpty());
                }
            });

            if (item.vesselStatusId && item.vesselStatus) {
                vesselStatuses.set(
                    item.vesselStatusId,
                    ColumnFilterItem.create(item.vesselStatus, LookupItem.create(item.vesselStatusId, item.vesselStatus), 'id')
                );
            } else {
                vesselStatuses.set('', ColumnFilterItem.createEmpty());
            }
            
            if (item.operationId) {
                operations.set(item.operationId, ColumnFilterItem.create(item.operationName, LookupItem.create(item.operationId, item.operationName), 'id'));
            } else {
                operations.set('', ColumnFilterItem.createEmpty());
            }

            if (item.charterer) {
                charterers.set(
                    item.chartererCode,
                    ColumnFilterItem.create(item.charterer, PartyLookup.create(item.chartererCode, item.charterer), 'displayText')
                );
            } else {
                charterers.set('', ColumnFilterItem.createEmpty());
            }

            if (item.shipper) {
                shippers.set(item.shipperCode, ColumnFilterItem.create(item.shipperCode, PartyLookup.create(item.shipperCode, item.shipper), 'displayText'));
            } else {
                shippers.set('', ColumnFilterItem.createEmpty());
            }

            if (item.shippingAgent) {
                shippingAgents.set(
                    item.shippingAgentCode,
                    ColumnFilterItem.create(item.shippingAgentCode, PartyLookup.create(item.shippingAgentCode, item.shippingAgent), 'displayText')
                );
            } else {
                shippingAgents.set('', ColumnFilterItem.createEmpty());
            }

            if (item.vesselName) {
                vesselNames.set(item.vesselId, ColumnFilterItem.create(item.vesselName, LookupItem.create(item.vesselId, item.vesselName), 'displayText'));
            }

            if (item.vesselType) {
                vesselTypes.set(item.vesselType, ColumnFilterItem.create(item.vesselType, item.vesselType));
            } else {
                vesselTypes.set('', ColumnFilterItem.createEmpty());
            }

            if (item.voyageNumber) {
                voyageNumbers.set(item.voyageNumber, ColumnFilterItem.create(item.voyageNumber, item.voyageNumber));
            } else {
                voyageNumbers.set('', ColumnFilterItem.createEmpty());
            }

            if (item.nextDestinationNote) {
                nextDestinations.set(item.nextDestinationNote, ColumnFilterItem.create(item.nextDestinationNote, item.nextDestinationNote));
            } else {
                nextDestinations.set('', ColumnFilterItem.createEmpty());
            }
        });

        this.filterableTerminalsSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...terminals.values()]));
        this.filterableBerthsSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...berths.values()]));
        this.filterableCargosSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...cargos.values()]));
        this.filterableStatusesSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...vesselStatuses.values()]));
        this.filterableOperationsSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...operations.values()]));
        this.filterableVesselTypesSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...vesselTypes.values()]));

        this.filterableVesselNamesSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...vesselNames.values()]));
        this.filterableVoyageNumbersSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...voyageNumbers.values()]));
        this.filterableNextDestinationsSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...nextDestinations.values()]));
        this.filterableCharterersSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...charterers.values()]));
        this.filterableShippersSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...shippers.values()]));
        this.filterableShippingAgentsSubject.next(sortColumnFilterItemsAndSetEmptyAsFirst([...shippingAgents.values()]));
    }
}
