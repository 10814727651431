export enum ErrorLogTypes {
    LoggedIn = 1,
    Save = 2,
    Share = 3,
    Export = 4,
    Import = 5,
    Sync = 6,
    Other = 7,
    Priority = 8
}
