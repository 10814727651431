import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

type UIState = {
    headerHeight: number;
    ongoing: UIOngoing;
    fleet: UIFleet;
    sharedView: UISharedView;
    editView: UIEditView;
};

type UIOngoing = {
    showHeader: boolean;
    showGroupBy: boolean;
    showMap: boolean;
    showTraffic: boolean;
    showCargo: boolean;
    showReport: boolean;
    showDelayed: boolean;
    showShare: boolean;
    showFleetSelection: boolean,
    showPageContainerPadding: boolean,
    mapHeight: number
};

type UIFleet = {
    mapHeight: number
};

type UISharedView = {
    mapHeight: number
};

type UIEditView = {
    priorityColumnWidth: number
};

const initialState = {
    headerHeight: 0,
    ongoing: {
        showHeader: true,
        showGroupBy: true,
        showMap: true,
        showTraffic: true,
        showCargo: true,
        showReport: true,
        showDelayed: true,
        showShare: true,
        showFleetSelection: true,
        showPageContainerPadding: true,
        mapHeight: 570
    },
    fleet: {
        mapHeight: 570
    },
    sharedView: {
        mapHeight: 570
    },
    editView: {
        priorityColumnWidth: 32
    }

} as UIState;

export const UIStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((store) => {
        return {
            setHeaderHeight(headerHeight: number): void {
                patchState(store, () => ({ headerHeight }));
            },
            setOngoingOptions(ongoing: UIOngoing): void {
                patchState(store, () => ({ ongoing }));
            },
        };
    })
);
