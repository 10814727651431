import { Directive, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { distinctUntilChanged, filter, fromEvent, takeUntil, Subject } from 'rxjs';
import { SliderService } from '@port-line-up/shared/util';

@Directive({ selector: '[pluHorizontalScroll]', standalone: true })
export class HorizontalScrollDirective implements AfterViewInit, OnDestroy {

    private scrollLeft = 0;
    private destroy$ = new Subject<void>();
    constructor(private div: ElementRef<HTMLDivElement>, private sliderService: SliderService) {}

    ngAfterViewInit(): void {
        this.listenToScrollContainerScrollEvent()
        this.subscribeToTableScrollLeftValueChange();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private subscribeToTableScrollLeftValueChange(): void {
        this.sliderService.tableScrollLeft$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((scrollLeft) => {
            if (typeof scrollLeft === "number" && this.scrollLeft !== scrollLeft) {
                this.scrollLeft = scrollLeft;
                this.div.nativeElement.scrollLeft = scrollLeft;
            }
        });
    }

    private listenToScrollContainerScrollEvent(): void {
        fromEvent(this.div.nativeElement, 'scroll')
            .pipe(takeUntil(this.destroy$), filter(wrapper => {
                const { scrollLeft } = (wrapper as any).target;
                if (scrollLeft === this.scrollLeft) {
                    return false;
                }
                this.scrollLeft = scrollLeft;
                return true;
            })).subscribe(( wrapper ) => {
            const { scrollLeft } = (wrapper as any).target;
            this.sliderService.tableScrollLeft(scrollLeft);
        });
    }
}
