export enum LocalStorageKeys {
    FiltersEdit = 'filters-edit',
    ColumnsEdit = 'columns-edit',
    FiltersOngoing = 'filters-ongoing',
    ColumnsOngoing = 'columns-ongoing',
    FiltersFleet = 'filters-fleet',
    ColumnsFleet = 'columns-fleet',
    SelectedPortsKey = 'selected-ports-key',
    SelectedPortKey = 'selected-port-key',
    LocalStorageVersionKey = 'local-storage-version',
}
