import { DatePipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pluDatePipe',
    standalone: true,
})
export class PluDatePipe implements PipeTransform {
    private datePipe = inject(DatePipe);

    transform(value: string | number | Date | null | undefined): string | null {
        return this.datePipe.transform(value, 'HH:mm dd/MM/yy');
    }
}
