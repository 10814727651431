import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SliderService  {

    private horizontalSlideToSubject: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
    horizontalSlideTo$: Observable<number | null> = this.horizontalSlideToSubject.asObservable();

    private tableScrollLeftSubject: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
    tableScrollLeft$: Observable<number | null> = this.tableScrollLeftSubject.asObservable();

    private tableWidthSubject: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
    tableWidth$: Observable<number | null> = this.tableWidthSubject.asObservable();

    horizontalSlideTo(slideTo: number): void {
        this.horizontalSlideToSubject.next(slideTo);
    }

    tableScrollLeft(scrollLeft: number): void {
        this.tableScrollLeftSubject.next(scrollLeft);
    }

    setTableWidthTo(newTableWidth: number): void {
        this.tableWidthSubject.next(newTableWidth);
    }
}
