import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'plu-note-detail',
    standalone: true,
    imports: [CommonModule, InputTextareaModule, ButtonModule, ReactiveFormsModule],
    templateUrl: 'note-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteDetailComponent {
    form: FormGroup;
    isEditable = true;
    constructor(fb: FormBuilder, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
        this.isEditable = config.data.isEditable;
        this.form = fb.group({
            notes: fb.control({ value: config.data.notes, disabled: !this.isEditable }),
        });
    }

    apply(): void {
        this.ref.close(this.form.value);
    }

    close(): void {
        this.ref.close();
    }
}
