import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ColumnFilterItem, CustomMatchMode } from '@port-line-up/shared/util';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';

@Component({
    selector: 'plu-berth-filter',
    standalone: true,
    imports: [TableModule, MultiSelectModule, FormsModule],
    templateUrl: './berth-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BerthFilterComponent {
    @Input({required: true}) field!: string;
    @Input() options: ColumnFilterItem<any, any>[] = [];
    CustomMatchMode = CustomMatchMode;
}
