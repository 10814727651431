import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { UserRole } from '../enums';
import { LookupItem } from '@port-line-up/shared/util';

@Injectable({ providedIn: 'root' })
export class RoleService {
    private types: KeyValue<UserRole, string>[] = [
        { key: UserRole.GlobalAdmin, value: 'Global Admin' },
        { key: UserRole.LocalAdmin, value: 'Local Admin' },
        { key: UserRole.Editor, value: 'Editor' },
        { key: UserRole.Viewer, value: 'Viewer' },
    ];

    get(): KeyValue<UserRole, string>[] {
        return this.types;
    }

    getValue(key: UserRole | null): string {
        if (key === null || key === undefined) {
            return '';
        }

        const result = this.types.find((status) => status.key == key);
        return result ? result.value : '';
    }

    getLookups(): LookupItem[] {
        return this.types.map(entry => new LookupItem(entry.key.toString(), entry.value));
    }
}
