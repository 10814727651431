import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { TimeZone } from '../models';
import { LocalTimeZoneService } from '../services';

@Injectable({ providedIn: 'root' })
export class TimeZoneService {
    private readonly url = `${this.appEnvironment.baseApiUrl}/timezone`;

    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private appEnvironment: any, private localTimeZoneService: LocalTimeZoneService) {}

    get(): Observable<TimeZone> {
        const currentTimeZone = this.localTimeZoneService.getLocalTimeZone();
        return this.http.get<TimeZone>(`${this.url}?timeZoneId=${currentTimeZone}`);
    }
}
