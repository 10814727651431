export class DatesBackgroundColor {
  static readonly eta = 'bg-blue-400';
  static readonly etaihs = 'bg-blue-400';
  static readonly ata = 'bg-blue-600';
  static readonly ataihs = 'bg-blue-600';
  static readonly etd = 'bg-yellow-600';
  static readonly atd = 'bg-yellow-800';
  static readonly atdihs = 'bg-yellow-800';
  static readonly etc = 'bg-purple-400';
  static readonly atc = 'bg-purple-600';
  static readonly ecc = 'bg-green-400';
  static readonly acc = 'bg-green-500';
  static readonly etb = 'bg-green-600';
  static readonly atb = 'bg-green-700';
  static readonly atbihs = 'bg-green-700';
}
