import { Lookup } from '../../../../util/src/lib/models/lookup-item';

export class VesselLookup implements Lookup {
    constructor(
        public readonly id: string,
        public readonly displayText: string,
        public readonly imoNumber: string,
        public readonly dwt: number | null,
        public readonly vesselType: string,
    ) { }

    static create(
        id: string,
        displayText: string,
        imoNumber: string,
        dwt: number | null,
        vesselType: string
    ): VesselLookup {
        return new VesselLookup(id, displayText, imoNumber, dwt, vesselType);
    }

    static createEmpty(): VesselLookup {
        return new VesselLookup('', '', '', null, '');
    }
}