import { DatePipe } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { PortCallPresentation } from '../models';

@Injectable({ providedIn: 'root' })
export class BerthPriorityService {
    private readonly datePipe = inject(DatePipe);

    getBerthPriorityTooltip(item: PortCallPresentation, portCalls: PortCallPresentation[]): string {
        const filteredPortCalls =
            portCalls
                .filter((portCall) => portCall.berthId && portCall.berthId === item.berthId && portCall.berthPriority)
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .sort((a, b) => a.berthPriority! - b.berthPriority!)
                .slice(0, 10);

        const messages = filteredPortCalls.map((portCall) => {
            const etbDate = this.datePipe.transform(portCall.etb);
            const etdDate = this.datePipe.transform(portCall.etd);

            return `${portCall.berthPriority}. ${portCall.vesselName} - ETB ${etbDate ?? 'Unknown'} - ETD ${etdDate ?? 'Unknown'}`;
        });

        return messages.join('\n');
    }

    getPortCallsWithSameBerth(portCall: PortCallPresentation, portCalls: PortCallPresentation[]): PortCallPresentation[] {
        return portCalls
            .filter((x) => x.berthId && x.berthId === portCall.berthId)
            .sort((a, b) => (a.berthPriority ?? 0) - (b.berthPriority ?? 0));
    }
}
