import { patchState, signalStore, withMethods } from '@ngrx/signals';
import { LookupItem } from '@port-line-up/shared/util';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';


export const VesselStatusStore = signalStore(
  { providedIn: 'root' },
  withEntities<LookupItem>(),
  withMethods((store) => {
    return {
      loadVesselStatuses(vesselStatuses: LookupItem[]): void {
        patchState(store, setAllEntities(vesselStatuses))
      },
    }
  })
);

