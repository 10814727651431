import { Directive } from '@angular/core';
import { CustomMatchMode, PresentationTableFilterIds } from '@port-line-up/shared/util';
import { FilterMatchMode, FilterMetadata } from 'primeng/api';
import { Table } from 'primeng/table';
import { ObjectUtils } from 'primeng/utils';

/*
 * [PRIMENG_OVERRIDE]
 *  Override function enable us to extract data from model and pass it to the filter function
 *  for Presentation Tables
 * */
@Directive({
    selector: '[pluExecuteLocalFilterOverride]',
    standalone: true,
})
export class ExecuteLocalFilterOverrideDirective {
    constructor(table: Table) {
        table.executeLocalFilter = (field: string, rowData: any, filterMeta: FilterMetadata) => {
            const filterValue = filterMeta.value;
            const filterMatchMode = filterMeta.matchMode ?? FilterMatchMode.STARTS_WITH;
            const filterConstraint = (<any>table.filterService).filters[filterMatchMode];
            const dataFieldValue = this.getDataFieldValue(field, rowData, filterMeta);

            return filterConstraint(dataFieldValue, filterValue, table.filterLocale);
        }
    }

    private getDataFieldValue(field: string, rowData: any, filterMeta: FilterMetadata): any {
        const dateFiltersNames = [
            PresentationTableFilterIds.ata,
            PresentationTableFilterIds.atb,
            PresentationTableFilterIds.acc,
            PresentationTableFilterIds.atd,
            PresentationTableFilterIds.atc,
        ];
        let dataFieldValue;

        if (field === PresentationTableFilterIds.party) {
            dataFieldValue = {
                charterer: rowData.charterer,
                shippingAgent: rowData.shippingAgent,
                shipper: rowData.shipper,
            };
        } else if (dateFiltersNames.includes(field)) {
            field = this.handleFieldName(field, rowData, filterMeta);
            dataFieldValue = ObjectUtils.resolveFieldData(rowData, field);
        } else {
            dataFieldValue = ObjectUtils.resolveFieldData(rowData, field);
        }

        return dataFieldValue;
    }

    private handleFieldName(field: string, rowData: any, filterMeta: FilterMetadata): string {
        // If match mode is EmptyDate then we don't consider ihs values
        if (filterMeta.matchMode !== CustomMatchMode.EmptyDate && field === PresentationTableFilterIds.ata && !rowData.ata && !rowData.eta && rowData.ataihs) {
            return PresentationTableFilterIds.ataihs;
        }

        if (field === PresentationTableFilterIds.ata && !rowData.ata && rowData.eta) {
            return PresentationTableFilterIds.eta;
        }

        if (filterMeta.matchMode !== CustomMatchMode.EmptyDate && field === PresentationTableFilterIds.ata && !rowData.ata && !rowData.eta && rowData.etaihs) {
            return PresentationTableFilterIds.etaihs;
        }

        if (field === PresentationTableFilterIds.atb && !rowData.atb && rowData.etb) {
            return PresentationTableFilterIds.etb;
        }

        if (field === PresentationTableFilterIds.atc && !rowData.atc && rowData.etc) {
            return PresentationTableFilterIds.etc;
        }

        if (field === PresentationTableFilterIds.atd && !rowData.atd && rowData.etd) {
            return PresentationTableFilterIds.etd;
        }

        if (field === PresentationTableFilterIds.acc && !rowData.acc && rowData.ecc) {
            return PresentationTableFilterIds.ecc;
        }

        return field;
    }
}
