export class ModifyTableFilterIds {
    static readonly vesselName: string  = 'value.vessel';
    static readonly vesselStatus: string = 'value.vesselStatus';
    static readonly vesselType: string = 'value.vesselType';
    static readonly terminal: string = 'value.terminal';
    static readonly berth: string = 'value.berth';
    static readonly cargoType: string = 'value.cargoType';
    static readonly ata: string = 'value.ata';
    static readonly ataihs: string = 'value.ataihs';
    static readonly eta: string = 'value.eta';
    static readonly etaihs: string = 'value.etaihs';
    static readonly atb: string = 'value.atb';
    static readonly etb: string = 'value.etb';
    static readonly acc: string = 'value.acc';
    static readonly ecc: string = 'value.ecc';
    static readonly atc: string = 'value.atc';
    static readonly etc: string = 'value.etc';
    static readonly atd: string = 'value.atd';
    static readonly etd: string = 'value.etd';
    static readonly cargoDescription: string = 'value.cargoDescription';
    static readonly voyageNumber: string = 'value.voyageNumber';
    static readonly cargoRate: string = 'value.cargoRate';
    static readonly cargoUom: string = 'value.cargoUom';
    static readonly operation: string = 'value.operation';
    static readonly cargoQuantity: string = 'value.cargoQuantity';
    static readonly cargoQuantityUom: string = 'value.cargoQuantityUom';
    static readonly notes: string = 'value.notes';
    static readonly dwt: string = 'value.dwt';
    static readonly berthDelay: string = 'value.berthDelay';
    static readonly berthPriority: string = 'value.berthPriority';
    static readonly nextDestinationNote = 'value.nextDestinationNote'
    static readonly party = 'value.party'
}
